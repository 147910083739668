import { batch } from "react-redux";
import { Dispatch } from "redux";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { WroWager, WroWagerGroup } from "@tvg/ts-types/WroWager";
import {
  selectStatusFilters,
  clearStatusFilters,
  setStatusFilterCount,
  selectTrackFilters,
  setTrackFilterCount,
  setBetTypeFilters,
  setBetTypeFilterCount,
  setSelectedSettledTabMyBetsStandalone,
  setCustomStartDate,
  setCustomEndDate,
  setIsCustomTimeFrame
} from "../redux/actions";
import {
  clearFilterDropdown,
  selectedNewFiltersGtm,
  clearAllFiltersGtm,
  selectedNewCustomDateGtm
} from "./gtm";
import {
  StatusFilters,
  MyBetsDynamicFilter,
  MyBetsDynamicFilters,
  QueryTrackFilter,
  QueryBetTypeFilter,
  ActiveTabEnum,
  SelectedFilters,
  SettledTabEnum
} from "./types";

export const clearDrowpdownFilters = (dispatch: Dispatch) => {
  dispatch(selectTrackFilters([]));
  dispatch(setTrackFilterCount(0));
  dispatch(setBetTypeFilters([]));
  dispatch(setBetTypeFilterCount(0));
  dispatch(clearStatusFilters());
  dispatch(setStatusFilterCount(0));
};

export const clearAllFilters = (dispatch: Dispatch, date?: Date) => {
  clearDrowpdownFilters(dispatch);
  dispatch(setCustomEndDate(date || new Date()));
  dispatch(setCustomStartDate(null));
};

export const selectNewTimeFrame =
  (dispatch: Dispatch, currentFrame: string, tab: string) =>
  (newTimeFrame: SettledTabEnum) => {
    if (currentFrame !== newTimeFrame && tab === "SETTLED") {
      dispatch(setSelectedSettledTabMyBetsStandalone(newTimeFrame));
    }
  };

export const filterByTrack = (bets: WroWagerGroup[], trackNames: string[]) => {
  if (trackNames.length) {
    const filteredBets = bets.filter((bet: WroWagerGroup) =>
      trackNames.includes(bet.wagers[0].trackName)
    );
    return filteredBets;
  }
  return bets;
};

export const getTrackListFromBets = (bets: WroWagerGroup[]) => {
  const trackListBuffer: string[] = [];
  bets.forEach((bet) => {
    bet.wagers.forEach((wager: WroWager) => {
      if (!trackListBuffer.includes(wager.trackName)) {
        trackListBuffer.push(wager.trackName);
      }
    });
  });
  return trackListBuffer;
};

export const getQueryDynamicOptions = (
  filters: Array<QueryTrackFilter & QueryBetTypeFilter>,
  selected: MyBetsDynamicFilters
) => {
  const constructed: MyBetsDynamicFilters = [];
  const filterCopy = [...filters];
  filterCopy.sort((a, b) => {
    if (a.id && b.id) {
      return a.id - b.id;
    }
    return a.name ? a.name.localeCompare(b.name) : 0;
  });
  filterCopy.forEach((filter) => {
    const currentOption = selected.find(
      (option) => option.code === filter.code && option.name === filter.name
    );
    constructed.push({
      code: filter.code,
      name: filter.name,
      isActive: currentOption?.isActive || false
    });
  });
  return constructed;
};

export const reduceDynamicFilters = (filters: MyBetsDynamicFilters) =>
  filters.reduce<{ keys: string[]; values: boolean[] }>(
    (prev, filter) => {
      prev.keys.push(filter.name);
      prev.values.push(filter.isActive);
      return prev;
    },
    { keys: [], values: [] }
  );

export const selectNewStatusFilters =
  (
    filters: StatusFilters = {
      Cancelled: false,
      Wins: false,
      Refunded: false,
      Lost: false
    },
    dispatch: Dispatch,
    settledTab: string,
    activeCounter: number,
    settledCounter: number,
    closeDropdown: UnaryFn<boolean, void>
  ) =>
  (newType: keyof StatusFilters | "clear") => {
    if (newType === "clear") {
      dispatch(clearStatusFilters());
      dispatch(setStatusFilterCount(0));
      closeDropdown(false);
      clearFilterDropdown("Status", settledTab, activeCounter, settledCounter);
      return;
    }
    const newFilters: StatusFilters = { ...filters };
    newFilters[newType] = !filters[newType];

    let newCounter = 0;
    const activeStatus: string[] = [];
    Object.keys(newFilters).forEach((type) => {
      if (newFilters[type as keyof StatusFilters]) {
        newCounter += 1;
        activeStatus.push(type);
      }
    });
    dispatch(selectStatusFilters(newFilters));
    dispatch(setStatusFilterCount(newCounter));
    selectedNewFiltersGtm(
      `Status - ${activeStatus.length ? activeStatus.join(", ") : "None"}`,
      settledTab,
      activeCounter,
      settledCounter
    );
  };

export const setDynamicFilters =
  (
    dispatch: Dispatch,
    type: string,
    filters: MyBetsDynamicFilters,
    settledTab: string,
    activeCounter: number,
    settledCounter: number,
    closeDropdown: UnaryFn<boolean, void>
  ) =>
  (toggledType: string) => {
    if (toggledType === "clear") {
      if (type === "Tracks") {
        closeDropdown(false);
        dispatch(selectTrackFilters([]));
        dispatch(setTrackFilterCount(0));
        clearFilterDropdown(
          "Tracks",
          settledTab,
          activeCounter,
          settledCounter
        );
      } else {
        closeDropdown(false);
        dispatch(setBetTypeFilters([]));
        dispatch(setBetTypeFilterCount(0));
        clearFilterDropdown(
          "Bet Type",
          settledTab,
          activeCounter,
          settledCounter
        );
      }
    } else {
      const toggledIndex = filters.findIndex(
        (filter) => filter.name === toggledType
      );
      if (toggledIndex === -1) return;
      const newFilters = filters.map((filter) => ({
        ...filter,
        isActive:
          filter.name === toggledType ? !filter.isActive : filter.isActive
      }));
      const activeStatus = newFilters
        .filter((filter) => filter.isActive)
        .map((filter) => filter.name);
      if (type === "Tracks") {
        dispatch(selectTrackFilters(newFilters));
        dispatch(setTrackFilterCount(activeStatus.length));
        selectedNewFiltersGtm(
          `Tracks - ${activeStatus.length ? activeStatus.join(", ") : "None"}`,
          settledTab,
          activeCounter,
          settledCounter
        );
      } else {
        dispatch(setBetTypeFilters(newFilters));
        dispatch(setBetTypeFilterCount(activeStatus.length));
        selectedNewFiltersGtm(
          `Bet Type - ${
            activeStatus.length ? activeStatus.join(", ") : "None"
          }`,
          settledTab,
          activeCounter,
          settledCounter
        );
      }
    }
  };

export const resetAllFilters =
  (
    dispatch: Dispatch,
    settledTab: string,
    activeCounter: number,
    settledCounter: number
  ) =>
  () => {
    batch(() => {
      clearDrowpdownFilters(dispatch);
    });
    clearAllFiltersGtm(settledTab, activeCounter, settledCounter);
  };

export const statusKeyMapper = (statusList: string[]): string[] => {
  const newStatusList: string[] = [];
  statusList.forEach((track: string) => {
    switch (track) {
      case "Cancelled":
        newStatusList.push("Canceled");
        break;
      case "Wins":
        newStatusList.push("Winners");
        break;
      case "Lost":
        newStatusList.push("Losses");
        break;
      case "Refunded":
        newStatusList.push(track);
        break;
      default:
        break;
    }
  });
  return newStatusList.map((newStatus: string) => newStatus?.toUpperCase());
};

export const getQueryFilters = (
  selectedTab: ActiveTabEnum,
  filters: SelectedFilters | null
) => {
  if (selectedTab !== "SETTLED" || filters === null) {
    return null;
  }
  const { statusFilters, trackFilters, betTypeFilters } = filters;
  const activeStatusFilters: string[] = [];
  if (statusFilters) {
    Object.keys(statusFilters).forEach((type) => {
      if (statusFilters[type as keyof StatusFilters]) {
        activeStatusFilters.push(type);
      }
    });
  }
  const activeTrackFilter: MyBetsDynamicFilter[] = [];
  if (trackFilters && trackFilters.length) {
    trackFilters.forEach((filter) => {
      if (filter.isActive) {
        activeTrackFilter.push(filter);
      }
    });
  }
  const activeBetTypeFilter: MyBetsDynamicFilter[] = [];
  if (betTypeFilters && betTypeFilters.length) {
    betTypeFilters.forEach((filter) => {
      if (filter.isActive) {
        activeBetTypeFilter.push(filter);
      }
    });
  }
  const statusFiltersMap = statusKeyMapper(activeStatusFilters);
  return {
    statusList: statusFiltersMap.length > 0 ? statusFiltersMap : ["RESOLVED"],
    trackCodes:
      activeTrackFilter.length > 0
        ? activeTrackFilter.map((filter: MyBetsDynamicFilter) => filter.code)
        : null,
    wagerTypes:
      activeBetTypeFilter.length > 0
        ? activeBetTypeFilter.map((filter: MyBetsDynamicFilter) => filter.code)
        : null
  };
};

export const dateSelectionHook =
  (
    type: string,
    dispatch: Dispatch,
    closePicker: Function,
    customStartDate?: Date,
    activeBets?: number,
    settledBets?: number
  ) =>
  (event: Date, info: { disabled?: boolean }) => {
    if (info?.disabled) {
      return;
    }
    batch(() => {
      if (type === "start") {
        dispatch(setCustomStartDate(event));
        clearDrowpdownFilters(dispatch);
      } else if (type === "end") {
        dispatch(setCustomEndDate(event));
        if (customStartDate) {
          clearDrowpdownFilters(dispatch);
          if (new Date(event) < customStartDate) {
            dispatch(setCustomStartDate(null));
          }
        }
      }
      closePicker(false);
    });
    selectedNewCustomDateGtm(activeBets, settledBets);
  };

export const updateIsCustomTimeFrame =
  (dispatch: Dispatch) => (isCustom: boolean) => {
    if (!isCustom) {
      batch(() => {
        dispatch(setIsCustomTimeFrame(isCustom));
        clearAllFilters(dispatch);
      });
    } else {
      dispatch(setIsCustomTimeFrame(isCustom));
    }
  };
