/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useMemo,
  useCallback
} from "react";
import { connect, batch, useSelector } from "react-redux";
import { Dispatch, Store } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { get, throttle, flowRight as compose } from "lodash";
import { ToastManager, ToastMessageLink } from "@tvg/design-system";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import mediator from "@tvg/mediator";
import { isTvg5 } from "@tvg/utils/generalUtils";
import {
  buildTabs,
  safeJSONParse,
  getEmptyStateTitle,
  getEmptyStateMessage,
  shouldRedirectToSettled
} from "@tvg/sh-lib-my-bets/utils/service";
import {
  onMyBetsStartupGtm,
  onMyBetsFilterHandling,
  handleCancelBetNotificationGtm,
  selectedNewCustomDateGtm,
  sendTimeframeSelectionGtmEvent,
  onTabClickGtm
} from "@tvg/sh-lib-my-bets/utils/gtm";
import {
  isLoadingMyBets,
  openBetCancelModal,
  closeWagerCancellationRulesModal,
  setCustomEndDate,
  setCustomStartDate,
  closeTrackRulesModal,
  openWagerCancellationRulesModal
} from "@tvg/sh-lib-my-bets/redux/actions";
import { MyBetsDynamicFilter } from "@tvg/sh-lib-my-bets/redux/types";
import {
  RaceInfoMyBets,
  RaceProgram,
  RaceWagerType,
  RaceProgramRaceTrack
} from "@tvg/ts-types/Race";
import {
  getSelectedSettledTab,
  getSelectedTab,
  getDeletedBets,
  getIsLoadingMyBets,
  getStatusFilters,
  getStatusFiltersCount,
  getTrackFilters,
  getTrackFiltersCount,
  getIsWagerCancellationRulesModalOpen,
  getBetCancelLimitationsByState,
  getBetCancel,
  getBetTypeFilters,
  getBetTypeFiltersCount,
  getMyBetsPaginationConf,
  getCustomStartDate,
  getCustomEndDate,
  getIsCustomTimeFrame,
  getRedirectWithoutActiveBetsToggle,
  getIsTrackRulesModalOpen,
  getMyBetsTrackRulesMessage
} from "@tvg/sh-lib-my-bets/redux/selectors";
import {
  shouldRenderWager,
  isSuccessBetCancel
} from "@tvg/sh-lib-my-bets/utils/general";
import {
  getQueryDynamicOptions,
  selectNewStatusFilters,
  setDynamicFilters,
  resetAllFilters,
  selectNewTimeFrame,
  updateIsCustomTimeFrame,
  reduceDynamicFilters,
  clearAllFilters,
  clearDrowpdownFilters
} from "@tvg/sh-lib-my-bets/utils/filters";
import groupByWagerType from "@tvg/desktop-bet/src/utils/groupByWagerType";
import useUpdateActiveBetsWithProbables from "@tvg/sh-lib-my-bets/hooks/useUpdateActiveBetsWithProbables";
import useMyBetsCountersSubscription from "@tvg/sh-lib-my-bets/hooks/useMyBetsCountersSubscription";
import useMyBetsSubscription from "@tvg/sh-lib-my-bets/hooks/useMyBetsSubscription";
import useGraphRacesSubscription from "@tvg/sh-lib-my-bets/hooks/useGraphRacesSubscription";
import usePagination from "@tvg/sh-lib-my-bets/hooks/usePagination";
import useTabReset from "@tvg/sh-lib-my-bets/hooks/useTabReset";
import useMyBetsUpdateRaces from "@tvg/sh-lib-my-bets/hooks/useMyBetsUpdateRaces";
import useClearAllFilters from "@tvg/sh-lib-my-bets/hooks/useClearAllFilters";
import BehgOptions from "@tvg/sh-lib-my-bets/graphql/behgOptions.graph";
import ApolloOptions from "@tvg/sh-lib-my-bets/graphql/options.graph";
import GroupWagersQuery from "@tvg/sh-lib-my-bets/graphql/queries/GroupWagers.graphql";
import AllWagerTypesQuery from "@tvg/sh-lib-my-bets/graphql/queries/AllWagerTypes.graphql";
import WagersSubscription from "@tvg/sh-lib-my-bets/graphql/subscriptions/WagersSubscription.graphql";
import RacesSubscription from "@tvg/sh-lib-my-bets/graphql/subscriptions/RacesSubscription.graphql";
import { Props, BetCancelResult } from "@tvg/sh-lib-my-bets/utils/types";
import { BetBasicInfo } from "@tvg/ts-types/Bet";
import SettledGraphOptions from "@tvg/sh-lib-my-bets/graphql/optionsSettled.graph";
import ActiveBetsQuery from "@tvg/sh-lib-my-bets/graphql/queries/activeBetsQuery.graphql";
import SettledBetsQuery from "@tvg/sh-lib-my-bets/graphql/queries/settledBetsQuery.graphql";
import GraphOptions from "@tvg/sh-lib-my-bets/graphql/optionsActive.graph";
import ActiveBetsSubscription from "@tvg/sh-lib-my-bets/graphql/subscriptions/activeBetsSubscription.graphql";
import WagerCancellationRulesModal from "@tvg/atomic-ui/_templates/WagerCancellationRulesModal";
import MyBetsHeaderDesktop from "@tvg/atomic-ui/_templates/MyBetsHeaderDesktop";
import MyBetsBodyDesktop from "@tvg/atomic-ui/_templates/MyBetsBodyDesktop";
import { datePreset } from "@tvg/atomic-ui/_molecule/MyBetsDesktopSettledFilters/utils";
import { DatePickerStatus } from "@tvg/atomic-ui/_molecule/MyBetsDesktopSettledFilters/types";
import TrackRulesModal from "@tvg/atomic-ui/_templates/TrackRulesModal";
import { getTrackRulesMessage } from "@tvg/sh-lib-my-bets/utils/track";
import { isMultiRaceBet } from "@tvg/sh-lib-my-bets/utils/raceDetails";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import {
  getAccountNumber,
  getBalance,
  getResidenceState
} from "@urp/store-selectors";
import { WroWagerGroup } from "@tvg/ts-types/WroWager";
import RepeatBetModal from "@tvg/pp/src/components/RepeatBetModal";
import MyBetsRow from "./MyBetsRow";

export const buildRace = (race: RaceInfoMyBets | undefined): RaceProgram => {
  if (!race) {
    return {};
  }
  const {
    bettingInterests,
    status,
    wagerTypes,
    id,
    trackName,
    trackCode,
    postTime,
    number,
    mtp,
    probables,
    type,
    numRunners
  } = race;

  const wagerTypesSorted = groupByWagerType(wagerTypes).sort(
    (a: RaceWagerType, b: RaceWagerType) => a.type.id - b.type.id
  );

  return {
    bettingInterests,
    status,
    id,
    track: { trackName, trackCode } as RaceProgramRaceTrack,
    mtp,
    postTime,
    raceNumber: number.toString(),
    probables,
    type,
    wagerTypes: wagerTypesSorted,
    numRunners,
    wagerable: !["SK", "C", "RO"].includes(status.code)
  };
};

export const MyBetsDesktopComponent: FC<Props> = ({
  queryBets,
  races,
  dispatch,
  wagerProfile,
  totalActiveBets = 0,
  totalFutureBets = 0,
  totalSettledBets,
  selectedTab,
  selectedSettledTab,
  isLoading,
  isLoadingGraph,
  isLoadingBehg,
  isLoadingActiveTotals,
  isLoadingSettledTotals,
  isLogged,
  accountNumber,
  activeWageredAmount = 0,
  settledWageredAmount = 0,
  futureWageredAmount = 0,
  subscribeToMore,
  subscribeToMoreGraph,
  statusFilters = {
    Cancelled: false,
    Wins: false,
    Refunded: false,
    Lost: false
  },
  statusFilterCount,
  trackFilters,
  trackFilterCount,
  raceDate: currentRaceDate,
  refetchSettled,
  wagerCancellationModal,
  userLocation,
  cancelLimitationsByState,
  betCancel,
  queryTrackList,
  wagerTypeFilter,
  betTypeFilters,
  betTypeFilterCount,
  myBetsPaginationConf,
  fetchMore,
  customStartDate = new Date(),
  customEndDate = new Date(),
  isCustomTimeFrame,
  redirectWithoutActiveBetsToggle
}) => {
  const [hasSentGtm, setHasSentGtm] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(DatePickerStatus.NONE);
  const [showPresetDate, setShowPresetDate] = useState(true);
  const [isMonthSelector, setIsMonthSelector] = useState(false);
  const [lastPresetSelected, setLastPresetSelected] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [isLoadingPaginationScroll, setIsLoadingPaginationScroll] =
    useState(false);
  const [scrollYPosition, setScrollYPosition] = useState(0);

  const [isStatusFilterExpanded, setIsStatusFilterExpanded] = useState(false);
  const [isBetTypeFilterExpanded, setIsBetTypeFilterExpanded] = useState(false);
  const [isTrackFilterExpanded, setTrackFilterExpanded] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isRepeatBetModalOpen, setIsRepeatBetModalOpen] = useState(false);
  const [selectedWager, setSelectedWager] = useState<BetBasicInfo>();
  const [selectedRace, setSelectedRace] = useState<
    RaceInfoMyBets | undefined
  >();

  const containerRef = useRef(null);

  const firstRender = useRef(true);

  const firstStatusFilterUpdate = useRef(true);
  const firstTracksFilterUpdate = useRef(true);
  const firstBetTypeFilterUpdate = useRef(true);

  const previouslySelectedTab = useRef(selectedTab);

  const userBalance = useSelector(getBalance);
  const trackRulesModal = useSelector(getIsTrackRulesModalOpen);
  const trackRulesMessages = useSelector(getMyBetsTrackRulesMessage);
  const [trackRulesMessagesObject, setTrackRulesMessagesObject] = useState({
    subtitle: "",
    message: [] as string[]
  });

  useEffect(() => {
    if (trackRulesModal?.betType) {
      setTrackRulesMessagesObject(
        getTrackRulesMessage(
          trackRulesMessages,
          trackRulesModal.betType,
          isMultiRaceBet(trackRulesModal.betType as WagerTypeCodesEnum)
        )
      );
    }
  }, [trackRulesModal?.betType]);

  useEffect(() => {
    dispatch(setCustomEndDate(datePreset.today.to));
    setSelectedMonth(datePreset.today.to);
    return () => {
      batch(() => {
        clearAllFilters(dispatch);
        setShowDatePicker(DatePickerStatus.NONE);
        setShowPresetDate(true);
        setIsMonthSelector(false);
        setLastPresetSelected({});
      });
    };
  }, [selectedTab]);

  useClearAllFilters({
    dispatch,
    redirectWithoutActiveBetsToggle: !!redirectWithoutActiveBetsToggle
  });

  useEffect(() => {
    if (
      typeof refetchSettled === "function" &&
      (selectedSettledTab === "TODAY" || selectedTab === "ACTIVE")
    )
      refetchSettled();
  }, [totalActiveBets]);

  useEffect(() => {
    if (
      typeof refetchSettled === "function" &&
      selectedSettledTab === "TODAY" &&
      !isCustomTimeFrame
    )
      refetchSettled();
  }, [isCustomTimeFrame]);

  useEffect(() => {
    dispatch(
      isLoadingMyBets(
        (isLoadingGraph || isLoadingBehg) && !isLoadingPaginationScroll
      )
    );
  }, [isLoadingGraph, isLoadingBehg, isLoadingPaginationScroll]);

  useEffect(() => {
    setSelectedFilters({ statusFilters, trackFilters, betTypeFilters });
  }, [statusFilters, trackFilters, betTypeFilters]);

  useLayoutEffect(() => {
    if (firstStatusFilterUpdate.current) {
      firstStatusFilterUpdate.current = false;
      return;
    }
    onMyBetsFilterHandling(
      "Status",
      selectedTab,
      selectedSettledTab,
      totalActiveBets,
      totalSettledBets,
      isStatusFilterExpanded
    );
  }, [isStatusFilterExpanded]);

  useEffect(() => {
    if (
      !isLoadingSettledTotals &&
      !isLoadingActiveTotals &&
      selectedTab !== "ACTIVE" &&
      !isCustomTimeFrame
    ) {
      sendTimeframeSelectionGtmEvent(
        selectedSettledTab,
        selectedTab,
        totalActiveBets,
        totalSettledBets
      );
    } else if (
      !isLoadingSettledTotals &&
      !isLoadingActiveTotals &&
      selectedTab !== "ACTIVE" &&
      isCustomTimeFrame
    ) {
      selectedNewCustomDateGtm(totalActiveBets, totalSettledBets);
    } else if (
      !isLoadingSettledTotals &&
      !isLoadingActiveTotals &&
      selectedTab !== "ACTIVE" &&
      isCustomTimeFrame &&
      showDatePicker !== DatePickerStatus.NONE
    ) {
      selectedNewCustomDateGtm(totalActiveBets, totalSettledBets);
    }
  }, [isLoadingActiveTotals, isLoadingSettledTotals]);

  useLayoutEffect(() => {
    if (firstTracksFilterUpdate.current) {
      firstTracksFilterUpdate.current = false;
      return;
    }
    onMyBetsFilterHandling(
      "Tracks",
      selectedTab,
      selectedSettledTab,
      totalActiveBets,
      totalSettledBets,
      isTrackFilterExpanded
    );
  }, [isTrackFilterExpanded]);

  useLayoutEffect(() => {
    if (firstBetTypeFilterUpdate.current) {
      firstBetTypeFilterUpdate.current = false;
      return;
    }
    onMyBetsFilterHandling(
      "Bet Type",
      selectedTab,
      selectedSettledTab,
      totalActiveBets,
      totalSettledBets,
      isBetTypeFilterExpanded
    );
  }, [isBetTypeFilterExpanded]);

  const redirectToSettled: boolean =
    (totalActiveBets === 0 &&
      totalFutureBets === 0 &&
      redirectWithoutActiveBetsToggle) ||
    false;

  const redirectToFuture: boolean =
    (totalActiveBets === 0 &&
      totalFutureBets > 0 &&
      redirectWithoutActiveBetsToggle) ||
    false;

  useTabReset(dispatch, redirectToSettled, redirectToFuture);

  useMyBetsCountersSubscription(
    ActiveBetsSubscription,
    isLogged,
    accountNumber,
    subscribeToMore
  );

  useMyBetsSubscription(
    WagersSubscription,
    isLogged,
    subscribeToMore,
    selectedTab,
    selectedSettledTab,
    selectedFilters,
    !!isCustomTimeFrame,
    accountNumber
  );

  useGraphRacesSubscription(
    RacesSubscription,
    isLogged,
    subscribeToMoreGraph,
    selectedSettledTab,
    wagerProfile,
    get(queryBets, "bets", [])
  );

  useMyBetsUpdateRaces({ dispatch, races });

  const activeBetsWithProbables = useUpdateActiveBetsWithProbables(
    {
      queryBets,
      races,
      selectedTab
    },
    true
  );

  useEffect(() => {
    const handleScroll = throttle(
      () => {
        setScrollYPosition(window.scrollY);
      },
      100,
      { trailing: true }
    );

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  usePagination(
    fetchMore,
    containerRef,
    typeof window !== "undefined" ? document.body : null,
    scrollYPosition,
    isLoadingPaginationScroll,
    setIsLoadingPaginationScroll,
    selectedTab,
    selectedSettledTab,
    get(myBetsPaginationConf, "intersectionWagerPercentage", 0.9),
    selectedFilters,
    formatDateToMMDDYYYY(customStartDate),
    formatDateToMMDDYYYY(customEndDate)
  );

  useEffect(() => {
    if (
      onMyBetsStartupGtm(
        true,
        totalActiveBets,
        totalSettledBets,
        isLoadingActiveTotals || isLoadingSettledTotals,
        hasSentGtm,
        setHasSentGtm
      )
    ) {
      onTabClickGtm({
        selectedTab,
        selectedSettledTab,
        activeBetsCount: totalActiveBets,
        settledBetsCount: totalSettledBets,
        futureBetsCount: totalFutureBets,
        previousSelectedTab: "None"
      });
    }
  }, [
    totalActiveBets,
    totalSettledBets,
    isLoadingActiveTotals,
    isLoadingSettledTotals
  ]);

  useEffect(() => {
    if (isSuccessBetCancel(get(betCancel, "result") as BetCancelResult)) {
      ToastManager.show({
        message: "Bet Cancelled",
        variant: "success",
        hasDismissButton: true,
        actionLink: (
          <ToastMessageLink
            variant="success"
            onPress={() => {
              dispatch(openWagerCancellationRulesModal({ hasBack: false }));
              handleCancelBetNotificationGtm(
                selectedTab,
                selectedSettledTab,
                totalActiveBets,
                totalSettledBets
              );
            }}
            qaLabel="rules-button-notification"
          >
            See Rules
          </ToastMessageLink>
        )
      });
    }
  }, [betCancel]);

  const setDateRange = useCallback(
    (dateRange) => {
      if (dateRange.from !== customStartDate) {
        clearDrowpdownFilters(dispatch);
      }

      let showDateType = DatePickerStatus.NONE;
      if (!customStartDate && !!dateRange.from) {
        showDateType = DatePickerStatus.END_DATE;
      } else if (!dateRange.from) {
        showDateType = DatePickerStatus.START_DATE;
      }

      setShowDatePicker(showDateType);
      setIsMonthSelector(false);
      batch(() => {
        dispatch(setCustomStartDate(dateRange.from));
        dispatch(setCustomEndDate(dateRange.to));
      });
    },
    [customStartDate, showDatePicker]
  );

  const cancelRulesMessages = useMemo(
    () =>
      safeJSONParse({
        defaultValue: {},
        jsonToParse: `${cancelLimitationsByState}`
      }),
    [cancelLimitationsByState]
  );

  const togglePreset = useCallback(
    (showPreset) => {
      if (showPreset) {
        setDateRange(lastPresetSelected);
      } else {
        setShowDatePicker(DatePickerStatus.START_DATE);
        setLastPresetSelected({
          from: customStartDate,
          to: customEndDate
        });
        setDateRange({
          to: datePreset.today.to
        });
      }

      updateIsCustomTimeFrame(showPreset);
      setShowPresetDate(showPreset);
    },
    [lastPresetSelected, showPresetDate, customStartDate, customEndDate]
  );

  const mergedBetTypeOptions = getQueryDynamicOptions(
    wagerTypeFilter || [],
    betTypeFilters || []
  );
  const mergedTrackOptions = getQueryDynamicOptions(
    queryTrackList || [],
    trackFilters || []
  );

  const { keys: trackFilterKeys, values: trackFilterValues } =
    reduceDynamicFilters(mergedTrackOptions);
  const { keys: betTypeFilterKeys, values: betTypeFilterValues } =
    reduceDynamicFilters(mergedBetTypeOptions);

  const bets = useMemo(() => {
    const filtered = get(activeBetsWithProbables, "bets", []).filter(
      (bet: WroWagerGroup) =>
        shouldRenderWager(
          selectedTab,
          currentRaceDate,
          get(bet, "wagers[0].raceDate", ""),
          mergedTrackOptions
            .filter((track: typeof MyBetsDynamicFilter) => track.isActive)
            .map((track: typeof MyBetsDynamicFilter) => track.name),
          get(bet, "wagers[0].trackName", "")
        )
    );

    if (selectedTab !== "ACTIVE") {
      return filtered.sort(
        (a: WroWagerGroup, b: WroWagerGroup) =>
          new Date(get(b, "wagers[0].transactionDate")).getTime() -
          new Date(get(a, "wagers[0].transactionDate")).getTime()
      );
    }

    return filtered;
  }, [selectedTab, activeBetsWithProbables, currentRaceDate, trackFilterCount]);

  useEffect(() => {
    if (
      selectedTab === "SETTLED" &&
      !isLoadingSettledTotals &&
      previouslySelectedTab.current === "ACTIVE"
    ) {
      onTabClickGtm({
        selectedTab: "SETTLED",
        selectedSettledTab: "TODAY",
        activeBetsCount: totalActiveBets,
        settledBetsCount: totalSettledBets,
        previousSelectedTab: previouslySelectedTab.current
      });
      previouslySelectedTab.current = selectedTab;
    }
    if (selectedTab === "ACTIVE" && !isLoadingActiveTotals) {
      previouslySelectedTab.current = selectedTab;
    }
  }, [selectedTab, isLoadingSettledTotals]);

  const counters = (() => {
    switch (selectedTab) {
      case "SETTLED":
        return { counter: totalSettledBets, amount: settledWageredAmount };
      case "FUTURES":
        return { counter: totalFutureBets, amount: futureWageredAmount };
      case "ACTIVE":
      default:
        return { counter: totalActiveBets, amount: activeWageredAmount };
    }
  })();

  const hasBets = bets?.length === 0;

  const hasFilters =
    betTypeFilterCount !== 0 ||
    statusFilterCount !== 0 ||
    trackFilterCount !== 0;

  const filtersOffsetBottom = 210;

  useEffect(() => {
    shouldRedirectToSettled(
      isLoadingActiveTotals || isLoadingBehg,
      totalActiveBets,
      totalFutureBets,
      firstRender,
      dispatch,
      selectedTab,
      redirectWithoutActiveBetsToggle
    );
  }, [
    isLoadingActiveTotals,
    isLoadingBehg,
    totalActiveBets,
    totalFutureBets,
    selectedTab,
    redirectWithoutActiveBetsToggle,
    firstRender
  ]);

  const handleSelectedRace = (
    myBetsSelectedRace: RaceInfoMyBets | undefined
  ) => {
    setSelectedRace(myBetsSelectedRace);
  };

  const handleSelectedWager = (wager: BetBasicInfo) => {
    setSelectedWager(wager);
  };

  const buildRaces = () => {
    let availableRaces: RaceProgram[] = [];
    if (races && selectedRace !== undefined) {
      availableRaces = Object.values(races).map(buildRace);
    }
    return availableRaces;
  };

  const currentRace =
    races &&
    races.find(
      (race) =>
        race.id === `${selectedRace?.trackCode}-${selectedRace?.number}` &&
        race.raceDate === selectedRace?.raceDate
    );

  const handleResponsibleGamingError = () => {
    setIsRepeatBetModalOpen(false);
    mediator.base.dispatch({
      type: "OPEN_AW_PAGE",
      payload: { path: "/responsible-play" }
    });
  };

  return (
    <>
      {!isTvg5() && (
        <ToastManager
          qaLabel="toast-manager"
          variant="informational"
          message=""
          ref={(ref) => ToastManager.setRef(ref)}
        />
      )}
      <MyBetsHeaderDesktop
        title="My Bets"
        selectedTab={selectedTab}
        selectNewTimeFrame={selectNewTimeFrame(
          dispatch,
          selectedSettledTab,
          selectedTab
        )}
        tabs={buildTabs(
          totalActiveBets,
          totalFutureBets,
          selectedTab,
          dispatch
        )}
        isStatusFilterExpanded={isStatusFilterExpanded}
        setStatusExpanded={setIsStatusFilterExpanded}
        isStatusFilterDisabled={hasBets}
        isBetTypesFilterDisabled={hasBets}
        setStatusFilter={selectNewStatusFilters(
          statusFilters,
          dispatch,
          selectedSettledTab,
          totalActiveBets,
          totalSettledBets,
          setIsStatusFilterExpanded
        )}
        currentStatusFilters={statusFilters}
        statusFilterCount={statusFilterCount}
        trackList={trackFilterKeys}
        trackListToggles={trackFilterValues}
        betTypeList={betTypeFilterKeys}
        betTypeListToggles={betTypeFilterValues}
        betTypeFilterCount={betTypeFilterCount}
        setBetTypeListFilter={setDynamicFilters(
          dispatch,
          "BetType",
          mergedBetTypeOptions,
          selectedSettledTab,
          totalActiveBets,
          totalSettledBets,
          setIsBetTypeFilterExpanded
        )}
        isBetTypeFilterExpanded={isBetTypeFilterExpanded}
        setBetTypeFilterExpanded={setIsBetTypeFilterExpanded}
        setTrackListFilter={setDynamicFilters(
          dispatch,
          "Tracks",
          mergedTrackOptions,
          selectedSettledTab,
          totalActiveBets,
          totalSettledBets,
          setTrackFilterExpanded
        )}
        isTrackFilterExpanded={isTrackFilterExpanded}
        setTrackFilterExpanded={setTrackFilterExpanded}
        isTracksFilterDisabled={hasBets}
        trackFilterCount={trackFilterCount}
        counters={counters}
        isClearAllDisabled={
          betTypeFilterCount === 0 &&
          trackFilterCount === 0 &&
          statusFilterCount === 0
        }
        clearAllFilters={resetAllFilters(
          dispatch,
          selectedSettledTab,
          totalActiveBets,
          totalSettledBets
        )}
        toggleCustom={updateIsCustomTimeFrame(dispatch)}
        setShowDatePicker={(showDate: DatePickerStatus) => {
          setIsMonthSelector(false);
          setShowDatePicker(showDate);
        }}
        showDatePicker={showDatePicker}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        onDateChange={setDateRange}
        dateRange={{
          from: customStartDate ? new Date(customStartDate) : undefined,
          to: new Date(customEndDate)
        }}
        showPresetDate={showPresetDate}
        onPresetDateChange={togglePreset}
        isMonthSelector={isMonthSelector}
        setIsMonthSelector={setIsMonthSelector}
        filtersOffsetBottom={filtersOffsetBottom}
      />
      <MyBetsBodyDesktop
        bets={bets}
        isLoading={isLoading}
        emptyTitle={getEmptyStateTitle(
          selectedTab,
          selectedSettledTab,
          !showPresetDate,
          new Date(customStartDate),
          new Date(customEndDate),
          hasFilters
        )}
        emptyMessage={getEmptyStateMessage(selectedTab)}
        isFetchingPagination={isLoadingPaginationScroll}
        selectedTab={selectedTab}
        scrollYPosition={scrollYPosition}
        containerRef={containerRef}
      >
        {selectedWager && selectedRace && buildRaces && (
          <RepeatBetModal
            userBalance={userBalance}
            race={buildRace(currentRace)}
            races={buildRaces()}
            raceWager={selectedWager!}
            isRepeatBetModalOpen={isRepeatBetModalOpen}
            setRepeatBetModalOpen={setIsRepeatBetModalOpen}
            onResponsibleGamingError={handleResponsibleGamingError}
          />
        )}
        {bets.map((bet: WroWagerGroup) => (
          <MyBetsRow
            key={`myBetsRow-${get(bet, "wagers[0].id")}`}
            bet={bet}
            races={races || []}
            currentRaceDate={currentRaceDate}
            qaLabel="mybets-wager-row"
            activeBetsCounter={totalActiveBets}
            settledBetsCounter={totalSettledBets}
            handleSelectedRace={handleSelectedRace}
            handleSelectedWager={handleSelectedWager}
            setIsRepeatBetModalOpen={setIsRepeatBetModalOpen}
          />
        ))}
      </MyBetsBodyDesktop>
      <TrackRulesModal
        isOpen={trackRulesModal.isOpen}
        onClose={() => {
          dispatch(closeTrackRulesModal());
        }}
        subtitle={get(trackRulesMessagesObject, "subtitle")}
        messages={get(trackRulesMessagesObject, "message")}
        layerOffset={100}
      />

      <WagerCancellationRulesModal
        isOpen={wagerCancellationModal.isOpen}
        hasBack={wagerCancellationModal.hasBack}
        onClose={() => {
          dispatch(closeWagerCancellationRulesModal());
        }}
        userLocation={userLocation}
        messages={cancelRulesMessages}
        onBack={() => {
          dispatch(closeWagerCancellationRulesModal());
          setTimeout(() => {
            // @ts-ignore
            dispatch(openBetCancelModal());
            mediator.base.dispatch({ type: "OPEN_BET_CANCEL_MODAL" });
          }, 200);
        }}
      />
    </>
  );
};

// @ts-ignore
export default connect(
  (store: Store) => ({
    accountNumber: getAccountNumber(store),
    isLogged: get(store, "userData.logged"),
    wagerProfile: get(store, "userData.user.profile", ""),
    selectedTab: getSelectedTab(store),
    selectedSettledTab: getSelectedSettledTab(store),
    deletedBets: getDeletedBets(store),
    isLoading: getIsLoadingMyBets(store),
    statusFilters: getStatusFilters(store),
    statusFilterCount: getStatusFiltersCount(store),
    trackFilters: getTrackFilters(store),
    trackFilterCount: getTrackFiltersCount(store),
    betTypeFilters: getBetTypeFilters(store),
    betTypeFilterCount: getBetTypeFiltersCount(store),
    wagerCancellationModal: getIsWagerCancellationRulesModalOpen(store),
    userLocation: getResidenceState(store),
    cancelLimitationsByState: getBetCancelLimitationsByState(store),
    betCancel: getBetCancel(store),
    myBetsPaginationConf: getMyBetsPaginationConf(store),
    customStartDate: getCustomStartDate(store),
    customEndDate: getCustomEndDate(store),
    isCustomTimeFrame: getIsCustomTimeFrame(store),
    redirectWithoutActiveBetsToggle: getRedirectWithoutActiveBetsToggle(store)
  }),
  // @ts-ignore
  (dispatch: Dispatch<any>) => ({ dispatch })
)(
  // @ts-ignore
  compose(
    // @ts-ignore
    graphql(ActiveBetsQuery, GraphOptions),
    // @ts-ignore
    graphql(SettledBetsQuery, SettledGraphOptions),
    // @ts-ignore
    graphql(GroupWagersQuery, BehgOptions),
    // @ts-ignore
    graphql(AllWagerTypesQuery, ApolloOptions)
  )(MyBetsDesktopComponent)
);
