import React from "react";
import { AlertInLine } from "../../../AlertInLine";

import { WagerCardNotificationProps } from "./types";

const WagerCardNotification = ({
  scratchedLegText,
  scratchedTitle,
  shouldNotHideScratchNotification,
  showScratchedNotification,
  onDismissPress,
  wagerId,
  onNotificationPress
}: WagerCardNotificationProps) =>
  showScratchedNotification && shouldNotHideScratchNotification ? (
    <AlertInLine
      message={scratchedLegText}
      title={scratchedTitle}
      type="fixed"
      variant="informational"
      qaLabel={`notification-scratch-${wagerId}`}
      marginBottom="space-5"
      onDismissPress={() => onDismissPress(false)}
      linkLabel="See Rules"
      onClick={onNotificationPress}
    />
  ) : null;

export default WagerCardNotification;
