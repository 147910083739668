import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: 2;
  justify-content: flex-end;
`;

export const TextContainer = styled.div<{ shouldWrap: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
  ${({ shouldWrap }) =>
    shouldWrap &&
    css`
      max-width: min-content;
    `};
`;

export const WinAmountContainer = styled.div<{ shouldWrap: boolean }>`
  background-color: ${({ theme }) => theme.colorTokens.content.positive};
  transform: skew(-15deg);
  padding: 0 4px;
  text-align: center;
  ${({ shouldWrap }) =>
    !shouldWrap &&
    css`
      min-width: 90px;
    `};
`;

export const WinAmountValue = styled.span`
  display: inline-flex;
  transform: skew(15deg);
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.bold};
      font-size: ${theme.fontSizes.s};
      color: ${theme.colorTokens.background.surface};
    `};
`;

export const WinAmountDescriptionWrapper = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
`;
