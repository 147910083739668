import React, { FC } from "react";

export const India: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_2930)">
      <path d="M32 0H0V6.66667H32V0Z" fill="#D67A11" />
      <path d="M32 6.66663H0V13.3333H32V6.66663Z" fill="#F8F8F9" />
      <path d="M32 13.3334H0V20H32V13.3334Z" fill="#38AB4F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.29434C14.4069 7.29434 13.114 8.50646 13.114 9.99997C13.114
        11.4935 14.4069 12.7056 16 12.7056C17.593 12.7056 18.886 11.4935 18.886
        9.99997C18.886 8.50646 17.593 7.29434 16 7.29434ZM17.6161
        11.7965C17.6161 11.7965 17.6162 11.7749 17.5931 11.7749C17.5469 11.7316
        17.4776 11.7099 17.4084 11.7532C17.3622 11.7965 17.3391 11.8614 17.3852
        11.9264L17.4084 11.948C17.2929 12.013 17.1774 12.0779 17.0389
        12.1428V12.1212C17.0158 12.0562 16.9466 12.0346 16.8773 12.0562C16.8081
        12.0779 16.785 12.1428 16.808 12.2078C16.808 12.2078 16.8081 12.2294
        16.8312 12.2294C16.6926 12.2727 16.5772 12.316 16.4156
        12.3376V12.316C16.4156 12.2511 16.3463 12.2078 16.2771 12.2078C16.2078
        12.2078 16.1616 12.2727 16.1616 12.3376V12.3593C16.0924 12.3593 16.0231
        12.3593 15.9538 12.3593C15.8845 12.3593 15.8153 12.3593 15.746
        12.3593V12.3376C15.746 12.2727 15.6998 12.2078 15.6306 12.2078C15.5613
        12.2078 15.492 12.2511 15.492 12.316V12.3376C15.3535 12.316 15.215
        12.2727 15.0764 12.2294C15.0764 12.2294 15.0765 12.2078 15.0995
        12.2078C15.1226 12.1428 15.0995 12.0779 15.0303 12.0562C14.961 12.0346
        14.8918 12.0562 14.8687 12.1212V12.1428C14.7532 12.0779 14.6147 12.013
        14.4992 11.948L14.5223 11.9264C14.5685 11.8831 14.5454 11.7965 14.4992
        11.7532C14.4531 11.7099 14.3608 11.7316 14.3146 11.7749C14.3146 11.7749
        14.3146 11.7965 14.2915 11.7965C14.176 11.7099 14.0837 11.6233 13.9913
        11.5151C13.9913 11.5151 14.0144 11.5151 14.0144 11.4935C14.0606 11.4502
        14.0837 11.3853 14.0375 11.3203C13.9913 11.277 13.9221 11.2554 13.8528
        11.2987L13.8297 11.3203C13.7604 11.2121 13.6912 11.1039 13.6219
        10.974H13.645C13.7143 10.9524 13.7374 10.8874 13.7143 10.8225C13.6912
        10.7575 13.6219 10.7359 13.5527 10.7575C13.5527 10.7575 13.5295 10.7575
        13.5295 10.7792C13.4834 10.6493 13.4372 10.5411 13.4141
        10.3896H13.4372C13.5065 10.3896 13.5527 10.3246 13.5527 10.2597C13.5527
        10.1948 13.4834 10.1515 13.4141 10.1515H13.3911C13.3911 10.0865 13.3911
        10.0216 13.3911 9.95668C13.3911 9.89175 13.3911 9.82681 13.3911
        9.76187H13.4141C13.4834 9.76187 13.5527 9.71858 13.5527 9.65365C13.5527
        9.58871 13.5065 9.52378 13.4372 9.52378H13.4141C13.4372 9.39391 13.4834
        9.26404 13.5295 9.13417C13.5295 9.13417 13.5527 9.13417 13.5527
        9.15582C13.6219 9.17746 13.6912 9.15581 13.7143 9.09088C13.7374 9.02594
        13.7143 8.96101 13.645 8.93937H13.6219C13.6912 8.83114 13.7604 8.70127
        13.8297 8.59304L13.8528 8.61469C13.899 8.65798 13.9913 8.63633 14.0375
        8.59304C14.0837 8.54975 14.0606 8.46317 14.0144 8.41988C14.0144 8.41988
        13.9913 8.41988 13.9913 8.39824C14.0837 8.29001 14.176 8.20343 14.2915
        8.11685C14.2915 8.11685 14.2915 8.1385 14.3146 8.1385C14.3608 8.18179
        14.43 8.20343 14.4992 8.16014C14.5454 8.11685 14.5685 8.05192 14.5223
        7.98698L14.4992 7.96534C14.6147 7.9004 14.7301 7.83547 14.8687
        7.77053V7.79218C14.8918 7.85711 14.961 7.87876 15.0303 7.85711C15.0995
        7.83547 15.1226 7.77053 15.0995 7.7056C15.0995 7.7056 15.0995 7.68395
        15.0764 7.68395C15.215 7.64066 15.3304 7.59737 15.492
        7.57573V7.59737C15.492 7.66231 15.5613 7.7056 15.6306 7.7056C15.6998
        7.7056 15.746 7.64066 15.746 7.57573V7.55408C15.8153 7.55408 15.8845
        7.55408 15.9538 7.55408C16.0231 7.55408 16.0924 7.55408 16.1616
        7.55408V7.57573C16.1616 7.64066 16.2078 7.7056 16.2771 7.7056C16.3463
        7.7056 16.4156 7.66231 16.4156 7.59737V7.57573C16.5541 7.59737 16.6926
        7.64066 16.8312 7.68395C16.8312 7.68395 16.8311 7.7056 16.808
        7.7056C16.785 7.77053 16.8081 7.83547 16.8773 7.85711C16.9466 7.87876
        17.0158 7.85711 17.0389 7.79218V7.77053C17.1544 7.83547 17.2929 7.9004
        17.4084 7.96534L17.3852 7.98698C17.3391 8.03027 17.3622 8.11685 17.4084
        8.16014C17.4545 8.20343 17.5469 8.18179 17.5931 8.1385C17.5931 8.1385
        17.593 8.11685 17.6161 8.11685C17.7316 8.20343 17.8239 8.29001 17.9163
        8.39824C17.9163 8.39824 17.8932 8.39824 17.8932 8.41988C17.847 8.46317
        17.8239 8.52811 17.8701 8.59304C17.9163 8.65798 17.9856 8.65798 18.0548
        8.61469L18.0779 8.59304C18.1471 8.70127 18.2164 8.80949 18.2857
        8.93937H18.2626C18.1933 8.96101 18.1702 9.02594 18.1933 9.09088C18.2164
        9.15581 18.2857 9.17746 18.355 9.15582C18.355 9.15582 18.378 9.15581
        18.378 9.13417C18.4242 9.26404 18.4704 9.37226 18.4935
        9.52378H18.4704C18.4012 9.52378 18.355 9.58871 18.355 9.65365C18.355
        9.71858 18.4242 9.76187 18.4935 9.76187H18.5166C18.5166 9.82681 18.5166
        9.89175 18.5166 9.95668C18.5166 10.0216 18.5166 10.0865 18.5166
        10.1515H18.4935C18.4242 10.1515 18.355 10.1948 18.355 10.2597C18.355
        10.3246 18.4012 10.3896 18.4704 10.3896H18.4935C18.4704 10.5195 18.4242
        10.6493 18.378 10.7792C18.378 10.7792 18.355 10.7792 18.355
        10.7575C18.2857 10.7359 18.2164 10.7575 18.1933 10.8225C18.1702 10.8874
        18.1933 10.9524 18.2626 10.974H18.2857C18.2164 11.0822 18.1471 11.2121
        18.0779 11.3203L18.0548 11.2987C18.0087 11.2554 17.9163 11.277 17.8701
        11.3203C17.8239 11.3636 17.847 11.4502 17.8932 11.4935C17.8932 11.4935
        17.9163 11.4935 17.9163 11.5151C17.8239 11.6233 17.7316 11.7316 17.6161
        11.7965ZM16.4617 10.2814C16.4386 10.303 16.4386 10.3246 16.4156
        10.3463L16.7619 10.606L17.7547 11.645L16.6464 10.7143L16.3694
        10.3896C16.3463 10.4112 16.3232 10.4112 16.3001 10.4329L16.5772
        10.7575L17.2467 12.013L16.4156 10.8441L16.2308 10.4545C16.2078 10.4762
        16.1847 10.4762 16.1616 10.4762L16.3232 10.8658L16.6234 12.2294L16.1616
        10.8874L16.0923 10.4762C16.0693 10.4762 16.0462 10.4762 16.0231
        10.4762L16.0692 10.8874L15.9769 12.2943L15.8845 10.8874L15.9307
        10.4762C15.9077 10.4762 15.8846 10.4762 15.8615 10.4762L15.7922
        10.8874L15.3304 12.2294L15.6306 10.8658L15.7922 10.4762C15.7691 10.4762
        15.746 10.4545 15.7229 10.4545L15.5382 10.8441L14.7071 12.013L15.3766
        10.7575L15.6536 10.4329C15.6305 10.4112 15.6075 10.4112 15.5844
        10.3896L15.3073 10.7143L14.1991 11.645L15.1919 10.606L15.5382
        10.3463C15.5151 10.3246 15.5151 10.303 15.492 10.2814L15.1457
        10.5411L13.8066 11.1688L15.0534 10.3896L15.469 10.2164C15.4459 10.1948
        15.4459 10.1731 15.4459 10.1515L15.0303 10.303L13.5757 10.5844L15.0072
        10.1515L15.4459 10.0865C15.4459 10.0649 15.4459 10.0433 15.4459
        10.0216L15.0072 10.0649L13.5065 9.97832L15.0072 9.89174L15.4459
        9.93503C15.4459 9.91339 15.4459 9.89174 15.4459 9.8701L15.0072
        9.80517L13.5757 9.37227L15.0303 9.65365L15.4459 9.80517C15.4459 9.78352
        15.469 9.76188 15.469 9.74023L15.0534 9.56707L13.8066 8.78785L15.1457
        9.41555L15.492 9.67529C15.5151 9.65365 15.5151 9.632 15.5382
        9.61036L15.1919 9.35062L14.1991 8.31166L15.3073 9.24239L15.5844
        9.56707C15.6075 9.54542 15.6305 9.54542 15.6536 9.52378L15.3766
        9.1991L14.7071 7.94369L15.5382 9.11252L15.7229 9.50213C15.746 9.48049
        15.7691 9.48049 15.7922 9.48049L15.6306 9.09088L15.3304 7.72724L15.7922
        9.06923L15.8615 9.48049C15.8846 9.48049 15.9077 9.48049 15.9307
        9.48049L15.8845 9.06923L15.9769 7.66231L16.0692 9.06923L16.0231
        9.48049C16.0462 9.48049 16.0693 9.48049 16.0923 9.48049L16.1616
        9.06923L16.6234 7.72724L16.3232 9.09088L16.1616 9.48049C16.1847 9.48049
        16.2078 9.50213 16.2308 9.50213L16.4156 9.11252L17.2467 7.94369L16.5772
        9.1991L16.3001 9.52378C16.3232 9.54542 16.3463 9.54542 16.3694
        9.56707L16.6464 9.24239L17.7547 8.31166L16.7619 9.35062L16.4156
        9.61036C16.4386 9.632 16.4386 9.65365 16.4617 9.67529L16.808
        9.41555L18.1472 8.78785L16.9004 9.56707L16.4848 9.74023C16.5079
        9.76188 16.5079 9.78352 16.5079 9.80517L16.9235 9.65365L18.378
        9.37227L16.9466 9.80517L16.5079 9.8701C16.5079 9.89174 16.5079 9.91339
        16.5079 9.93503L16.9466 9.89174L18.4473 9.97832L16.9466 10.0649L16.5079
        10.0216C16.5079 10.0433 16.5079 10.0649 16.5079 10.0865L16.9466
        10.1515L18.378 10.5844L16.9235 10.303L16.5079 10.1515C16.5079 10.1731
        16.4848 10.1948 16.4848 10.2164L16.9004 10.3896L18.1472 11.1688L16.808
        10.5411L16.4617 10.2814Z"
        fill="#1C3D70"
      />
    </g>
    <defs>
      <clipPath id="clip0_18106_2930">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
