import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import type { Theme } from "@tvg/design-system";

const wrapper = `
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const RaceHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RaceHeaderWrapper = styled(Link)`
  outline: none;
  color: inherit;
  text-decoration: inherit;
  ${wrapper}
`;

export const RaceWatchReplayWrapper = styled.div<{
  theme: Theme;
}>`
  margin-top: ${({ theme }) => theme.space["space-4"]};
  ${wrapper}
`;

export const LegMtpWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RaceHeaderInfo = styled.div<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin-right: ${({ theme }) => theme.space["space-4"]};
  overflow: hidden;
`;

export const RaceFeaturedEventName = styled.div<{
  theme: Theme;
}>`
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.bold};
      font-size: ${theme.fontSizes.s};
      color: ${theme.colorTokens.button.promo.background.active};
    `};
`;

export const RaceTrackNameWrapper = styled.div<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: row;
  white-space: pre;
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.bold};
      font-size: ${theme.fontSizes.m};
      color: ${theme.colorTokens.content.default};
      font-size: ${theme.fontSizes.m};
      line-height: ${theme.lineHeights.l};
    `};
`;

export const RaceTrackName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RaceTrackNumber = styled.div``;

export const RaceBetsInfo = styled.div<{
  theme: Theme;
}>`
  ${({ theme }) =>
    css`
      font-family: ${theme.fonts.regular};
      font-size: ${theme.fontSizes.s};
      color: ${theme.colorTokens.content.subtle};
      margin-top: ${theme.space["space-1"]};
      line-height: ${theme.lineHeights.s};
    `};
`;

export const RaceHeaderActions = styled.div<{
  theme: Theme;
}>`
  display: flex;
  align-items: center;

  > a,
  > button {
    min-width: 125px;
  }
  > * {
    &:first-child {
      margin-left: 0;
    }

    margin-left: ${({ theme }) => theme.space["space-4"]};
  }
`;

export const VideoButtonIconWrapper = styled.span<{
  theme: Theme;
}>`
  display: flex;
  margin-right: ${({ theme }) => theme.space["space-3"]};
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
`;
