import { SaddleSelectionsProps } from "../../types";

export const rangeSelections = (
  selections: SaddleSelectionsProps[],
  scratches?: string[] | undefined,
  wagerDetails?: boolean
) =>
  selections
    .reduce((ranges: string[][], curr: SaddleSelectionsProps) => {
      const lastSubArray: string[] = ranges[ranges.length - 1];
      const prevCurr = (+curr.number - 1).toString();
      const nextCurr = +curr.number + 1;

      const lastSubArrayChar = lastSubArray?.[lastSubArray?.length - 1]; // 4A or 4
      const prevArr = lastSubArrayChar?.match(/[a-zA-Z]+|[0-9]+/g); // ["4","A"] or ["4"]
      const isPrevCoupledRunner = prevArr && prevArr?.length > 1;

      if (
        !lastSubArray ||
        scratches?.includes(curr.number) ||
        scratches?.includes(prevCurr) ||
        (isPrevCoupledRunner &&
          (+curr.number - 1).toString() !== prevArr?.[0]) ||
        (!isPrevCoupledRunner &&
          lastSubArray[lastSubArray.length - 1] !== prevCurr &&
          !Number.isNaN(+curr.number)) ||
        Number.isNaN(nextCurr)
      ) {
        ranges.push([]);
      }
      ranges[ranges.length - 1].push(curr.number);

      return ranges;
    }, [])
    .map((range: string[]) => {
      if (range.length > 2 && !wagerDetails) {
        return [range[0], range[range.length - 1]].join("-");
      }
      return range;
    });
