// @flow
import mediator from "@tvg/mediator";

type AppsFlyerPayload = {
  key: string,
  values?: {
    [key: string]: string | number
  }
};

type AppsFlyerEventPayload = {
  type: string,
  payload: {
    appsflyer: AppsFlyerPayload
  }
};

const APPS_FLYER_TYPE = "APPSFLYER_EVENT";

const APP_FLYER_EMPTY_VALUES = { values: { "": "" } };

const sendToAppsFlyer = (payload: AppsFlyerPayload) => {
  const appsFlyerPayload: AppsFlyerEventPayload = {
    type: APPS_FLYER_TYPE,
    payload: {
      appsflyer: {
        ...APP_FLYER_EMPTY_VALUES,
        ...payload
      }
    }
  };
  if (
    window &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.APPSFLYER_EVENT
  ) {
    window.webkit.messageHandlers.APPSFLYER_EVENT.postMessage(appsFlyerPayload);
  } else {
    // $FlowFixMe
    mediator.ios.dispatch(appsFlyerPayload);
  }
};

export default sendToAppsFlyer;
