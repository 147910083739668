import React, { useCallback, useMemo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import { useColorTokens } from "@tvg/design-system/web/hooks";
import { get } from "lodash";
import { Saddle } from "../saddle";
import {
  IconContainer,
  SaddleDetailsParagraph,
  SaddleSelectionContainer,
  RowContainer,
  WillPaysContainer
} from "./styled-components";
import { rangeSelections } from "./utils";
import { ShowSelectionsProps } from "./types";
import { Paragraph } from "../typography";
import { Tag } from "../tag";
import { ParagraphFonts } from "../typography/paragraph/types";
import Icon from "../icon";

const SaddleNumbers = ({
  numWagerableRunners,
  selections,
  isDark,
  showBetType,
  scratches,
  wagerDetails = false,
  shouldShowRefundTag,
  breed = "thoroughbred",
  onHandicaping,
  hideWillPaysTag = false,
  showOdds
}: ShowSelectionsProps) => {
  const colorTokens = useColorTokens();
  const checkSaddleSelection = useCallback(
    (runnerNumber: string) =>
      selections.filter(
        (saddleSelection) => saddleSelection.number === runnerNumber
      ),
    [selections]
  );

  const hasScratch = scratches ? scratches?.length > 0 : false;
  const hasWinner = selections.some((selection) => selection.winner);
  const hasWillPays = selections.some((selection) => selection.willPay);

  const isAllSelections =
    numWagerableRunners &&
    Number(numWagerableRunners) === selections.length &&
    !wagerDetails;

  const isSaddleNumber = !isAllSelections && selections.length > 0;
  const rangedSelections: string[] = useMemo(
    () =>
      rangeSelections(selections, scratches, wagerDetails)
        .join()
        .split(/(-)/)
        .join()
        .split(","),
    [wagerDetails, selections, scratches]
  );

  const renderAllSelections = () =>
    isAllSelections ? (
      <Saddle all={isAllSelections} number="All" size="s" breed={breed} />
    ) : (
      // empty Selections
      <Saddle
        number="?"
        size="s"
        saddleColor={colorTokens.content.default}
        numberColor={colorTokens.component.input.base}
        mr="space-1"
      />
    );

  return (
    <>
      {!isSaddleNumber && !hasScratch && !onHandicaping && !wagerDetails ? (
        renderAllSelections()
      ) : (
        <SaddleSelectionContainer
          isDetailView={wagerDetails}
          hasThirdColumn={!!shouldShowRefundTag || hasWillPays}
        >
          {rangedSelections.map((item, index) => {
            const currentSaddleSelection = checkSaddleSelection(item)[0];

            const runnerDetailsStyle = {
              fontFamily: (currentSaddleSelection?.winner &&
              !currentSaddleSelection?.scratched
                ? "bold"
                : "medium") as ParagraphFonts,
              opacity: currentSaddleSelection?.isSettled ? "0.3" : "1"
            };

            return (
              <RowContainer
                key={`sanddle-arrow-${index.toString()}`}
                isDetailView={wagerDetails}
              >
                {item.includes("-") ? (
                  <IconContainer>
                    <Icon
                      size="m"
                      name="arrowRight"
                      lineColor={
                        isDark && showBetType
                          ? "content.onDark"
                          : "content.subtle"
                      }
                      qaLabel="runner-number-range-arrow"
                      mr="space-1"
                      shouldUseColorTokens
                    />
                  </IconContainer>
                ) : currentSaddleSelection ? (
                  <>
                    <Saddle
                      isScratched={
                        scratches?.includes(item) ||
                        (hasWinner && currentSaddleSelection.winner === false)
                      }
                      key={`Saddle-num-${currentSaddleSelection.number}`}
                      number={currentSaddleSelection.number}
                      numberColor={currentSaddleSelection.numberColor}
                      saddleColor={currentSaddleSelection.saddleColor}
                      all={currentSaddleSelection.number === "ALL"}
                      isWinner={currentSaddleSelection.winner}
                      breed={breed}
                      size="s"
                      mr={
                        !showBetType && index === rangedSelections.length
                          ? 0
                          : "space-1"
                      }
                    />
                    {wagerDetails && (
                      <SaddleDetailsParagraph
                        qaLabel="details-runner-name"
                        scratched={!!currentSaddleSelection.scratched}
                        {...runnerDetailsStyle}
                      >
                        {currentSaddleSelection.runnerName as string}
                      </SaddleDetailsParagraph>
                    )}
                    {wagerDetails &&
                      !!currentSaddleSelection.willPay &&
                      !hideWillPaysTag && (
                        <WillPaysContainer>
                          <Paragraph
                            qaLabel="details-will-pay-amount"
                            textAlign="center"
                            {...runnerDetailsStyle}
                          >
                            {formatCurrency(currentSaddleSelection.willPay)}
                          </Paragraph>
                        </WillPaysContainer>
                      )}
                    {wagerDetails &&
                      !!shouldShowRefundTag &&
                      !!currentSaddleSelection.scratched && (
                        <Tag label="REFUND" size="s" variant="warning" />
                      )}

                    {wagerDetails &&
                      currentSaddleSelection.winner &&
                      currentSaddleSelection.currentOdds &&
                      showOdds && (
                        <WillPaysContainer>
                          <Paragraph
                            qaLabel="details-will-pay-amount"
                            textAlign="center"
                            fontWeight={
                              currentSaddleSelection.winner ? "bold" : undefined
                            }
                          >
                            <>
                              {get(
                                currentSaddleSelection,
                                "currentOdds.numerator"
                              )}
                              {get(
                                currentSaddleSelection,
                                "currentOdds.denominator"
                              )
                                ? `/${get(
                                    currentSaddleSelection,
                                    "currentOdds.denominator"
                                  )}`
                                : ""}
                            </>
                          </Paragraph>
                        </WillPaysContainer>
                      )}
                  </>
                ) : null}
              </RowContainer>
            );
          })}
        </SaddleSelectionContainer>
      )}
    </>
  );
};

export default SaddleNumbers;
