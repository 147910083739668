// @flow

import React, { PureComponent, Fragment } from "react";
import { noop } from "lodash";

import type { NullaryFn } from "@tvg/types/Functional";

import ButtonLink from "../../_atom/Buttons/buttonLink";
import MessageBox from "../../_molecule/MessageBox";
import { arrowForward } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import { RaceButtonsContainer, ArrowIcon } from "./styled-components";

type Props = {
  keyLabel: string,
  title: string,
  message: string,
  isWarning: boolean,
  nextRaceUrl: string,
  homeUrl: string,
  tracksUrl: string,
  hasBorderBottom: boolean,
  onNextRaceClick: NullaryFn<mixed>,
  onChangeTrackClick: NullaryFn<mixed>
};

export default class RaceMessageRedirect extends PureComponent<Props> {
  static defaultProps = {
    keyLabel: "race-message-redirect",
    title: "",
    message: "",
    isWarning: false,
    homeUrl: "/",
    tracksUrl: "",
    nextRaceUrl: "",
    hasBorderBottom: false,
    onNextRaceClick: noop,
    onChangeTrackClick: noop
  };

  renderButtons = () => (
    <RaceButtonsContainer
      isWarning={this.props.isWarning}
      hasBorderBottom={this.props.hasBorderBottom}
    >
      {!this.props.tracksUrl && !this.props.nextRaceUrl && (
        <ButtonLink type="secondary" url={this.props.homeUrl}>
          Return to Homepage
        </ButtonLink>
      )}
      {this.props.tracksUrl && (
        <ButtonLink
          type="secondary"
          url={this.props.tracksUrl}
          onClick={this.props.onChangeTrackClick}
        >
          Change Track
        </ButtonLink>
      )}
      {this.props.nextRaceUrl && (
        <ButtonLink
          type="secondary"
          url={this.props.nextRaceUrl}
          onClick={this.props.onNextRaceClick}
        >
          <span>Next Race</span>
          <ArrowIcon
            icon={arrowForward}
            size={16}
            color={buildColor("grey", "900")}
          />
        </ButtonLink>
      )}
    </RaceButtonsContainer>
  );

  render() {
    return (
      <Fragment>
        <MessageBox
          key={this.props.keyLabel}
          type={this.props.isWarning ? "warning" : "info"}
          message={this.props.message}
          title={this.props.title}
        />
        {this.renderButtons()}
      </Fragment>
    );
  }
}
