// @flow
import React, {
  PureComponent,
  Fragment,
  type Element as ReactElement
} from "react";
import { noop, times, isFunction } from "lodash";
import { createPortal } from "react-dom";

import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type {
  RaceTypeCodeEnum,
  HandicapOptions,
  HandicapSortOptionsType
} from "@tvg/types/Race";
import formatSequencial from "@tvg/formatter/numeric";

import type { SelectionType } from "../../_atom/SelectionButton";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { key, arrowDown } from "../../_static/Icons/icons";
import InlineInfo from "../../_atom/InlineInfo";

import {
  RLHeader,
  RunnerInfoWrapper,
  BetAllWrapper,
  BetAllButton,
  Text,
  TitleBoldEllipsis,
  HandicappTypeWrapper,
  SeparatorLine,
  RLHandicap,
  HandicapWrapper,
  InlineContainer,
  FixedHandicapContainer,
  OptionTitle,
  BetAllOption
} from "./styled-components";

import type { HandicapSelectiontype } from "../../_templates/HandicappingList/handicappingOptions";

const getSelectionText = (
  selectionType: SelectionType,
  column: number,
  selected: boolean
) => {
  const text = [];
  switch (selectionType) {
    case "key":
      if (column === 0) {
        text.push(
          <Icon key="key-icon" icon={key} color={buildColor("blue", "800")} />
        );
      } else {
        text.push(<Text key="line1">{!selected ? "W/" : "RESET"}</Text>);
        text.push(<Text key="line2">ALL</Text>);
      }
      break;
    case "number":
      text.push(
        <Text key="line1">
          {!selected ? formatSequencial(column + 1) : "RESET"}
        </Text>
      );
      text.push(<Text key="line2">ALL</Text>);
      break;
    case "default":
    default:
      text.push(
        <Text verticalSpacer={!selected} key="line1">
          {!selected ? "BET" : "RESET"}
        </Text>
      );
      text.push(<Text key="line2">ALL</Text>);
      break;
  }

  return text;
};

type HeaderRef = { current: null | HTMLTableSectionElement };

type Props = {
  /**
   * Leg number for the race
   */
  leg: number,
  /**
   * Boolean to change the button state
   */
  isWagerable: boolean,
  /**
   * Flag to activate the leg layout
   */
  isLeg: boolean,
  /**
   * Flag to activate a key column
   */
  isKey: boolean,
  /**
   * Number of selection columns
   */
  numColumns: number,
  /**
   * Race type code
   */
  raceTypeCode: RaceTypeCodeEnum,
  /**
   * Boolean to change the button state.
   */
  isAllSelected: Array<boolean>,
  /**
   * Function to select all runners
   */
  onAllRunnersSelect: UnaryFn<number, mixed>,
  /**
   * Title for handicapping selector
   */
  handicapTitle?: string,
  /**
   * Handicap format to be displayed in row
   */
  handicapInfo: Array<string | HandicapOptions>,
  /**
   * Should handicap section be fixed
   */
  isHandicapFixed: boolean,
  /**
   * Position of fixing handicap section
   */
  fixedHandicapOffset: number,
  /**
   * has handicapping dropdown
   */
  hasHandicappingSelection: boolean,
  /**
   *  List of Handicap options to show
   */
  handicapOptions: HandicapSelectiontype[],
  /**
   *  Header reference
   */
  refHeader: HeaderRef,
  /**
   * Function to show bet all buttons
   */
  toggleBetAll: NullaryFn<mixed>,
  /**
   * Handicapping Sort Mode
   */
  handicappingSortMode: HandicapSortOptionsType,
  shouldRenderPromoOnboarding: boolean,
  /**
   * Function to render promo onboarding message
   */
  renderPromoOnboarding: NullaryFn<ReactElement<*>>
};

export default class RunnerListHeader extends PureComponent<Props> {
  static defaultProps = {
    leg: 0,
    isWagerable: true,
    raceTypeCode: "T",
    numColumns: 1,
    fixedHandicapOffset: 0,
    isHandicapFixed: false,
    isKey: false,
    isLeg: false,
    isAllSelected: [false],
    handicapInfo: [],
    onAllRunnersSelect: noop,
    hasHandicappingSelection: false,
    handicapOptions: [],
    handicapTitle: "",
    refHeader: { current: null },
    toggleBetAll: noop,
    handicappingSortMode: "saddlecloth",
    shouldRenderPromoOnboarding: false,
    renderPromoOnboarding: noop
  };

  el: Element;

  buildHandicapFormat = () => {
    /* istanbul ignore next */
    if (typeof window !== "undefined") {
      // $FlowFixMe
      this.el = document.querySelector("#app > section > div") || document.body;
    }

    return (
      !!this.props.handicapInfo.length &&
      (this.props.isHandicapFixed && this.el ? (
        createPortal(
          <FixedHandicapContainer
            isHandicapFixed
            fixedOffset={this.props.fixedHandicapOffset}
            isLeg={this.props.isLeg}
            name="handicapWrapper"
          >
            <InlineInfo
              info={this.props.handicapInfo}
              showHandicappingSortMode={this.props.raceTypeCode !== "G"}
              handicappingSortMode={this.props.handicappingSortMode}
            />
          </FixedHandicapContainer>,
          this.el
        )
      ) : (
        <RLHandicap name="handicapWrapper" leg={this.props.isLeg}>
          <HandicapWrapper colSpan={4}>
            <InlineContainer>
              <InlineInfo
                info={this.props.handicapInfo}
                showHandicappingSortMode={this.props.raceTypeCode !== "G"}
                handicappingSortMode={this.props.handicappingSortMode}
              />
            </InlineContainer>
          </HandicapWrapper>
        </RLHandicap>
      ))
    );
  };

  renderSelectAll = () => {
    const selectionType = (() => {
      if (!this.props.isKey) {
        return this.props.numColumns === 1 ? "default" : "number";
      }

      return "key";
    })();

    const buildQALabel = (column) => {
      if (this.props.isKey) {
        return column === 0
          ? "runnerListHeader-selectKeyButton"
          : "runnerListHeader-selectWithButton";
      }

      return this.props.numColumns === 1
        ? "runnerListHeader-selectAllButton"
        : `runnerListHeader-select-${column + 1}-button`;
    };

    return this.props.numColumns < 3 ? (
      times(this.props.numColumns, (column) => (
        <BetAllButton
          key={`button-${column}`}
          onClick={() =>
            this.props.onAllRunnersSelect(
              this.props.leg > 0 ? this.props.leg : column
            )
          }
          qaLabel={buildQALabel(column)}
          isBold={false}
        >
          {getSelectionText(
            selectionType,
            column,
            this.props.isAllSelected[column]
          )}
        </BetAllButton>
      ))
    ) : (
      <HandicappTypeWrapper
        onTouchStart={noop}
        qaLabel="runnerListHeader-BetAllSelector"
        onClick={this.props.toggleBetAll}
      >
        <BetAllOption>
          BET ALL <Icon icon={arrowDown} size={11} />
        </BetAllOption>
      </HandicappTypeWrapper>
    );
  };

  renderSelectOptions = () =>
    this.props.handicapOptions
      .filter((option) => option.visible)
      .map((option, index, options) => (
        <Fragment key={`select-option-${index.toString()}`}>
          <HandicappTypeWrapper
            onTouchStart={noop}
            qaLabel={option.qaLabel}
            onClick={option.callback}
            iconBefore
          >
            {!!option.icon && <Icon icon={option.icon} size={11} />}
            <OptionTitle>{option.title.toLowerCase()}</OptionTitle>
          </HandicappTypeWrapper>
          {index < options.length - 1 && <SeparatorLine />}
        </Fragment>
      ));

  render() {
    const promoOnboardingComponent = isFunction(
      this.props.renderPromoOnboarding
    )
      ? this.props.renderPromoOnboarding()
      : null;
    return (
      <thead data-qa-label="runnerListHeader" ref={this.props.refHeader}>
        <RLHeader leg={this.props.isLeg}>
          <RunnerInfoWrapper colSpan={this.props.isWagerable ? 3 : 4}>
            {this.props.hasHandicappingSelection ? (
              this.renderSelectOptions()
            ) : (
              <TitleBoldEllipsis>{this.props.handicapTitle}</TitleBoldEllipsis>
            )}
          </RunnerInfoWrapper>

          {this.props.isWagerable && (
            <BetAllWrapper
              isLeg={this.props.isLeg}
              numColumns={this.props.numColumns}
            >
              <div>{this.renderSelectAll()}</div>
            </BetAllWrapper>
          )}
        </RLHeader>
        {this.buildHandicapFormat()}
        {this.props.shouldRenderPromoOnboarding && !!promoOnboardingComponent && (
          <tr>
            <td colSpan={4}>{promoOnboardingComponent}</td>
          </tr>
        )}
      </thead>
    );
  }
}
