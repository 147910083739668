import React from "react";
import { RaceStatus, RaceStatusEnum, RaceVideo } from "@tvg/ts-types/Race";
import TvBrand from "../../../TvBrand";
import { Icon } from "../../../Icon";

export const renderIcon = (video: RaceVideo, status: RaceStatus) => {
  if (!video) return null;

  if (
    status.code !== RaceStatusEnum.RACE_OFFICIAL &&
    (video.onTvg || video.onTvg2) // TODO: Add condition for FDRTV when logo is available
  ) {
    return <TvBrand height={16} brandColor="grey" />;
  }

  if (video.hasReplay)
    return <Icon name="replay" size="s" lineColor="content.subtle" />;

  return <Icon name="noVideo" size="s" lineColor="content.subtle" />;
};
