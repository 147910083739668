// @flow

import styled from "styled-components";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

export const RaceButtonsContainer = styled.div`
  margin: 0;
  background: ${(props) =>
    props.isWarning
      ? buildColor("yellow", "100")
      : buildColor("blue_accent", "100")};
  padding: 0 8px 12px 32px;
  border-bottom: ${(props) =>
    props.hasBorderBottom && `1px solid ${buildColor("grey", "300")}`};

  & > :first-child {
    margin-right: 8px;
  }
`;

export const ArrowIcon = styled(Icon)`
  margin-left: 4px;
`;
