import React, { FC } from "react";

export const HongKong: FC = () => (
  <>
    <path d="M32 0H0V20H32V0Z" fill="#DE3131" />
    <path
      d="M15.9885 7.97414C15.9655 7.99569 15.9196 7.99569 15.8966 8.01724C15.552
      8.21121 15.3223 8.51293 15.2075 8.85776C15.1845 8.96552 15.1615 9.07328
      15.1615 9.20259C15.1615 9.63362 15.3223 9.93535 15.6669 10.1509C14.4494
      9.71983 13.6454 8.66379 13.6454 7.34914C13.6454 5.66811 15.0926 4.31035
      16.8844 4.31035C16.9763 4.31035 17.0682 4.31035 17.1601 4.31035C16.7925
      4.46121 16.5399 4.80604 16.5399 5.19397C16.5399 5.38793 16.5858 5.56035
      16.7006 5.71121C16.9074 5.96983 16.9533 6.25 16.9533 6.55173C16.9533
      7.17673 16.5398 7.71552 15.9885 7.97414Z"
      fill="#F8F8F9"
    />
    <path
      d="M15.6439 10.194C14.9777 9.89228 14.5642 9.15952 14.5413 8.29745C14.5413
      7.84487 14.6561 7.39228 14.9088 7.00435L14.9777 7.04745C14.725 7.41383
      14.6102 7.84487 14.6102 8.29745C14.6332 9.11642 15.0466 9.82762 15.6669
      10.1078L15.6439 10.194Z"
      fill="#DE3131"
    />
    <path
      d="M15.7588 6.09917L15.621 6.444L15.9196 6.68107L15.5291 6.63797L15.3913
      6.98279L15.2994 6.63797L14.9088 6.61641L15.2304 6.42245L15.1386
      6.07762L15.4372 6.29314L15.7588 6.09917Z"
      fill="#DE3131"
    />
    <path
      d="M13.5535 9.18103C13.5535 9.22413 13.5765 9.24568 13.5765
      9.28879C13.6683 9.65517 13.8981 9.95689 14.2197 10.1724C14.3116 10.2371
      14.4264 10.2802 14.5413 10.3233C14.9777 10.4526 15.3453 10.4095 15.6669
      10.1724C14.8399 11.1207 13.5305 11.5086 12.1981 11.1207C10.4982 10.6034
      9.55636 8.87931 10.1077 7.28448C10.1307 7.19827 10.1766 7.11206 10.1996
      7.04741C10.2226 7.41379 10.4982 7.75861 10.9117 7.88792C11.0955 7.95258
      11.3022 7.95258 11.463 7.90948C11.7847 7.80172 12.0833 7.84482 12.4049
      7.93103C13.0481 8.08189 13.4616 8.62068 13.5535 9.18103Z"
      fill="#F8F8F9"
    />
    <path
      d="M14.2427 10.7112C13.99 10.7112 13.7143 10.6681 13.4386 10.6034C12.9792
      10.4741 12.5657 10.2155 12.2441 9.87067L12.313 9.82757C12.6116 10.1724
      13.0022 10.4095 13.4616 10.5388C14.3116 10.7758 15.1386 10.625 15.621
      10.1508L15.6899 10.2155C15.3453 10.5172 14.817 10.7112 14.2427 10.7112Z"
      fill="#DE3131"
    />
    <path
      d="M11.5779 8.81461L11.8765 9.05168L12.1981 8.85772L12.0603
      9.20254L12.3589 9.41806L11.9684 9.39651L11.8306 9.74134L11.7387
      9.37496L11.3482 9.35341L11.6928 9.15944L11.5779 8.81461Z"
      fill="#DE3131"
    />
    <path
      d="M14.0359 11.7457C14.0819 11.7457 14.1048 11.7457 14.1508
      11.7672C14.5413 11.7888 14.9318 11.681 15.2534 11.4655C15.3453 11.4008
      15.4372 11.3146 15.5061 11.2284C15.7818 10.8836 15.8507 10.5388 15.6899
      10.1724C16.402 11.2069 16.402 12.5 15.575 13.556C14.5183 14.9138 12.4968
      15.2155 11.0266 14.2241C10.9577 14.181 10.8887 14.1164 10.8198
      14.0733C11.2103 14.1595 11.6238 14.0302 11.8765 13.7069C11.9914 13.556
      12.0603 13.3836 12.0603 13.2112C12.0603 12.8879 12.1752 12.6293 12.3819
      12.3922C12.7495 11.8534 13.4157 11.6379 14.0359 11.7457Z"
      fill="#F8F8F9"
    />
    <path
      d="M14.3115 13.1035L14.2886 13.0173C14.725 12.8448 15.0926 12.5647 15.3683
      12.2199C15.8737 11.5302 15.9885 10.7543 15.6439 10.1724L15.7129
      10.1293C16.0574 10.7328 15.9426 11.5517 15.4372 12.263C15.1385 12.6509
      14.748 12.9311 14.3115 13.1035Z"
      fill="#DE3131"
    />
    <path
      d="M13.0481 13.3836L13.3697 13.1896L13.2778 12.8448L13.5765
      13.0603L13.8981 12.8663L13.7603 13.2112L14.0589 13.4482L13.6684
      13.4051L13.5305 13.75L13.4386 13.4051L13.0481 13.3836Z"
      fill="#DE3131"
    />
    <path
      d="M16.7696 12.0905C16.7925 12.0689 16.7925 12.0258 16.8155
      12.0042C16.9763 11.6594 16.9763 11.293 16.8385 10.9267C16.7925 10.8189
      16.7466 10.7111 16.6777 10.6249C16.402 10.2801 16.0804 10.1077 15.6669
      10.1292C16.9304 9.80597 18.2398 10.2155 19.0668 11.2715C20.1235 12.6292
      19.8019 14.5474 18.3546 15.5387C18.2857 15.5818 18.1938 15.6465 18.1249
      15.6896C18.3317 15.3663 18.3317 14.9568 18.079 14.6336C17.9641 14.4827
      17.8033 14.3749 17.6425 14.3103C17.2979 14.2241 17.0912 14.0301 16.9074
      13.7715C16.4939 13.2758 16.4709 12.6077 16.7696 12.0905Z"
      fill="#F8F8F9"
    />
    <path
      d="M18.1479 12.2845C18.1249 11.8535 17.9412 11.4225 17.6655
      11.0776C17.1371 10.4095 16.3791 10.0862 15.6669 10.194L15.644
      10.1078C16.3791 10 17.1601 10.3449 17.7114 11.013C18.0101 11.3794
      18.1709 11.8104 18.2168 12.263L18.1479 12.2845Z"
      fill="#DE3131"
    />
    <path
      d="M18.1249 13.4914L18.033 13.125L17.6425 13.1035L17.9641 12.9095L17.8722
      12.5647L18.1709 12.8017L18.4925 12.6078L18.3546 12.931L18.6533
      13.1681L18.2627 13.1466L18.1249 13.4914Z"
      fill="#DE3131"
    />
    <path
      d="M17.9641 9.78453C17.9411 9.76298 17.9182 9.74143 17.8952
      9.71988C17.5966 9.48281 17.206 9.3535 16.8155 9.3535C16.7006 9.3535
      16.5858 9.37505 16.4709 9.41815C16.0345 9.54746 15.7588 9.78453 15.6669
      10.1725C15.7358 8.94402 16.5628 7.90953 17.8722 7.50005C19.5721 6.98281
      21.4099 7.86643 21.9612 9.46126C21.9842 9.54746 22.0072 9.63367 22.0302
      9.69833C21.7775 9.41815 21.341 9.28884 20.9275 9.41815C20.7437 9.48281
      20.5829 9.56901 20.4681 9.71988C20.2613 10 20.0086 10.1078 19.687
      10.2156C19.0668 10.388 18.4006 10.194 17.9641 9.78453Z"
      fill="#F8F8F9"
    />
    <path
      d="M15.7129 10.1509L15.621 10.1294C15.7358 9.43971 16.3331 8.83626 17.1831
      8.55609C17.6425 8.40523 18.1249 8.38368 18.6073 8.49144L18.5844
      8.57765C18.1249 8.46989 17.6655 8.49144 17.206 8.6423C16.3791 8.92247
      15.8048 9.50437 15.7129 10.1509Z"
      fill="#DE3131"
    />
    <path
      d="M19.8019 8.98711L19.4113 8.96556L19.2735 9.31038L19.1816
      8.94401L18.7911 8.92245L19.1127 8.72849L19.0208 8.38366L19.3194
      8.62073L19.6411 8.42676L19.5032 8.75004L19.8019 8.98711Z"
      fill="#DE3131"
    />
  </>
);
