import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";

export const isFdr = () => tvgConf().brand === "fdr";

export const delay = (helper: { cancel: () => void }, ms: number) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => resolve("run"), ms);

    helper.cancel = () => {
      clearTimeout(timer);
      reject("cancelled");
    };
  });

export const isHongKongRaces = (code: string) =>
  code.includes("HKS") || code.includes("HKH");

export const openLoginWall = (
  loginSuccessCallback: Function,
  loginFailCallback?: Function
) => {
  if (isFdrProduct()) {
    mediator.base.dispatch({
      type: "OPEN_LOGIN",
      payload: {
        callback: loginSuccessCallback
      }
    });
  } else {
    mediator.base.dispatch({
      type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
      payload: {
        callback: (data?: { status: string }) => {
          if (
            !data ||
            data.status !== "error" ||
            typeof loginSuccessCallback === "function"
          ) {
            loginSuccessCallback();
          } else if (typeof loginFailCallback === "function") {
            loginFailCallback();
          }
        }
      }
    });
  }
};

export const buildQaLabel = (values: string[], separator: string = "-") =>
  values.join(separator);
