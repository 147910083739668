import React, { FC } from "react";

export const TrinidadAndTobago: FC = () => (
  <>
    <g clipPath="url(#clip0_18753_2501)">
      <path d="M32.3333 0H-1V20H32.3333V0Z" fill="#DE3131" />
      <path d="M-1 0L22.1386 20H32.3333L9.19474 0H-1Z" fill="white" />
      <path
        d="M0.699097 0L23.8377 20H30.6342L7.49559 0H0.699097Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_18753_2501">
        <rect width="32" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </>
);
