import styled from "styled-components";

export const WarningContainer = styled.div`
  display: inline-flex;
  position: relative;
  bottom: 90px;
  box-shadow: ${({ theme }) => theme.elevations.medium};
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};
  padding: ${({ theme }) => theme.space["space-3"]};
  border-radius: ${({ theme }) => theme.radii.s};

  & p {
    margin-left: ${({ theme }) => theme.space["space-1"]};
    color: ${({ theme }) => theme.colorTokens.content.warning};
  }
`;
