import React, { FC, memo } from "react";
import { IconSizeVariants } from "../../theme";
import { useColorTokens, useTheme } from "../../hooks";
import * as Icons from "./components/IconPaths";
import { Svg } from "./styled-components";

import { IconNames, IconProps } from "./types";

export const Icon: FC<IconProps> = memo(
  ({
    name,
    lineColor = "content.link",
    size,
    backgroundColor = "transparent",
    qaLabel,
    ...rest
  }) => {
    const { iconSizes } = useTheme();
    const { iconSize, strokeWidth } = iconSizes[size as IconSizeVariants];
    const IconContent = Icons[name as IconNames];

    // TODO: delete when color tokens for every use case are implemented
    // Checking if color has #, so we use the color instead of token
    const filterColor = (color: string | undefined) =>
      color && color.includes("#") ? color : useColorTokens(color);

    return (
      <Svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-qa-label={qaLabel || name}
        {...rest}
      >
        <IconContent
          lineColor={filterColor(lineColor)}
          backgroundColor={
            backgroundColor === "transparent"
              ? "transparent"
              : filterColor(backgroundColor)
          }
          strokeWidth={strokeWidth}
        />
      </Svg>
    );
  }
);
