// @flow
import React, { Component } from "react";
import { isEqual, noop, get } from "lodash";
import { connect } from "react-redux";
import { graphql } from "@apollo/client/react/hoc";
import mediator from "@tvg/mediator";
import type {
  PastPerformanceRace,
  RaceCardRunner,
  PastRace,
  RaceProgram,
  FullResultRunner,
  RaceCardBettingInterest,
  RaceTypeCodeEnum,
  MTPNotificationConfigType
} from "@tvg/types/Race";
import type { WroWagerGroup } from "@tvg/api/wro/types";
import type { WagerProfile } from "@tvg/types/User";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import {
  findRunnerInRace,
  convertBettingInterests,
  getStreamSrc
} from "@tvg/utils/pastPerformanceUtils";
import parseCapiMessage from "@tvg/utils/capiUtils";
import type { HandicappingRunnerFlags } from "@tvg/ipp/src/components/RunnerFlags";
import type { RaceBet } from "@tvg/atomic-ui/_organism/RaceBets";
import HorsePastPerformances from "@tvg/atomic-ui/_templates/HorsePastPerformances";
import type { TabKey } from "@tvg/atomic-ui/_templates/RaceOfficials";
import { mtpNotificationConfigDefault } from "@tvg/atomic-ui/__mocks__/ipp-pastRaces.mock";

import {
  buildResults,
  RESULTS_TEMPLATE
} from "@tvg/sh-lib-my-bets/utils/bettingInterests";
import { getAccountNumber } from "@urp/store-selectors";
import HorsePerformanceQuery from "../graphql/queries/horsePastPerformance.graphql";
import { ApolloOptionsPP } from "../graphql/options.graph";

type Props = {
  device: Device,
  runnerName: string,
  trackCode: string,
  raceNumber: string,
  raceDate: string,
  wagerProfile: WagerProfile,
  selectedRace: PastPerformanceRace,
  selectedRaceIndex: number,
  races: PastPerformanceRace[],
  wagerGroup: ?WroWagerGroup,
  myBetsResultedBetTicket: boolean,
  pastRace: ?PastRace,
  fullResults: FullResultRunner[],
  hasTimeformData: boolean,
  hasNextRace: boolean,
  hasPreviousRace: boolean,
  onPreviousRaceNavigation: NullaryFn<void>,
  onNextRaceNavigation: NullaryFn<void>,
  onRaceFromList: UnaryFn<number, void>,
  hasNavigation: boolean,
  videoFullscreenTilt: boolean,
  isRaceListView: boolean,
  onClickGTMEvent: UnaryFn<RaceCardRunner, void>,
  isLoading: boolean,
  updateRunner: UnaryFn<RaceCardRunner, void>,
  accountNumber: string,
  raceBets: ?(RaceBet[]),
  onMatchConditions: NullaryFn<void>,
  isMatchConditionsOn: boolean,
  originRace: string,
  raceTypeCode: RaceTypeCodeEnum,
  activeRunner: string,
  activeRunnerBI: ?RaceCardBettingInterest,
  isLoadingRunner: boolean,
  enableRaceResultsReDesign: boolean,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  racePayoffsShift: boolean,
  mtpNotificationConfig: MTPNotificationConfigType,
  handicappingMTPNotification: boolean,
  currentRace: RaceProgram,
  onModalClose: NullaryFn<void>,
  isVideoIframe: boolean,
  disableFullscreen: boolean,
  isFlash?: boolean,
  isTvg5?: boolean,
  isAutoPlay?: boolean
};

type State = {
  replaySrc: ?string
};

export class PastPerformanceComponent extends Component<Props, State> {
  static defaultProps = {
    dispatch: noop,
    device: "mobile",
    races: [],
    wagerGroup: null,
    myBetsResultedBetTicket: false,
    fullResults: [],
    runnerName: "",
    wagerProfile: "PORT-Generic",
    selectedRaceIndex: -1,
    pastRace: null,
    hasTimeformData: false,
    hasPreviousRace: false,
    hasNextRace: false,
    onPreviousRaceNavigation: noop,
    onNextRaceNavigation: noop,
    onRaceFromList: noop,
    hasNavigation: false,
    videoFullscreenTilt: false,
    isRaceListView: false,
    onClickGTMEvent: noop,
    isLoading: true,
    updateRunner: noop,
    accountNumber: "",
    raceBets: null,
    onMatchConditions: noop,
    isMatchConditionsOn: false,
    originRace: "",
    raceTypeCode: "T",
    activeRunner: "",
    activeRunnerBI: null,
    isLoadingRunner: false,
    enableRaceResultsReDesign: false,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    racePayoffsShift: false,
    mtpNotificationConfig: mtpNotificationConfigDefault,
    handicappingMTPNotification: false,
    currentRace: {},
    onModalClose: noop,
    isVideoIframe: false,
    disableFullscreen: false,
    isFlash: false,
    isTvg5: false,
    isAutoPlay: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      replaySrc: null
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const {
      runnerName,
      selectedRace,
      selectedRaceIndex,
      pastRace,
      hasPreviousRace,
      hasNextRace,
      isLoading,
      isMatchConditionsOn,
      raceBets,
      activeRunner,
      isLoadingRunner
    } = this.props;

    return (
      isMatchConditionsOn !== nextProps.isMatchConditionsOn ||
      runnerName.toLowerCase() !== nextProps.runnerName.toLowerCase() ||
      hasPreviousRace !== nextProps.hasPreviousRace ||
      activeRunner !== nextProps.activeRunner ||
      isLoadingRunner !== nextProps.isLoadingRunner ||
      hasNextRace !== nextProps.hasNextRace ||
      isLoading !== nextProps.isLoading ||
      selectedRaceIndex !== nextProps.selectedRaceIndex ||
      (nextState && nextState.replaySrc !== this.state.replaySrc) ||
      !isEqual(nextProps.raceBets, raceBets) ||
      !isEqual(selectedRace, nextProps.selectedRace) ||
      !isEqual(pastRace, nextProps.pastRace)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const runner = this.getRunner();

    if (runner) {
      this.props.updateRunner(runner);
    }

    if (this.props.pastRace && !prevProps.pastRace) {
      // CLICKED ON A PP
      if (runner) {
        this.props.onClickGTMEvent(runner);
      }
    }

    if (
      this.props.selectedRace &&
      this.props.selectedRace.hasReplays &&
      (!this.state.replaySrc ||
        !isEqual(this.props.selectedRace, prevProps.selectedRace))
    ) {
      const replayFileName = get(this.props.selectedRace, "replayFileName");
      getStreamSrc(replayFileName).then((data) => {
        if (data.payload && data.type === "success") {
          const replaySrc = get(data, "payload");
          const finalReplaySrc =
            this.props.isVideoIframe &&
            replaySrc &&
            typeof replaySrc === "string"
              ? replaySrc
                  .replace("replaysmobile.m3u8", "replaysflash.php")
                  .replace("race=", "stream=")
                  .concat(
                    this.props.isTvg5
                      ? "&width=100%&height=100%"
                      : "&width=548&height=411"
                  )
              : replaySrc;
          this.setState({
            replaySrc: finalReplaySrc
          });
        }
      });
    }
  }

  getRunner = (): ?RaceCardRunner => {
    const { runnerName, pastRace } = this.props;
    return pastRace
      ? findRunnerInRace(runnerName, pastRace?.bettingInterests || [])
      : null;
  };

  changeRaceOfficialsTab = (tab: TabKey) => {
    const actionType = {
      result: "PAST_PERFORMANCES:VIEW_RESULTS",
      racecard: "PAST_PERFORMANCES:VIEW_RACECARD",
      bets: "PAST_PERFORMANCES:VIEW_BETS",
      fullresult: "",
      none: ""
    };

    if (actionType[tab]) {
      mediator.base.dispatch({
        type: actionType[tab],
        payload: {
          trackName: get(this.props.selectedRace, "track.name", ""),
          runnerName: get(this.props, "runnerName", ""),
          raceNumber: get(this.props.selectedRace, "number", "")
        }
      });
    }

    return true;
  };

  sendVideoGtmEvent = () => {
    mediator.base.dispatch({
      type: "PAST_PERFORMANCES:VIEW_REPLAY",
      payload: {
        trackName: get(this.props, "selectedRace.track.name"),
        runnerName: this.props.runnerName,
        raceNumber: get(this.props, "selectedRace.number")
      }
    });
  };

  deviceLockRotationToggle = (lock: boolean = true) => {
    if (this.props.videoFullscreenTilt) {
      mediator.ios.dispatch({
        type: "IPHONE_LOCK_SCREEN_ROTATION",
        payload: { lockScreenRotation: lock ? 0 : 2 }
      });
    }
  };

  seeAllHorsesGTM = () => {
    mediator.base.dispatch({
      type: "PAST_PERFORMANCES:SEE_ALL_RUNNERS"
    });
  };

  render() {
    const biData = convertBettingInterests(
      get(this.props.pastRace, "bettingInterests") || []
    );

    return (
      <HorsePastPerformances
        results={buildResults(
          get(this.props.pastRace, "results", {}),
          RESULTS_TEMPLATE.emptyWinningTime
        )}
        race={this.props.selectedRace}
        runner={this.getRunner()}
        hasPreviousRace={this.props.hasNavigation && this.props.hasPreviousRace}
        hasNextRace={this.props.hasNavigation && this.props.hasNextRace}
        onPreviousRaceNavigation={this.props.onPreviousRaceNavigation}
        onNextRaceNavigation={this.props.onNextRaceNavigation}
        isLoading={this.props.isLoading}
        onTabClick={this.changeRaceOfficialsTab}
        withBorders={this.props.device !== "mobile"}
        bettingInterests={biData.bettingInterests}
        scratchedRunners={biData.scratchedRunners}
        raceBets={this.props.raceBets || []}
        wagerGroup={this.props.wagerGroup}
        myBetsResultedBetTicket={this.props.myBetsResultedBetTicket}
        replaySrc={this.state.replaySrc}
        sendVideoGtmEvent={this.sendVideoGtmEvent}
        fullResults={this.props.fullResults}
        hasTimeformData={this.props.hasTimeformData}
        videoFullscreenTilt={this.props.videoFullscreenTilt}
        deviceLockRotationToggle={this.deviceLockRotationToggle}
        device={this.props.device}
        enableRaceResultsReDesign={this.props.enableRaceResultsReDesign}
        seeAllHorsesGTM={this.seeAllHorsesGTM}
        handicappingRunnerFlags={this.props.handicappingRunnerFlags}
        racePayoffsShift={this.props.racePayoffsShift}
        currentRace={this.props.currentRace}
        mtpNotificationConfig={this.props.mtpNotificationConfig}
        handicappingMTPNotification={
          this.props.device === "mobile" &&
          this.props.handicappingMTPNotification
        }
        onModalClose={this.props.onModalClose}
        isVideoIframe={this.props.isVideoIframe}
        disableFullscreen={this.props.disableFullscreen}
        isAutoPlay={this.props.isAutoPlay}
        isTvg5={this.props.isTvg5}
      />
    );
  }
}

export default connect(
  /* istanbul ignore next */
  (store) => ({
    accountNumber: getAccountNumber(store),
    hasNavigation: get(
      store,
      "capi.featureToggles.inLinePastPerformanceNavigation",
      false
    ),
    videoFullscreenTilt: get(
      store,
      "capi.featureToggles.videoFullscreenTilt",
      false
    ),
    enableRaceResultsReDesign: get(
      store,
      "capi.featureToggles.enableRaceResultsReDesign",
      false
    ),
    racePayoffsShift: get(store, "capi.featureToggles.RacePayoffsShift", false),
    mtpNotificationConfig: parseCapiMessage(
      store,
      "capi.messages.mtpNotification"
    ),
    handicappingMTPNotification: get(
      store,
      "capi.featureToggles.handicappingMTPNotification",
      false
    ),
    myBetsResultedBetTicket: get(
      store,
      "capi.featureToggles.myBetsResultedBetTicket",
      false
    )
  }),
  /* istanbul ignore next */
  (dispatch: Dispatch<*>) => ({
    dispatch
  })
)(graphql(HorsePerformanceQuery, ApolloOptionsPP)(PastPerformanceComponent));
