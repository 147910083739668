import React, { FC } from "react";

export const Jamaica: FC = () => (
  <>
    <path
      d="M36.6176 0.0187509H-3.61765V19.9813H36.6176V0.0187509Z"
      fill="#007749"
    />
    <path
      d="M43.3235 -3.30833V23.3083L-10.3235 -3.30833V23.3083L43.3235 -3.30833Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45 -6L16.5 8.14011L-12 -6V26L16.5 11.8599L45 26V-6ZM20.2487 10L41.6471
      -0.616665V20.6167L20.2487 10ZM12.7513 10L-8.64706
      20.6167V-0.616665L12.7513 10Z"
      fill="#FFB81C"
    />
  </>
);
