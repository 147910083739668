import React, { FC } from "react";

export const Norway: FC = () => (
  <>
    <path
      d="M32 0V7.78378H15.504V0H32ZM0 12.2378V20H10.1284V12.2378H0ZM10.1284
      0H0V7.78378H10.1284V0ZM15.504 19.9784H32V12.2162H15.504V19.9784Z"
      fill="#DE3131"
    />
    <path
      d="M14.0044
      8.90811V0H11.628V8.90811H0V11.1351H11.628V19.9784H14.0044V11.1351H32V8.90811H14.0044Z"
      fill="#1C3D70"
    />
    <path
      d="M11.628
      11.1351V19.9784H10.1284V12.2162H0V11.1135H11.628V11.1351ZM14.0044
      19.9784H15.504V12.2162H32V11.1135H14.0044V19.9784ZM15.504
      0H14.0044V8.90811H32V7.78378H15.504V0ZM11.628
      0H10.1284V7.78378H0V8.90811H11.628V0Z"
      fill="#F8F8F9"
    />
  </>
);
