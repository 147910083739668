import React, { ReactNode, InputHTMLAttributes } from "react";
import { DayModifiers, Modifier } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import type { Theme } from "../../../src/theming";
import { FontSizes, Colors, Fonts } from "../../../src/theming/types";
import type { SpaceProps } from "../../../src/theming/styledSystem";
import {
  MonthSelectorProps,
  MonthNavbarProps
} from "./InputCalendar/components/types";

export interface InputFieldProps
  extends InputHTMLAttributes<HTMLInputElement>,
    SpaceProps {
  /** Input type */
  type?: "text" | "password";
  /** Invalid input */
  isInvalid?: boolean;
  /** Disable input field */
  isDisabled?: boolean;
  /** Focused input */
  isFocused?: boolean;
  /** Input start node element */
  start?: JSX.Element;
  /** Input end node element */
  end?: JSX.Element;
  /** Input max length */
  maxLength?: number;
  /** Test label */
  qaLabel: string;
}

export interface InputLabelProps extends SpaceProps {
  /** Input label */
  label?: string;
  /**  Field optional */
  isOptional?: boolean;
  /** Shows an icon to indicate that the field was successfully validated */
  isChecked?: boolean;
  /** Test label */
  qaLabel?: string;
}

export interface InputMessageProps extends SpaceProps {
  /** Input context message */
  message: string;
  /** Warning message */
  isWarning?: boolean;
  /** Test label */
  qaLabel?: string;
}

export interface InputTextProps
  extends InputHTMLAttributes<HTMLInputElement>,
    SpaceProps {
  /** Input label */
  label?: string;
  /** Context message above
   * @default Message Text
   */
  messageAbove?: string;
  /** Context message bellow (default is "") */
  messageBellow?: string;
  /** Warning message (shown if invalid) */
  warningMessage?: string;
  /**  Field optional
   * @default false
   */
  isOptional?: boolean;
  /** Shows an icon to indicate that the field was successfully validated
   * @default false
   */
  isChecked?: boolean;
  /** Invalid input
   * @default false
   */
  isInvalid?: boolean;
  /** Disable input field
   * @default false
   */
  isDisabled?: boolean;
  /** Input max length
   * @default false
   */
  maxLength?: number;
  /** Input start node element */
  start?: JSX.Element;
  /** Input end node element */
  end?: JSX.Element;
  /** Test label */
  qaLabel: string;
  /** Short hint */
  placeholder?: string;
  /** Callback that returns inserted input  */
  onChangeText?: (val: string) => void;
}

export interface InputNumberProps extends InputTextProps {
  /** Number with decimal places */
  hasDecimalPlaces?: boolean;
}

export interface InputSelectProps extends InputTextProps {
  /** Set up object for select list */
  selectList: SelectListObject[] | CheckboxSelectListObject[];
  /** Set up selected item */
  selectedValue: string | number;
  /** Input Box label */
  inputBoxLabel?: string;
  /** Displays a Select element ready to receive checkboxes as options */
  hasCheckboxes?: string;
  /** Callback that returns that selected option  */
  onChangeSelection?: (val: string) => void;
}

export interface InputPasswordProps
  extends InputHTMLAttributes<HTMLInputElement>,
    SpaceProps {
  /** Input label */
  label?: string;
  /** Context message above */
  messageAbove?: string;
  /** Context message bellow */
  messageBellow?: string;
  /** Warning message (shown if invalid) */
  warningMessage?: string;
  /**  Field optional
   * @default false
   */
  isOptional?: boolean;
  /** Shows an icon to indicate that the field was successfully validated */
  isChecked?: boolean;
  /** Invalid input */
  isInvalid?: boolean;
  /** Disable input field
   * @default false
   */
  isDisabled?: boolean;
  /** Controls the visibility of the password icon */
  hasPasswordIcon?: boolean;
  /** Test label */
  qaLabel: string;
  /** Recovery link */
  recoveryLink?: {
    text: string;
    url: string;
  };
}

export interface InputBoxProps {
  theme: Theme;
  isInvalid: boolean;
  isDisabled: boolean;
  isHovered: boolean;
  isFocused: boolean;
  isPressed: boolean;
  isSelect?: boolean;
}

export interface StyledInputProps {
  theme: Theme;
  isDisabled: boolean;
  isNested: boolean;
  isInvalid: boolean;
  isFocused: boolean;
}

export interface SelectListObject {
  value: string | number;
  label: string;
}

export interface CheckboxSelectListObject {
  value: string;
  label: string;
}

export interface SelectProps {
  ref: React.Ref<HTMLInputElement>;
  colors: Colors;
  fonts: Fonts;
  fontsizes: FontSizes;
  placeholder?: string;
  disabled: boolean;
  onChange: Function;
  onFocus: Function;
  onMouseOut: Function;
  value?: string;
  children: ReactNode;
}

export interface OptionsProps {
  value: string | number;
  label: string;
  disabled: boolean;
  children: string;
  fonts: Fonts;
}

export interface CheckboxSelectProps
  extends Pick<
    InputSelectProps,
    | "label"
    | "messageAbove"
    | "messageBellow"
    | "isInvalid"
    | "isDisabled"
    | "warningMessage"
    | "qaLabel"
  > {
  selectList: CheckboxSelectListObject[];
  onSelectCheckbox: (val: string[]) => void;
  selectedValues?: string[];
}

export enum InputCalendarMode {
  SINGLE = "single",
  RANGE = "range"
}

export type OnDayChange = (
  day: Date,
  DayModifiers: DayModifiers,
  dayPickerInput: DayPickerInput
) => void;

export interface InputCalendarProps extends InputTextProps {
  mode?: "single" | "range";
  onDayChange?: OnDayChange;
  onDayFromChange?: OnDayChange;
  onDayToChange?: OnDayChange;
  disabledDays?: Modifier | Modifier[];
  captionElement?: ({ date, localeUtils }: MonthSelectorProps) => JSX.Element;
  navbarElement?: ({
    month,
    previousMonth,
    nextMonth,
    onPreviousClick,
    onNextClick
  }: MonthNavbarProps) => JSX.Element;
  dayPickerProps?: Object;
}
