import React, { ReactNode, useEffect, useState } from "react";
import { get, uniqWith } from "lodash";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { RunnerFlags as RunnerFlagsType } from "@tvg/ts-types/Race";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import {
  useColorTokens,
  Icon,
  Modal,
  Tag,
  ModalType,
  TagProps,
  Saddle
} from "@tvg/design-system";
import TagList from "../TagList";

import {
  RunnerFlagsWrapper,
  ContentWrapper,
  IconWrapper,
  RunnerNumberContainer
} from "./styled-components";

type FlagConfig = {
  id: string;
  name: string;
  description: string;
  isCondensed: boolean;
};

export type HandicappingRunnerFlags = {
  title: string;
  subtitle: string;
  subtitleRunnerFlags: string;
  flags: Array<FlagConfig>;
};

type Props = {
  runnerId: string;
  runnerName: string;
  flags: RunnerFlagsType;
  maxFlags?: number;
  handicappingRunnerFlags: HandicappingRunnerFlags;
  helpShown: boolean;
};

const mapToTag = (flag: FlagConfig): TagProps => ({
  label: flag.name,
  description: flag.description,
  icon: undefined,
  qaLabel: `flag-${flag.id}`,
  variant: "warning",
  size: "l"
});

const renderContentWrapper = (
  title: string | ReactNode,
  flags: Array<FlagConfig>,
  hasSeparator = false
) => (
  <ContentWrapper hasSeparator={hasSeparator}>
    <TagList title={title} tags={flags.map((flag) => mapToTag(flag))} />
  </ContentWrapper>
);

const getRunnerTitle = (
  titleTemplate: string,
  runnerId: string,
  runnerName: string
) => (
  <>
    {replaceCAPIVariables(titleTemplate, {
      runnerName: ""
    })}
    <RunnerNumberContainer>
      <Saddle
        size="s"
        number={runnerId}
        qaLabel={`saddle-container-${runnerId}`}
      />
    </RunnerNumberContainer>
    {runnerName}
  </>
);

const renderFlagsHelpContent = (
  runnerId: string,
  runnerName: string,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  runnerFlags: string[] = []
) => {
  const flagsUniq = uniqWith(
    handicappingRunnerFlags.flags,
    (flagCfgA, flagCfgB) => flagCfgA.name === flagCfgB.name
  );

  // runner flags config
  const RunnerFlagsTitle = getRunnerTitle(
    handicappingRunnerFlags.subtitleRunnerFlags,
    runnerId,
    runnerName
  );
  const runnerFlagsConfig =
    runnerFlags && runnerFlags.length > 0
      ? flagsUniq.filter((handicappingFlag) =>
          runnerFlags.includes(handicappingFlag.id)
        )
      : [];
  // other flags config
  const hasSeparator = true;
  const otherFlagsTitle = handicappingRunnerFlags.subtitle;
  const otherFlagsConfig = flagsUniq.filter(
    (handicappingFlag) => !runnerFlags.includes(handicappingFlag.id)
  );

  return (
    <>
      {runnerFlagsConfig.length > 0 &&
        renderContentWrapper(RunnerFlagsTitle, runnerFlagsConfig)}
      {renderContentWrapper(otherFlagsTitle, otherFlagsConfig, hasSeparator)}
    </>
  );
};

const renderFlagsHelpModal = (
  runnerId: string,
  runnerName: string,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  flagsList: string[],
  isModalOpen: boolean,
  setIsModalOpen: UnaryFn<boolean, void>
) => {
  const modalProps = {
    isOpen: isModalOpen,
    title: handicappingRunnerFlags.title,
    type: "flexible" as ModalType,
    onClose: () => {
      setIsModalOpen(false);
    },
    onBack: () => {
      setIsModalOpen(false);
    },
    padding: "0px"
  };

  return (
    <Modal {...modalProps}>
      {renderFlagsHelpContent(
        runnerId,
        runnerName,
        handicappingRunnerFlags,
        flagsList
      )}
    </Modal>
  );
};

const RunnerFlags = (props: Props) => {
  const {
    runnerId,
    runnerName,
    flags,
    maxFlags,
    handicappingRunnerFlags,
    helpShown
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flagsList, setFlagsList] = useState<string[]>([]);
  const colorTokens = useColorTokens();

  useEffect(() => {
    setFlagsList(
      Object.keys(flags).filter(
        (flag) => flags[flag as keyof RunnerFlagsType] && flag !== "__typename"
      )
    );
  }, [runnerName, flags]);

  const getFlagConfig = (flag: string) =>
    get(handicappingRunnerFlags, "flags", []).find(
      (flagConfig) => flagConfig.id === flag
    ) || { id: flag, name: flag, description: flag, isCondensed: true };

  return flagsList.length > 0 ? (
    <>
      <RunnerFlagsWrapper>
        {flagsList.slice(0, maxFlags || flagsList.length).map((flag) => (
          <Tag key={flag} {...mapToTag(getFlagConfig(flag))} />
        ))}
        {helpShown && !!handicappingRunnerFlags && (
          <IconWrapper onClick={() => setIsModalOpen(true)}>
            <Icon
              name="support"
              lineColor={colorTokens.content.link}
              size="m"
              backgroundColor={colorTokens.background.infoSubtle}
            />
          </IconWrapper>
        )}
      </RunnerFlagsWrapper>

      {helpShown &&
        !!handicappingRunnerFlags &&
        renderFlagsHelpModal(
          runnerId,
          runnerName,
          handicappingRunnerFlags,
          flagsList,
          isModalOpen,
          setIsModalOpen
        )}
    </>
  ) : null;
};

RunnerFlags.defaultProps = {
  runnerId: "",
  runnerName: "",
  flags: {
    horseInFocus: false,
    warningHorse: false,
    jockeyUplift: false,
    trainerUplift: false,
    horsesForCoursePos: false,
    horsesForCourseNeg: false,
    hotTrainer: false,
    coldTrainer: false,
    highestLastSpeedRating: false,
    sectionalFlag: false,
    significantImprover: false,
    jockeyInForm: false,
    clearTopRated: false,
    interestingJockeyBooking: false,
    firstTimeBlinkers: false
  },
  maxFlags: null,
  handicappingRunnerFlags: {
    title: "",
    subtitle: "",
    subtitleRunnerFlags: "",
    flags: []
  },
  helpShown: false
};

export default RunnerFlags;
