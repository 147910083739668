import { get } from "lodash";
import { batch } from "react-redux";
import { format } from "date-fns";
import {
  setActiveWagerAmountMyBetsStandalone,
  setCounterMyBetsStandalone,
  setFutureCounterMyBetsStandalone,
  setFutureWageredAmountMyBetsStandalone,
  Actions
} from "@tvg/sh-lib-my-bets/redux/actions";
import { ApolloError } from "@apollo/client/errors";
import type { Dispatch } from "redux";
import { FooterProps, WagerHistoryProps, WagerHistory } from "../utils/types";

export const getRacesActiveBets = (wagerHistory: WagerHistory) => {
  if (wagerHistory && wagerHistory.groupWagers) {
    const activeBetsArray: number[] = [];
    wagerHistory.groupWagers.forEach((eachGroup) => {
      eachGroup.wagers.forEach((wager) => {
        if (wager.betStatus.code === "A") {
          activeBetsArray.push(wager.tvgRaceId);
        }
      });
    });

    const racesActiveBets = activeBetsArray.reduce<Record<number, number>>(
      (acc, currentValue) => {
        const value = get(acc, currentValue, 0);
        if (value > 0) {
          return {
            ...acc,
            [currentValue]: value + 1
          };
        }

        return {
          ...acc,
          [currentValue]: 1
        };
      },
      {}
    );

    return racesActiveBets;
  }
  return {};
};

export const formatBets = (
  wagerHistory: WagerHistoryProps,
  dispatch: Dispatch<Actions>,
  error: ApolloError | undefined
) => {
  if (!error && get(wagerHistory, "totals.totalGambledCount") !== undefined) {
    const totalActiveBets = get(wagerHistory, "totals.totalGambledCount", 0);
    const activeWageredAmount = get(
      wagerHistory,
      "totals.totalGambledAmount",
      50
    );
    const totalFutureBets = get(
      wagerHistory,
      "futureWagersList.totals.totalCount",
      0
    );
    const futureWageredAmount = get(
      wagerHistory,
      "futureWagersList.totals.totalAmount",
      0
    );

    batch(() => {
      // Entry point for our counters and saving them in redux store
      dispatch(setCounterMyBetsStandalone(totalActiveBets));
      dispatch(setActiveWagerAmountMyBetsStandalone(activeWageredAmount));
      dispatch(setFutureCounterMyBetsStandalone(totalFutureBets));
      dispatch(setFutureWageredAmountMyBetsStandalone(futureWageredAmount));
    });

    return {
      totalActiveBets,
      totalFutureBets,
      activeWageredAmount,
      futureWageredAmount
    };
  }
  return {};
};

export default {
  skip: (props: FooterProps) => !props.accountNumber,
  options: (props: FooterProps) => {
    const date = format(new Date(), "yyyy-MM-dd");
    const variables = {
      startDate: date,
      endDate: date,
      accountId: parseInt(props.accountNumber, 10) || 0,
      status: ["PENDING"]
    };
    return {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      client: props.behgClient,
      ssr: false,
      variables
    };
  },
  // @ts-ignore
  props: (result: graphql<Response>) => ({
    ...formatBets(
      get(result, "data.wagerHistory"),
      get(result, "ownProps.dispatch"),
      get(result, "data.error")
    ),
    isLoadingActiveTotals: get(result, "data.loading", false),
    subscribeToMore: result.data.loading ? null : result.data.subscribeToMore
  })
};
