// @flow

import React, { Component, Fragment, type Element } from "react";
import { bindAll, isEqual, get, noop, first } from "lodash";

import type { Device } from "@tvg/types/Device";
import type {
  Results,
  RaceCardBettingInterest,
  RaceTypeCodeEnum,
  HandicapOptionsType,
  FullResultRunner,
  ExpandLineArgs,
  RaceInfoMyBets,
  RaceProgram
} from "@tvg/types/Race";
import type { WroWagerGroup } from "@tvg/api/wro/types";
import type { NullaryFn, UnaryFn, QuadFn } from "@tvg/types/Functional";
import handicappingOptions, {
  handicappingGreyhoundOptions,
  handicappingHarnessOptions
} from "@tvg/utils/handicapping-conf/handicappingOptions";

import { checkTimeformData } from "@tvg/sh-lib-my-bets/utils/bettingInterests";
import MyBetsBody from "@tvg/my-bets-standalone/src/MyBetsBody";
import TabGroup from "@tvg/ipp/src/components/TabGroup";
import type { HandicappingRunnerFlags } from "@tvg/ipp/src/components/RunnerFlags";
import RaceRunnersPayoffsReDesign from "../../_organism/RaceRunners";
import RaceRunnersPayoffs from "../../_organism/RaceRunnersPayoffs";
import RacePayoffs from "../../_organism/RacePayoffs";
import {
  RaceCardTableWrapper,
  RaceCardTable
} from "../RaceCard/styled-components";
import RunnerListHeader from "../../_molecule/RunnerListHeader";
import RunnerList from "../../_organism/RunnerList";
import RaceBets, { type RaceBet } from "../../_organism/RaceBets";
import { ResultsMask } from "../../_static/Masks";
import {
  ResultsContainer,
  RaceBetsContainer,
  Alerts,
  NoBetsContainer,
  TextHighlighted,
  ROWrapper,
  Message
} from "./styled-components";
import RaceMessageRedirect from "../../_organism/RaceMessageRedirect";
import RaceResults from "../../_organism/RaceResults";
import { handicapping } from "../../_static/Icons/icons";

export type TabKey = "result" | "fullresult" | "bets" | "racecard" | "none";

type State = {
  selectedtab: TabKey
};

type Props = {
  /**
   * Results tab to be shown
   */
  resultsType: "official" | "full",
  /**
   * Array of the Race Betting Interests
   */
  bettingInterests: Array<RaceCardBettingInterest>,
  /**
   * Object with the results and payoffs for the race
   */
  results: ?Results,
  /**
   * Object with the full results for the race
   */
  fullResults: FullResultRunner[],
  /**
   Array with the list of scratched runners for the full/basic result layout
   */
  scratchedRunners: string[],
  /**
   * runnerName for the full results highlight
   */
  runnerName?: string,
  /**
   * Indicates if the results are still pending
   */
  isResultsPending: boolean,
  /**
   * Array with the bets placed on the race
   */
  bets: Array<RaceBet>,
  /**
   * Array with the bets placed on the race
   */
  races: ?Array<RaceInfoMyBets | RaceProgram>,
  /**
   * Object with wagers grouped for the race
   */
  wagerGroup: ?WroWagerGroup,
  /**
   * Toggle to enable the new bet ticket design
   */
  myBetsResultedBetTicket: boolean,
  /**
   * Race type code
   */
  raceTypeCode: RaceTypeCodeEnum,
  /**
   * Counter that displays the number of bets placed on the race
   */
  defaultTab: TabKey,
  /**
   * Callback to be runed when user clicks a tab
   */
  onTabClick: UnaryFn<TabKey, boolean>,
  /**
   * Runner Line expanded by leg
   */
  legExpandedRunner: string,
  /**
   * Runner Line expand callBack
   */
  runnerExpandLine: UnaryFn<ExpandLineArgs, void>,
  /**
   * Render Expanded info
   */
  runnerExpandInfo: QuadFn<
    string,
    number,
    RaceCardBettingInterest[],
    string,
    Element<*> | void
  >,
  /**
    should expand for ipp
   */
  inLinePastPerformance: boolean,
  /**
   * type of handicapping to choose format
   */
  handicapType: HandicapOptionsType,
  /**
   * Function open handicapping options dropdown
   */
  openHandicapCallback?: NullaryFn<mixed>,
  /**
   * For the first element of inline handicapping be bold
   */
  firstHandicapBold: boolean,
  /**
   * The race number for this race officials
   */
  raceNumber: string | number,
  /**
   * The race id for this race officials
   */
  raceId?: string,
  /**
   * The next race url
   */
  nextRaceUrl: string,
  /**
   * The tracks page url
   */
  tracksUrl: string,
  /**
   * Callback for clicking the next race button
   */
  onNextRaceClick: NullaryFn<mixed>,
  /**
   * Callback for clicking the change track button
   */
  onChangeTrackClick: NullaryFn<mixed>,
  /**
   * Boolean to show or hide the handicapping selection header
   */
  hasNoHandicappingSelection: boolean,
  /**
   * enables the full results tabs
   */
  hasTimeformData: boolean,
  /**
   * winning time
   */
  winningTime: string,
  /**
   * Check if this race is greyhound
   */
  isGreyhound: boolean,
  /**
   * The device this is displaying on
   */
  device: Device,
  /**
   * Track data source
   */
  trackDataSource: string,
  /**
   * Toggle to enable the race results redesign (RaceRunnersPayoffs only)
   */
  enableRaceResultsReDesign: boolean,
  /**
   *  GTM to see all horses on full results
   */
  seeAllHorsesGTM: NullaryFn<void>,
  scrollRunnerPP: boolean,
  scrollOffset: number,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  racePayoffsShift: boolean
};

export default class RaceOfficials extends Component<Props, State> {
  static defaultProps = {
    resultsType: "official",
    bettingInterests: [],
    scratchedRunners: [],
    results: null,
    fullResults: [],
    runnerName: "",
    raceTypeCode: "T",
    isResultsPending: true,
    bets: [],
    races: [],
    wagerGroup: null,
    myBetsResultedBetTicket: false,
    defaultTab: "racecard",
    onTabClick: () => false,
    legExpandedRunner: "",
    runnerExpandLine: noop,
    runnerExpandInfo: noop,
    inLinePastPerformance: false,
    handicapType: "basic",
    openHandicapCallback: noop,
    raceNumber: "1",
    firstHandicapBold: false,
    nextRaceUrl: "",
    tracksUrl: "",
    onNextRaceClick: noop,
    onChangeTrackClick: noop,
    hasNoHandicappingSelection: false,
    hasTimeformData: false,
    winningTime: "",
    isGreyhound: false,
    device: "mobile",
    trackDataSource: "",
    enableRaceResultsReDesign: false,
    seeAllHorsesGTM: noop,
    scrollRunnerPP: false,
    scrollOffset: 0,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    raceId: "0",
    racePayoffsShift: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      selectedtab: this.props.defaultTab
    };

    bindAll(
      this,
      "buildTabs",
      "handleTabClick",
      "renderActiveTabContent",
      "renderResultTab",
      "getHandicappingFormat"
    );
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.state.selectedtab !== nextProps.defaultTab) {
      this.setState({ selectedtab: nextProps.defaultTab });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return (
      nextState.selectedtab !== this.state.selectedtab ||
      nextProps.defaultTab !== this.props.defaultTab ||
      nextProps.bettingInterests.length !==
        this.props.bettingInterests.length ||
      get(nextProps, "results.runners", []).length !==
        get(this.props, "results.runners", []).length ||
      get(nextProps.results, "payoffs", []).length !==
        get(this.props.results, "payoffs", []).length ||
      !isEqual(nextProps, this.props)
    );
  }

  getHandicappingFormat(handicapKey: HandicapOptionsType) {
    let option = [];

    const { raceTypeCode } = this.props;

    if (raceTypeCode === "G") {
      option = handicappingGreyhoundOptions;
    } else if (raceTypeCode === "H") {
      option = handicappingHarnessOptions;
    } else {
      option = handicappingOptions;
    }

    const findRes =
      option.find((handicapItem) => handicapItem.key === handicapKey) ||
      option[0];

    return handicapKey === "basic"
      ? {
          title: findRes.title
        }
      : findRes;
  }

  handleTabClick(newTab: TabKey) {
    if (this.state.selectedtab !== newTab && this.props.onTabClick(newTab)) {
      this.setState({ selectedtab: newTab });
    }
  }

  buildTabs() {
    const {
      hasTimeformData,
      isResultsPending,
      resultsType,
      bets,
      enableRaceResultsReDesign,
      myBetsResultedBetTicket,
      wagerGroup
    } = this.props;
    const title = hasTimeformData ? "Full Result" : "Basic Result";
    const totalBets = myBetsResultedBetTicket
      ? wagerGroup?.wagers.length || 0
      : bets.length;

    let resultsTab;
    switch (resultsType) {
      case "full":
        resultsTab = {
          title: enableRaceResultsReDesign ? "Result" : title,
          isTitleUppercase: !enableRaceResultsReDesign,
          isActive: this.state.selectedtab === "fullresult",
          onClick: () => this.handleTabClick("fullresult"),
          qaLabel: hasTimeformData
            ? "raceofficials-fullresult"
            : "raceofficials-basicresult"
        };
        break;

      case "official":
      default:
        resultsTab = {
          title: "Result",
          isTitleUppercase: !enableRaceResultsReDesign,
          subTitle: isResultsPending ? (
            <TextHighlighted>PENDING</TextHighlighted>
          ) : (
            ""
          ),
          isActive: this.state.selectedtab === "result",
          onClick: () => this.handleTabClick("result"),
          qaLabel: "raceofficials-result"
        };
        break;
    }

    return [
      resultsTab,
      ...(totalBets === 0
        ? []
        : [
            {
              title: "Bets",
              isActive: this.state.selectedtab === "bets",
              isTitleUppercase: !enableRaceResultsReDesign,
              isDisabled: totalBets === 0,
              counter: totalBets,
              onClick: () => this.handleTabClick("bets"),
              qaLabel: "raceofficials-bets"
            }
          ]),
      {
        title: "Racecard",
        isTitleUppercase: !enableRaceResultsReDesign,
        isActive:
          this.state.selectedtab === "racecard" ||
          this.state.selectedtab === "none",
        onClick: () => this.handleTabClick("racecard"),
        qaLabel: "raceofficials-racecard"
      }
    ];
  }

  renderActiveTabContent() {
    switch (this.state.selectedtab) {
      case "result":
        return this.renderResultTab();

      case "fullresult":
        return this.renderFullResultTab();

      case "bets":
        return this.props.myBetsResultedBetTicket
          ? this.renderNewMyBets()
          : this.renderMyBets();

      case "none":
      case "racecard":
      default:
        return this.renderRaceCardtab();
    }
  }

  renderMyBets() {
    return this.props.bets.length > 0 ? (
      <RaceBetsContainer key="bets-tab">
        <RaceBets bets={this.props.bets} raceNumber={this.props.raceNumber} />
      </RaceBetsContainer>
    ) : (
      <NoBetsContainer>
        <Alerts isVertical type="info" message="You've no bets on this race" />
      </NoBetsContainer>
    );
  }

  renderNewMyBets() {
    const { wagerGroup, races } = this.props;
    return wagerGroup && wagerGroup.wagers.length > 0 ? (
      <RaceBetsContainer key="bets-tab" myBetsBody>
        <MyBetsBody
          bet={wagerGroup}
          races={races || []}
          currentRaceDate={first(wagerGroup.value.split("|"))}
          hasPastPerformance={false}
          showLiveVideo={false}
          isInsideRaceOfficials
        />
      </RaceBetsContainer>
    ) : (
      <NoBetsContainer>
        <Alerts isVertical type="info" message="You've no bets on this race" />
      </NoBetsContainer>
    );
  }

  renderResultTab() {
    return (
      <ResultsContainer>
        {!this.props.isResultsPending && (
          <Fragment>
            {this.props.enableRaceResultsReDesign ? (
              <RaceRunnersPayoffsReDesign
                shouldExpandMoreRunners={checkTimeformData(
                  get(this.props, "results")
                )}
                raceTypeCode={this.props.raceTypeCode}
                runners={get(this.props.results, "runners", [])}
                bettingInterests={this.props.bettingInterests}
                winningTime={this.props.winningTime}
                runnerNameHighlighted={this.props.runnerName}
                raceId={this.props.raceId}
                racePayoffsShift={this.props.racePayoffsShift}
              />
            ) : (
              <RaceRunnersPayoffs
                raceTypeCode={this.props.raceTypeCode}
                runners={get(this.props.results, "runners", [])}
              />
            )}
            <RacePayoffs
              raceNumber={this.props.raceNumber}
              raceTypeCode={this.props.raceTypeCode}
              enableRaceResultsReDesign={this.props.enableRaceResultsReDesign}
              payoffs={get(this.props.results, "payoffs", [])}
            />
          </Fragment>
        )}
        {this.props.isResultsPending && (
          <Fragment>
            <RaceMessageRedirect
              title="Result pending"
              message="Full result will be posted once they are official"
              nextRaceUrl={this.props.nextRaceUrl}
              tracksUrl={this.props.tracksUrl}
              onNextRaceClick={this.props.onNextRaceClick}
              onChangeTrackClick={this.props.onChangeTrackClick}
            />
            <ResultsMask />
          </Fragment>
        )}
      </ResultsContainer>
    );
  }

  renderFullResultTab = () => {
    const {
      hasTimeformData,
      raceTypeCode,
      results,
      runnerName,
      fullResults,
      scratchedRunners,
      winningTime,
      enableRaceResultsReDesign,
      seeAllHorsesGTM
    } = this.props;

    return (
      <Fragment>
        {!hasTimeformData && (
          <Message
            enableRaceResultsReDesign={enableRaceResultsReDesign}
            type="info"
            message="Full result unavailable at this time"
          />
        )}
        {enableRaceResultsReDesign ? (
          <RaceRunnersPayoffsReDesign
            raceTypeCode={raceTypeCode}
            runners={get(results, "runners", [])}
            bettingInterests={this.props.bettingInterests}
            winningTime={winningTime}
            runnerNameHighlighted={runnerName}
            shouldExpandMoreRunners={this.props.hasTimeformData}
            raceId={this.props.raceId}
          />
        ) : (
          <React.Fragment>
            <RaceResults
              resultsType={hasTimeformData ? "full" : "basic"}
              runnerName={runnerName}
              runners={fullResults}
              scratchedRunners={scratchedRunners}
              raceTypeCode={raceTypeCode}
              winningTime={winningTime}
              seeAllHorsesGTM={seeAllHorsesGTM}
            />
            <RaceRunnersPayoffs
              raceTypeCode={raceTypeCode}
              runners={get(results, "runners", [])}
              displayRunner={false}
            />
          </React.Fragment>
        )}
        <RacePayoffs
          raceNumber={this.props.raceNumber}
          raceTypeCode={raceTypeCode}
          payoffs={get(results, "payoffs", [])}
          enableRaceResultsReDesign={enableRaceResultsReDesign}
        />
      </Fragment>
    );
  };

  renderRaceCardtab() {
    const handicappingFormat = this.getHandicappingFormat(
      this.props.handicapType
    );

    const handicapOptions = [
      {
        title: handicappingFormat.title,
        callback: this.props.openHandicapCallback,
        icon: handicapping,
        visible: true,
        qaLabel: "runnerListHeader-handicapInfoBtn"
      }
    ];

    return (
      <Fragment>
        {this.props.isResultsPending && (
          <RaceMessageRedirect
            isWarning
            hasBorderBottom
            message="Betting has now closed on this race"
            nextRaceUrl={this.props.nextRaceUrl}
            tracksUrl={this.props.tracksUrl}
            onNextRaceClick={this.props.onNextRaceClick}
            onChangeTrackClick={this.props.onChangeTrackClick}
          />
        )}
        <RaceCardTableWrapper
          removeMargin={this.props.enableRaceResultsReDesign}
          device={this.props.device}
        >
          <RaceCardTable
            device={this.props.device}
            noBorderOnFirstChild={this.props.hasNoHandicappingSelection}
          >
            {!this.props.hasNoHandicappingSelection && (
              <RunnerListHeader
                isWagerable={false}
                handicapOptions={handicapOptions}
                handicapInfo={get(handicappingFormat, "handicapData")}
                raceTypeCode={this.props.raceTypeCode}
                hasHandicappingSelection
              />
            )}
            <RunnerList
              runners={this.props.bettingInterests}
              raceTypeCode={this.props.raceTypeCode}
              isWagerable={false}
              numColumns={1}
              firstHandicapBold={this.props.firstHandicapBold}
              trackDataSource={this.props.trackDataSource}
              expandedRunner={this.props.legExpandedRunner}
              expandLine={this.props.runnerExpandLine}
              expandInfo={this.props.runnerExpandInfo}
              inLinePastPerformance={this.props.inLinePastPerformance}
              scrollRunnerPP={this.props.scrollRunnerPP}
              scrollOffset={this.props.scrollOffset}
              handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            />
          </RaceCardTable>
        </RaceCardTableWrapper>
      </Fragment>
    );
  }

  render() {
    const tabs = this.buildTabs();

    return (
      <Fragment>
        <ROWrapper data-qa-label="raceOfficials">
          <TabGroup
            tabs={tabs}
            className={this.props.enableRaceResultsReDesign ? "tab-group" : ""}
            stretch
          />
          {this.renderActiveTabContent()}
        </ROWrapper>
      </Fragment>
    );
  }
}
