import { isEmpty, flatten, get, size } from "lodash";
import { Probable, RaceWagerType, ProbableBetCombo } from "@tvg/ts-types/Race";
import formatCurrency, {
  formatPurseWithoutCurrency
} from "@tvg/formatter/currency";
import { WagerTypes, WagerTypesPos, WagerType } from "@tvg/ts-types/Wager";

import { exactaAndDailyDouble } from "./potentialReturn/exactaAndDailyDouble";
import { quinella } from "./potentialReturn/quinella";
import { placeAndShow } from "./potentialReturn/placeAndShow";
import { win } from "./potentialReturn/win";
import { BetComboHash } from "./types";

export const doesRaceWagerTypeHasProbables = (
  probables: Probable[],
  currentWagerTypeId: number
): boolean => {
  if (!isEmpty(probables) && currentWagerTypeId) {
    let probableIndex = -1;
    // Checking if has index there for it means we have a probable for current wager type
    if (
      currentWagerTypeId === WagerTypes.WIN_PLACE ||
      currentWagerTypeId === WagerTypes.WIN_SHOW ||
      currentWagerTypeId === WagerTypes.PLACE_SHOW ||
      currentWagerTypeId === WagerTypes.WIN_PLACE_SHOW
    ) {
      [WagerTypes.WIN, WagerTypes.PLACE, WagerTypes.SHOW].forEach((type) => {
        const possibleIndex = probables.findIndex(
          (probable: Probable) => probable.wagerType.id === type
        );
        if (possibleIndex > -1) {
          probableIndex = possibleIndex;
        }
      });
    } else {
      probableIndex = probables.findIndex(
        (probable: Probable) => probable.wagerType.id === currentWagerTypeId
      );
    }

    if (probableIndex > -1) {
      return true;
    }
  }

  return false;
};

export const formatProbableAmountList = (
  probableAmountList: number[],
  betsNumber: number = 1
): string => {
  const probableAmountLength = probableAmountList.length;

  const minValue = Math.min(...probableAmountList) * betsNumber;
  const maxValue = Math.max(...probableAmountList) * betsNumber;

  if (!probableAmountLength) {
    return "0";
  }

  if (probableAmountLength === 1) {
    return probableAmountList[0] > 999
      ? formatPurseWithoutCurrency(probableAmountList[0] * betsNumber)
      : formatCurrency(probableAmountList[0] * betsNumber);
  }

  const minValueFormated =
    minValue > 999
      ? formatPurseWithoutCurrency(minValue)
      : formatCurrency(minValue);

  const maxValueFormated =
    maxValue > 999
      ? formatPurseWithoutCurrency(maxValue)
      : formatCurrency(maxValue);

  if (minValueFormated === maxValueFormated) {
    return minValueFormated;
  }

  if (minValueFormated === "$0.00" && maxValueFormated !== "$0.00") {
    return maxValueFormated;
  }

  return `${minValueFormated} - ${maxValueFormated}`;
};

export const getBetCombosHash = (betCombos: ProbableBetCombo[]): BetComboHash =>
  betCombos.reduce(
    (accumulator: BetComboHash, currentValue: ProbableBetCombo) => {
      const newValue: BetComboHash = {};

      if (currentValue.runner2 === null) {
        newValue[currentValue.runner1] = currentValue.payout;
      } else {
        newValue[`${currentValue.runner1}-${currentValue.runner2}`] =
          currentValue.payout;
      }

      return {
        ...accumulator,
        ...newValue
      };
    },
    {}
  );

export const getKindOfSpecialGroupType = (
  betSelections: string[][],
  wagerType: RaceWagerType | WagerType | null
): number[] => {
  const type = get(wagerType, "id") || get(wagerType, "type.id");
  const isSpecialGroup = !![
    WagerTypes.WIN_PLACE,
    WagerTypes.WIN_SHOW,
    WagerTypes.PLACE_SHOW,
    WagerTypes.WIN_PLACE_SHOW
  ].find((id) => id === type);

  // Check if is multiple wager types and max runners is 3
  if (isSpecialGroup) {
    const MAX_RUNNERS = 3;
    if (betSelections[0].length <= MAX_RUNNERS) {
      let specialGroup: number[];
      switch (type) {
        case WagerTypes.WIN_PLACE:
          specialGroup = [WagerTypes.WIN, WagerTypes.PLACE];
          break;
        case WagerTypes.WIN_SHOW:
          specialGroup = [WagerTypes.WIN, WagerTypes.SHOW];
          break;
        case WagerTypes.PLACE_SHOW:
          specialGroup = [WagerTypes.PLACE, WagerTypes.SHOW];
          break;
        case WagerTypes.WIN_PLACE_SHOW:
          specialGroup = [WagerTypes.WIN, WagerTypes.PLACE, WagerTypes.SHOW];
          break;
        default:
          specialGroup = [];
      }

      return specialGroup;
    }
  }

  return [];
};

export const getProbableValues = (
  probableIndex: number,
  probables: Probable[],
  betSelections: string[][],
  betAmount: number
): number[] => {
  if (probableIndex !== -1) {
    // Search and calculate probable
    const { wagerType, minWagerAmount, betCombos } = probables[probableIndex];

    // Turn betCombo into an hashTable
    const betCombosHash = getBetCombosHash(betCombos);

    // Find which payout we want to multiply

    const typesProps = {
      betSelections,
      betCombosHash,
      minWagerAmount,
      betAmount
    };

    switch (wagerType.id) {
      // Exacta
      case WagerTypes.EXACTA:
        return exactaAndDailyDouble(typesProps);
      // Daily Double
      case WagerTypes.DAILY_DOUBLE:
        return exactaAndDailyDouble(typesProps);
      // Quinella
      case WagerTypes.QUINELLA:
        return quinella(typesProps);
      // Show
      case WagerTypes.SHOW:
        return placeAndShow({
          ...typesProps,
          wagerTypeId: wagerType.id
        });
      // Place
      case WagerTypes.PLACE:
        return placeAndShow({
          ...typesProps,
          wagerTypeId: wagerType.id
        });
      // Win
      case WagerTypes.WIN:
        return win(typesProps);
      default:
        return [];
    }
  }

  return [];
};

export const hasCalculatedProbable = (currentProbable: string = "0"): boolean =>
  currentProbable !== "0";

export const getCurrentProbableValue = (
  probables: Probable[],
  currentWagerType: RaceWagerType | WagerType | null,
  betAmount: number,
  betSelections: string[][],
  betsNumber: number = 1
): string => {
  let probableValueList: number[] = [];

  if (
    isEmpty(probables) ||
    isEmpty(currentWagerType) ||
    !betAmount ||
    isEmpty(flatten(betSelections))
  ) {
    return "0";
  }

  let probableIndex = -1;
  const specialGroupWagerTypes = getKindOfSpecialGroupType(
    betSelections,
    currentWagerType
  );

  if (specialGroupWagerTypes.length) {
    const allProbables = specialGroupWagerTypes.map((wagerTypeId) => {
      const probIndex = probables.findIndex(
        (probable: Probable) => probable.wagerType.id === wagerTypeId
      );

      return getProbableValues(probIndex, probables, betSelections, betAmount);
    });

    // Check if it has invalid probable
    if (allProbables.find((probable) => !size(probable))) {
      return "0";
    }

    let minOfMin = 999999;
    let maxOfWin = 0;
    let maxOfPlace = 0;
    let maxOfShow = 0;

    allProbables.forEach((probable, index) => {
      // Get the biggest numbers for each wager
      if (index === WagerTypesPos.Win) {
        maxOfWin = Math.max(...probable);
      } else if (index === WagerTypesPos.Place) {
        maxOfPlace = Math.max(...probable);
      } else if (index === WagerTypesPos.Show) {
        maxOfShow = Math.max(...probable);
      }

      // Get the lowest number but checking on each wager
      const currentMinNumber = Math.min(...probable);
      if (currentMinNumber < minOfMin) {
        minOfMin = currentMinNumber;
      }
    });

    const sumOfWagerTypes = maxOfWin + maxOfPlace + maxOfShow;
    probableValueList = [minOfMin, sumOfWagerTypes];
  } else {
    probableIndex = probables.findIndex(
      (probable: Probable) =>
        probable.wagerType.id === get(currentWagerType, "type.id") ||
        probable.wagerType.id === get(currentWagerType, "id")
    );

    probableValueList = getProbableValues(
      probableIndex,
      probables,
      betSelections,
      betAmount
    );
  }

  return formatProbableAmountList(probableValueList, betsNumber);
};
