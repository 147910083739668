import React, { FC } from "react";

export const Italy: FC = () => (
  <>
    <g clipPath="url(#clip0_20811_7054)">
      <g clipPath="url(#clip1_20811_7054)">
        <path d="M32 -1H0V20.3333H32V-1Z" fill="#009246" />
        <path d="M32 -1H10.6667V20.3333H32V-1Z" fill="white" />
        <path d="M32 -1H21.3333V20.3333H32V-1Z" fill="#CE2B37" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_20811_7054">
        <rect width="32" height="20" rx="2" fill="white" />
      </clipPath>
      <clipPath id="clip1_20811_7054">
        <rect
          width="32"
          height="21.3333"
          fill="white"
          transform="translate(0 -1)"
        />
      </clipPath>
    </defs>
  </>
);
