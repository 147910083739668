import React, { FC } from "react";

export const Chile: FC = () => (
  <>
    <g clipPath="url(#clip0_23344_57)">
      <g clipPath="url(#clip1_23344_57)">
        <path d="M0 0.5H32V20.5H0V0.5Z" fill="white" />
        <path d="M0 0.5H10.6667V15.5H0V0.5Z" fill="#0033A0" />
        <path d="M0 10.5H32V20.5H0V10.5Z" fill="#DA281B" />
        <path
          d="M5.93203 4.72746H7.86948L6.30205 5.79508L6.90076 7.52254L5.33333 
          6.45492L3.7659 7.52254L4.36461 5.79509L2.79718 4.72746H4.73463L5.33333 
          3L5.93203 4.72746Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_23344_57">
        <rect y="0.5" width="32" height="20" rx="2" fill="white" />
      </clipPath>
      <clipPath id="clip1_23344_57">
        <rect
          width="32"
          height="20"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </>
);
