import mediator from "@tvg/mediator";
import { PlaceBetGtm, ConfirmBetErrorGtm, ConfirmBetSuccessGtm } from "./types";

export const confirmBetGtmEvent = ({
  raceNumber,
  trackName,
  betType,
  betAmount,
  selectionSource,
  raceType
}: PlaceBetGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_CONFIRM",
    payload: {
      raceNumber,
      trackName,
      betType,
      betAmount,
      selectionSource,
      raceType
    }
  });
};

export const confirmBetErrorGtmEvent = ({
  error,
  raceNumber,
  trackName,
  selections,
  betAmount,
  runnerAmount,
  betType
}: ConfirmBetErrorGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_ERROR",
    payload: {
      error,
      raceNumber,
      trackName,
      selections,
      betAmount,
      runnerAmount,
      betType
    }
  });
};

export const confirmBetSuccessGtmEvent = ({
  raceNumber,
  trackName,
  selections,
  betAmount,
  runnerAmount,
  repeatBet,
  betId,
  betType,
  selectionSource,
  raceType
}: ConfirmBetSuccessGtm) => {
  mediator.base.dispatch({
    type: "BET:BET_SUCCESS",
    payload: {
      raceNumber,
      trackName,
      selections,
      betAmount,
      runnerAmount,
      repeatBet,
      betId,
      betType,
      isRepeat: repeatBet ? "Repeat" : undefined,
      selectionSource,
      raceType
    }
  });
};
