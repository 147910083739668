// @flow

import styled, { css } from "styled-components";
import { buildText } from "../../_atom/Text";
import { fontMedium, titleFont } from "../../_static/Typography";
import ButtonDefault from "../../_atom/Buttons/default";
import buildColor from "../../_static/ColorPalette";

export const RLHeader = styled.tr`
  width: 100%;
  border-style: solid;
  border-width: ${(props) => (props.leg ? "0 1px 1px 1px" : "0 0 1px 0")};
  border-color: ${buildColor("grey", "300")};
  border-bottom: 0;
  height: 40px;
  background-color: ${buildColor("blue", "000")};
`;

export const RLHandicap = styled.tr`
  height: 24px;
  background-color: ${buildColor("blue", "000")};
  border-style: solid;
  border-color: ${buildColor("grey", "300")};
  border-width: ${(props) => (props.leg ? "0 1px 0 1px" : "0")};
  border-top: 0;
`;

export const HandicapWrapper = styled.td`
  padding: 0 8px;
  text-align: left;
  position: relative;
`;

export const InlineContainer = styled.span`
  display: flex;
  padding: 4px 0;
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const RunnerInfoWrapper = styled.th`
  max-width: 0;
  padding: 0 0 0 4px;
  text-align: left;
`;

export const BetAllWrapper = styled.th`
  width: ${(props) => (props.isLeg ? "30px" : "70px")};
  padding: ${(props) => (props.numColumns < 3 ? "0" : "0 4px 0 0")};

  & > div {
    display: flex;
    justify-content: flex-end;
  }
`;

export const BetAllButton = styled(ButtonDefault)`
  width: 48px;
  height: 40px;
  background: none;
  font-family: ${fontMedium};
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    height: auto;
    margin: 0;
  }
`;

export const CloseButton = styled(ButtonDefault)`
  width: 48px;
  height: 40px;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;

  & > svg {
    float: right;
  }
`;

export const HandicappTypeWrapper = styled(ButtonDefault)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  padding: 4px;
  margin: 0;
  font-size: 14px;
  ${({ iconBefore }) =>
    iconBefore &&
    css`
      padding: 4px 4px 4px 19px;
    `}

  &:active {
    background-color: ${buildColor("blue", "100")};
  }

  & > svg {
    position: absolute;
    top: calc(50% - 6px);
    left: 4px;
  }
`;

export const SeparatorLine = styled.div`
  border-left: 1px solid ${buildColor("grey", "600")};
  width: 0;
  height: 16px;
  display: inline-block;
  margin: 0 4px;
`;

export const Text = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("blue_accent", "500"),
    uppercase: true,
    bold: false
  })
)`
  ${({ verticalSpacer }) =>
    verticalSpacer &&
    css`
      margin-bottom: 4px;
    `}
  height: 11px;
  font-family: ${fontMedium};
`;

export const TitleBoldEllipsis = styled(
  buildText({
    tag: "strong",
    uppercase: false,
    fontSize: 10,
    bold: true,
    color: buildColor("grey", "900"),
    ellipsis: true,
    qaLabel: "handicapTitle"
  })
)`
  padding-right: 9px;
  font-family: ${titleFont};
`;

export const FixedHandicapContainer = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: ${buildColor("blue", "000")};
  position: fixed;
  ${(props) =>
    props.isLeg
      ? css`
          left: 17px;
          right: 17px;
        `
      : css`
          left: 0;
          right: 0;
          margin: 0 4px;
          border-left: 1px solid ${buildColor("grey", "300")};
          border-right: 1px solid ${buildColor("grey", "300")};
        `};
  top: ${(props) => props.isHandicapFixed && `${props.fixedOffset}px`};
  box-shadow: 0 -1px 0 0 ${buildColor("grey", "300")},
    inset 0 -1px 0 0 ${buildColor("grey", "300")};
`;

export const OptionTitle = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    uppercase: false,
    bold: false
  })
)`
  text-transform: capitalize;
  padding: 0;
  font-family: ${fontMedium};
`;

export const BetAllOption = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    uppercase: true,
    bold: false
  })
)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 14px;
  font-family: ${fontMedium};

  &:active {
    background-color: ${buildColor("blue", "100")};
  }

  & > svg {
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
  }
`;
