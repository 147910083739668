import React from "react";
// @ts-ignore
import { AppRegistry } from "react-native-web";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import tvgConf from "@tvg/conf";
import ApolloContext from "@tvg/utils/apolloContext";
// TODO: add gtm for this microapp
// import Registration from "@tvg/gtm/src/modules/Registration";
import { recoverQueriesFromBackground } from "@tvg/utils/generalUtils";
import { TVGThemeProviderWeb, TvgConfProvider } from "@tvg/design-system";
import ApolloClient from "./apolloClient/ApolloClient";
import configureStore from "./configureStore";
import App from "./pages";

// @ts-ignore
const preloadedState = window.__MYBETS_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// @ts-ignore
window.handleNativeMessages = (type, body) => {
  const message = {};
  const postMessage =
    window &&
    // @ts-ignore
    window.ReactNativeWebView &&
    // @ts-ignore
    window.ReactNativeWebView.postMessage;
  if (postMessage) {
    // @ts-ignore
    message.type = type;
    if (body) {
      // @ts-ignore
      message.body = body;
    }
    postMessage(JSON.stringify(message));
  }
};

// random gui from the net...
const guid = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

// @ts-ignore
window.promiseChain = Promise.resolve();
// @ts-ignore
window.callbacks = {};

// @ts-ignore
window.nativeBridge = (type, data, success, error) => {
  const msgObj = {
    type,
    body: data || {},
    msgId: ""
  };

  if (success || error) {
    msgObj.msgId = guid();
  }

  const msg = JSON.stringify(msgObj);

  // @ts-ignore
  window.promiseChain = window.promiseChain
    .then(
      () =>
        new Promise((resolve) => {
          console.log(`sending message ${msgObj.type}`);

          if (msgObj.msgId !== "") {
            // @ts-ignore
            window.callbacks[msgObj.msgId] = {
              onsuccess: success,
              onerror: error
            };
          }
          // @ts-ignore
          window.ReactNativeWebView.postMessage(msg);
          // @ts-ignore
          resolve();
        })
    )
    // @ts-ignore
    .catch((e) => {
      console.error(`nativeBridge promise failed ${e.message}`);
    });
};

// @TODO GTM subscribers event init
// Registration();
const store = configureStore(preloadedState);
// gets conf for specific host / device
tvgConf(window.location.hostname, window.navigator.userAgent);
const defaultClient = ApolloClient.createClient(false, "graph", true);
const behgClient = ApolloClient.createClient(false, "behg", true);
const fcpClient = ApolloClient.createClient(false, "fcp");

if (typeof window !== "undefined") {
  recoverQueriesFromBackground([defaultClient, fcpClient, behgClient]);
}

const Main = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <ApolloProvider client={defaultClient}>
        <ApolloContext.Provider value={{ behgClient, fcpClient }}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ApolloContext.Provider>
      </ApolloProvider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);

const Render = () => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    // @TODO check if we can improve the hydration
    // hydrate: true,
    rootTag: document.getElementById("mybets")
  });
};

export default Render();
