import React, { FC, useEffect, useRef } from "react";
import { CarouselContainer, Header, ListItems } from "./styled-components";
import { CarouselProps } from "./types";
import { Paragraph } from "../../../src/components/typography";
import NavigationButton from "../NavigationButton";

export const Carousel: FC<CarouselProps> = ({
  items,
  numberVisibleCards = 3,
  qaLabel,
  onMount,
  onScroll,
  title = ""
}) => {
  const container = useRef<HTMLDivElement>(null);
  const scrollContainer = container.current;

  useEffect(() => {
    if (onMount && typeof onMount === "function" && container.current) {
      onMount(container.current);
    }
  }, []);

  useEffect(() => {
    if (onScroll && typeof onScroll === "function" && scrollContainer) {
      scrollContainer.addEventListener("scroll", () =>
        onScroll(scrollContainer)
      );
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener(
          "scroll",
          () => onScroll && onScroll(scrollContainer)
        );
      }
    };
  }, [scrollContainer]);

  useEffect(() => {
    if (scrollContainer) {
      scrollContainer.scrollLeft = 0;
    }
  }, [items.length]);

  const onScrollRight = () => {
    if (scrollContainer) {
      const cardWidth = (scrollContainer.firstChild as HTMLElement).offsetWidth;
      scrollContainer.scrollLeft += cardWidth * numberVisibleCards;
    }
  };

  const onScrollLeft = () => {
    if (scrollContainer) {
      const cardWidth = (scrollContainer.firstChild as HTMLElement).offsetWidth;
      scrollContainer.scrollLeft -= cardWidth * numberVisibleCards;
    }
  };

  return (
    <CarouselContainer data-qa-label={`carousel-container-${qaLabel}`}>
      <Header>
        <Paragraph qaLabel="carousel-title" fontSize={18} fontFamily="bold">
          {title}
        </Paragraph>
        <NavigationButton
          containerRef={container}
          onLeftClick={onScrollLeft}
          onRightClick={onScrollRight}
          shouldRefresh={items.length !== scrollContainer?.childNodes.length}
        />
      </Header>
      <ListItems data-qa-label={`carousel-items-${qaLabel}`} ref={container}>
        {items}
      </ListItems>
    </CarouselContainer>
  );
};
