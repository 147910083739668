// @flow
import React, {
  // $FlowFixMe
  useMemo,
  // $FlowFixMe
  useEffect
} from "react";
import { get, noop } from "lodash";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import BetCancelNotification from "@tvg/atomic-ui/_molecule/BetNotification";
import type { WroWager } from "@tvg/api/wro/types";
import formatCurrency from "@tvg/formatter/currency";
import SpinningWheel from "@tvg/atomic-ui/_atom/SpinningWheel";
import Button from "@tvg/atomic-ui/_atom/Buttons/button";
import {
  errorOutline,
  warningOutline
} from "@tvg/atomic-ui/_static/Icons/icons";
import { infoOutline } from "@tvg/atomic-ui/_static/Icons/iconsDuotone";
import {
  closeBetCancelModal,
  loadingBetCancelModalOn,
  betCancelRequestError,
  cleanBetCancelResult,
  openWagerCancellationRulesModal
} from "@tvg/sh-lib-my-bets/redux/actions";
import CancelLimitsQuery from "@tvg/sh-lib-my-bets/graphql/queries/CancelLimits.graphql";
import BehgOptionsCancel from "@tvg/sh-lib-my-bets/graphql/behgOptionsCancel.graph";
import { graphql } from "@apollo/client/react/hoc";
import { getAccountNumber } from "@urp/store-selectors";
import {
  getBetCancelWager,
  getBetCancelRaceNumber,
  getBetCancelIsLoadingRequest,
  getBetCancelRequestError,
  getMyBetBettingInterests,
  getFeatureUseTvgPotReturn,
  getFeatureIsMTPNewRules,
  getBetCancelRaces,
  getTotalActiveBets,
  getTotalSettledBets,
  getBetCancelBet
} from "@tvg/sh-lib-my-bets/redux/selectors";
import { updateBalance } from "@tvg/shared-actions/UserActions";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import sendAppMetrics, { getApp } from "@tvg/metrics-collector";
import MyBetsBodyStandalone from "@tvg/my-bets-standalone/src/MyBetsBody";
import { fontMedium } from "@tvg/atomic-ui/_static/Typography";
import PromosOnboarding from "@tvg/promos-onboarding";
import { getStoryblokPromoByStep } from "@tvg/sh-lib-promos-onboarding/redux/selectors";
import { matchPlacedWager } from "@tvg/sh-lib-promos-onboarding/utils/matchPlacedWager";
import {
  cancelBetSubmitGtmEvent,
  cancelBetErrorGtmEvent,
  cancelBetSuccessGtmEvent,
  keepBetGtmEvent
} from "@tvg/pp/src/utils/gtm/betCancel";

import MyBetsBody from "@urp/my-bets/MyBetsBody";
import type {
  Props,
  HandleCancelBetArg,
  GetNotificationArguments
} from "./types";
import cancelBet from "./utils/service";
import {
  BetItemWrapperBetCancel,
  ButtonsContainer,
  BetCancelLoadingMask
} from "./styled-components";

export const handleCancelBet = ({
  wager,
  raceNumber,
  accountNumber,
  dispatch
}: HandleCancelBetArg) => {
  cancelBetSubmitGtmEvent({
    raceNumber,
    trackName: wager.trackName,
    betAmount: wager.betTotal,
    betType: get(wager, "wagerType.code", "")
  });

  dispatch(loadingBetCancelModalOn());

  return cancelBet({ wager, accountNumber })
    .then(({ data = {} }) => {
      sendAppMetrics(getApp(), accountNumber, ["cancel_bets_number"]);

      dispatch(
        closeBetCancelModal({
          result: { status: "success" }
        })
      );
      // wait until toast animation finishes
      setTimeout(() => {
        dispatch(cleanBetCancelResult());
      }, 3500);

      const newBalance = get(data, "userBalance", undefined);
      if (newBalance) {
        dispatch(updateBalance(newBalance));

        if (tvgConf().device === "desktop") {
          mediator.base.dispatch({
            type: "ACCOUNT_BALANCE_CHANGED",
            payload: { balance: newBalance }
          });
        }
      }

      cancelBetSuccessGtmEvent({
        raceNumber,
        trackName: wager.trackName,
        betAmount: wager.betTotal,
        betType: get(wager, "wagerType.code", "")
      });
    })
    .catch((err) => {
      const errorCode = get(err, "response.data.code", "default");

      cancelBetErrorGtmEvent({
        gaEventLabel: get(err, "message", ""),
        raceNumber,
        trackName: wager.trackName,
        betAmount: wager.betTotal,
        betType: get(wager, "wagerType.code", ""),
        errorType: get(err, "message", "")
      });

      dispatch(betCancelRequestError({ payload: err }));

      throw errorCode;
    });
};

export const handleKeepBet = ({ dispatch }: { dispatch: Dispatch<any> }) => {
  dispatch(closeBetCancelModal({ result: { status: "aborted" } }));
  dispatch(cleanBetCancelResult());
  keepBetGtmEvent();
};

const infoIconProperties = () => {
  const iconElement = infoOutline;
  const color = buildColor("blue_accent", "600");
  const colorList = [
    buildColor("blue_accent", "200"),
    buildColor("blue_accent", "600")
  ];
  const colorBackground = buildColor("blue_accent", "000");
  const colorBorder = buildColor("blue_accent", "200");

  return { color, colorBackground, iconElement, colorBorder, colorList };
};

const errorIconProperties = () => {
  const iconElement = errorOutline;
  const color = buildColor("red", "500");
  const colorBackground = buildColor("red", "000");
  const colorBorder = buildColor("red", "100");

  return { color, colorBackground, iconElement, colorBorder };
};

// Disabling ESLint for next line: 'warningIconProperties' is assigned a value but never used
// eslint-disable-next-line
const warningIconProperties = () => {
  const iconElement = warningOutline;
  const color = buildColor("orange", "500");
  const colorBackground = buildColor("orange", "000");
  const colorBorder = buildColor("orange", "100");

  return { color, colorBackground, iconElement, colorBorder };
};

export const getNotificationArguments = ({
  limits,
  serviceError
}: GetNotificationArguments) => {
  let notificationArguments = {
    title: "Please confirm that you want to cancel this bet.",
    text: `You can only cancel ${limits.remainingDayCancelledCount} bets per day`,
    ...infoIconProperties(),
    showCancelRules: true
  };
  if (!serviceError) {
    if (
      limits.remainingDayCancelledCount === 0 ||
      limits.remainingDayCancelledAmount === 0
    ) {
      notificationArguments = {
        title: "Unable to cancel bet",
        text: "You have reached your cancelation limit",
        ...errorIconProperties()
      };
    } else if (limits.currentDayCancelledCount > 0) {
      notificationArguments = {
        title: `You can cancel ${limits.remainingDayCancelledCount} more bets today`,
        text: `You can currently cancel bets up to ${formatCurrency(
          limits.remainingDayCancelledAmount
        )}`,
        ...infoIconProperties(),
        showCancelRules: true
      };
    }
  } else {
    notificationArguments = {
      title: "Something went wrong...",
      text: "Close and try again later",
      ...errorIconProperties()
    };
  }

  return notificationArguments;
};

export const formatWager = (wager: WroWager) => [{ wagers: [wager] }];

export const BetCancel = (props: Props) => {
  const {
    bet,
    wager,
    raceNumber,
    accountNumber,
    isLoadingLimitsRequest, // from bet cancel request
    isLoadingBetCancelRequest, // from graph limits request
    dispatch,
    limits,
    queryError,
    betCancelRequestErrorMessage,
    selectedTab,
    selectedSettledTab,
    races,
    totalActiveBets,
    totalSettledBets,
    activeBets,
    settledBets,
    isDesktop,
    promosOnboardingStep,
    myBetsStandaloneToggle
  } = props;
  const isLoading = isLoadingLimitsRequest || isLoadingBetCancelRequest;

  const notificationArguments = getNotificationArguments({
    limits,
    serviceError: queryError || betCancelRequestErrorMessage
  });
  const hasError =
    betCancelRequestErrorMessage ||
    queryError ||
    limits.remainingDayCancelledAmount <= 0 ||
    limits.remainingDayCancelledCount <= 0;

  useEffect(() => {
    if (!isLoading) {
      mediator.base.dispatch({
        type: "MYBETS_USER_SAW_WARNING_MESSAGE",
        payload: {
          selectedTab,
          selectedSettledTab,
          message: notificationArguments.title,
          activeBets: isDesktop ? activeBets : totalActiveBets,
          settledBets: isDesktop ? settledBets : totalSettledBets
        }
      });
    }
  }, [isLoading]);

  const onShowCancelRules = () => {
    mediator.base.dispatch({
      type: "MYBETS_USER_CLICKS_SEE_RULES_ON_CANCELLED_BET",
      payload: {
        selectedTab,
        selectedSettledTab,
        activeBets: isDesktop ? activeBets : totalActiveBets,
        settledBets: isDesktop ? settledBets : totalSettledBets
      }
    });
    // $FlowFixMe
    dispatch(closeBetCancelModal());
    setTimeout(() => {
      dispatch(openWagerCancellationRulesModal({ hasBack: true }));
    }, 200);
  };

  const promoOnboardingComponent = useMemo(() => {
    const renderPlace = "cancel_bet";
    const isValidWager = matchPlacedWager(promosOnboardingStep, wager);

    if (!isValidWager) {
      return null;
    }
    return (
      <PromosOnboarding placeToRender={renderPlace} isShown={isValidWager} />
    );
  }, [wager, raceNumber, accountNumber, promosOnboardingStep]);

  return (
    <React.Fragment>
      <BetItemWrapperBetCancel data-qa-label="bet-cancel-item-wrapper">
        {promoOnboardingComponent ||
          (!isLoading && (
            <BetCancelNotification
              {...notificationArguments}
              showCancelRulesFn={onShowCancelRules}
            />
          ))}
        {myBetsStandaloneToggle ? (
          <MyBetsBody
            bet={bet}
            races={races}
            hasWagerFooter={false}
            currentRaceDate={get(wager, "raceDate", "")}
            isCancelModal
            selectedWager={wager}
            hasPastPerformance={false}
            showLiveVideo={false}
            showRaceLeg={false}
            hasWagerDetails={false}
          />
        ) : (
          <MyBetsBodyStandalone
            bet={bet}
            races={races}
            hasWagerFooter={false}
            currentRaceDate={get(wager, "raceDate", "")}
            isCancelModal
            selectedWager={wager}
            hasPastPerformance={false}
            showLiveVideo={false}
            showRaceLeg={false}
            hasWagerDetails={false}
          />
        )}
      </BetItemWrapperBetCancel>
      <ButtonsContainer>
        {hasError ? null : (
          <Button
            qaLabel="betCancelButtonConfirm"
            type="delete"
            size="bigger"
            fontFamily={fontMedium}
            onClick={
              isLoading
                ? noop
                : () =>
                    handleCancelBet({
                      wager,
                      raceNumber,
                      accountNumber,
                      dispatch
                    })
            }
            isStretched
            isUppercase={false}
            isBold={false}
            className="betCancelButtonConfirm"
          >
            {isLoading ? (
              <SpinningWheel qaLabel="betCancelLoading" />
            ) : (
              "Cancel Bet"
            )}
          </Button>
        )}
        <Button
          qaLabel="betCancelButtonKeep"
          type="secondary"
          size="bigger"
          onClick={() => handleKeepBet({ dispatch })}
          isStretched
          isUppercase={false}
          isBold={false}
          fontFamily={fontMedium}
          className="betCancelButtonKeep"
        >
          {hasError ? "Close" : "Keep Bet"}
        </Button>
      </ButtonsContainer>
      {isLoading && (
        <BetCancelLoadingMask data-qa-label="betCancelLoadingOverlay" />
      )}
    </React.Fragment>
  );
};

BetCancel.defaultProps = {
  wager: {},
  raceNumber: 1,
  accountNumber: "",
  isLoadingLimitsRequest: false,
  isLoadingBetCancelRequest: false,
  useTvgPotReturnToggle: false,
  dispatch: noop,
  limits: {},
  queryError: null,
  betCancelRequestErrorMessage: "",
  selectedTab: "ACTIVE",
  selectedSettledTab: "TODAY",
  races: [],
  totalActiveBets: 0,
  totalSettledBets: 0,
  promosOnboardingStep: null
};

export default connect(
  (store) => ({
    bet: getBetCancelBet(store),
    races: getBetCancelRaces(store),
    wager: getBetCancelWager(store),
    raceNumber: getBetCancelRaceNumber(store),
    accountNumber: getAccountNumber(store),
    isLoadingBetCancelRequest: getBetCancelIsLoadingRequest(store),
    betCancelRequestErrorMessage: getBetCancelRequestError(store),
    bettingInterests: getMyBetBettingInterests(store),
    useTvgPotReturnToggle: getFeatureUseTvgPotReturn(store),
    selectedTab: get(store, "myBetsStandalone.selectedTab"),
    selectedSettledTab: get(store, "myBetsStandalone.selectedSettledTab"),
    isMTPNewRules: getFeatureIsMTPNewRules(store),
    totalActiveBets: getTotalActiveBets(store),
    totalSettledBets: getTotalSettledBets(store),
    promosOnboardingStep: getStoryblokPromoByStep(store, "WAGER_STEP")
  }),
  (dispatch: Dispatch<*>) => ({ dispatch })
)(graphql(CancelLimitsQuery, BehgOptionsCancel)(BetCancel));
