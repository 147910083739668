import React, { FC } from "react";

export const Austria: FC = () => (
  <>
    <g clipPath="url(#clip0_18106_2864)">
      <path d="M32 0H0V20H32V0Z" fill="#F8F8F9" />
      <path d="M10.6667 0H0V20H10.6667V0Z" fill="#DE3131" />
      <path d="M32 0H21.3333V20H32V0Z" fill="#DE3131" />
    </g>
    <defs>
      <clipPath id="clip0_18106_2864">
        <path
          d="M0 2C0 0.895431 0.895431 0 2 0H30C31.1046 0 32 0.895431 32 2V18C32
          19.1046 31.1046 20 30 20H2C0.895431 20 0 19.1046 0 18V2Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </>
);
