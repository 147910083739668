import React, { FC } from "react";

export const Argentina: FC = () => (
  <>
    <path d="M0.0230877 6.66667H32V0H0V6.66667H0.0230877Z" fill="#87B2F5" />
    <path d="M32 6.66664H0.0230713V13.3333H32V6.66664Z" fill="#F8F8F9" />
    <path d="M0 13.3333V20H32V13.3333H0.0230877H0Z" fill="#87B2F5" />
    <path
      d="M15.4459 9.00439C15.5151 8.98275 15.5613 8.93946 15.6306
      8.93946L14.9148 7.44595C14.9148 7.44595 14.8918 7.40266 14.8687
      7.40266C14.8456 7.42431 14.8456 7.4676 14.8456 7.4676L15.4459 9.00439Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M15.5382 8.18188C15.5151 8.5282 15.6536 8.65807 15.6767 8.91781C15.746
      8.89617 15.8153 8.87452 15.8845 8.87452C15.8383 8.61478 15.6767 8.41998
      15.6998 8.13859C15.7229 7.79227 15.5382 7.59747 15.492 7.4676C15.4458
      7.33773 15.5382 7.25114 15.492 7.2295C15.4458 7.20785 15.3535 7.31608
      15.3997 7.51089C15.4228 7.70569 15.5613 7.74898 15.5382 8.18188Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16 8.87445C16.0231 8.87445 16.0692 8.87445 16.0923 8.87445L16.0231
      7.22943C16.0231 7.22943 16.0231 7.18614 15.9769 7.18614C15.9307 7.18614
      15.9307 7.22943 15.9307 7.22943L15.8615 8.87445C15.9307 8.87445 15.9538
      8.87445 16 8.87445Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M14.8456 10.0001C14.8456 9.95679 14.8456 9.93514 14.8456 9.9135L13.1371
      9.97843C13.1371 9.97843 13.0909 9.97843 13.0909 10.0217C13.0909 10.065
      13.1371 10.065 13.1371 10.065L14.8456 10.1299C14.8456 10.065 14.8456
      10.0434 14.8456 10.0001Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M13.3218 8.98272L14.8687 9.67536C14.8918 9.61042 14.9149 9.54548 14.938
      9.50219L13.3449 8.91778C13.3449 8.91778 13.2987 8.89614 13.2756
      8.93943C13.2756 8.96107 13.3218 8.98272 13.3218 8.98272Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M13.7605 8.65806C13.9221 8.76628 14.0606 8.70135 14.3377
      9.02602C14.5686 9.30741 14.7764 9.28576 14.9842 9.45892C15.0072 9.39399
      15.0534 9.3507 15.0996 9.28576C14.8687 9.13425 14.6147 9.09096 14.43
      8.87451C14.1992 8.61477 13.9221 8.61477 13.7836 8.54983C13.645 8.4849
      13.6681 8.35503 13.622 8.37667C13.5758 8.39832 13.5989 8.52819 13.7605
      8.65806Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M15.0996 9.28579C15.1458 9.2425 15.192 9.19922 15.2381 9.15593L13.9914
      8.03038C13.9914 8.03038 13.9452 7.98709 13.9221 8.00874C13.899 8.03038
      13.9452 8.07367 13.9452 8.07367L15.0996 9.28579Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M14.8456 8.48486C14.961 8.83118 15.1457 8.89611 15.2611 9.13421C15.3073
      9.09092 15.3766 9.04763 15.4459 9.02598C15.2842 8.78789 15.0764 8.67966
      14.9841 8.39828C14.8687 8.0736 14.6147 7.96538 14.5223 7.85715C14.43
      7.74893 14.4762 7.6407 14.43 7.6407C14.3838 7.6407 14.3376 7.77057 14.4531
      7.92208C14.5454 8.09524 14.707 8.0736 14.8456 8.48486Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M17.5699 8.8961C17.2236 9.00433 17.1543 9.17749 16.9235 9.28571C16.9696
      9.329 17.0158 9.39394 17.0389 9.45887C17.2698 9.30736 17.3852 9.09091
      17.6623 9.02597C18.0086 8.91775 18.1009 8.67965 18.2164 8.59307C18.3318
      8.50649 18.4473 8.54978 18.4473 8.50649C18.4473 8.4632 18.3087 8.41991
      18.1471 8.52814C17.9624 8.61472 17.9624 8.76623 17.5699 8.8961Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M17.1082 9.65369L18.6551 8.96105C18.6551 8.96105 18.7013 8.93941
      18.7013 8.91776C18.6782 8.89612 18.632 8.89612 18.632 8.89612L17.0389
      9.45889C17.062 9.54547 17.0851 9.58876 17.1082 9.65369Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M18.5628 9.41558C18.3781 9.43722 18.3089 9.58874 17.8702
      9.54545C17.5008 9.5238 17.3853 9.65367 17.1083 9.67532C17.1314 9.74025
      17.1314 9.80519 17.1545 9.87012C17.4315 9.82683 17.6162 9.67532 17.9164
      9.69696C18.2627 9.71861 18.4705 9.54545 18.609 9.50216C18.7475 9.45887
      18.8399 9.54545 18.8399 9.50216C18.863 9.48051 18.7706 9.39393 18.5628
      9.41558Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.1155 8.87452C16.1847 8.87452 16.254 8.89617 16.3233 8.91781C16.3925
      8.65807 16.3233 8.41998 16.4387 8.16024C16.6003 7.85721 16.508 7.59747
      16.508 7.4676C16.508 7.33773 16.6465 7.29444 16.6003 7.25115C16.5772
      7.20786 16.4387 7.27279 16.3925 7.4676C16.3464 7.6624 16.4618 7.77062
      16.254 8.13859C16.1155 8.46327 16.2078 8.63643 16.1155 8.87452Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.531 9.00435L17.1313 7.46755C17.1313 7.46755 17.1544 7.42426 17.1082
      7.40262C17.0851 7.38097 17.062 7.44591 17.062 7.44591L16.3463
      8.93942C16.4156 8.93942 16.4618 8.96106 16.531 9.00435Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.9927 8.39836C16.7157 8.63645 16.7157 8.80961 16.5541
      9.00442C16.6233 9.04771 16.6695 9.06935 16.7388 9.11264C16.9004 8.89619
      16.9235 8.63645 17.1544 8.46329C17.4314 8.24684 17.4314 7.96546 17.5007
      7.85723C17.57 7.72736 17.6854 7.74901 17.6854 7.70572C17.6623 7.66243
      17.5238 7.68407 17.4083 7.83559C17.2698 7.9871 17.3391 8.11697 16.9927
      8.39836Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.9004 9.26417L18.0548 8.05204C18.0548 8.05204 18.1009 8.00875
      18.0779 7.98711C18.0548 7.96546 18.0086 8.00876 18.0086 8.00876L16.7618
      9.1343C16.808 9.17759 16.8542 9.22088 16.9004 9.26417Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M13.3911 9.58875C13.5758 9.63204 13.6912 9.52381 14.0837 9.71862C14.407
      9.89178 14.5917 9.8052 14.8456 9.89178C14.8456 9.82684 14.8687 9.76191
      14.8918 9.69697C14.6147 9.63204 14.3608 9.69697 14.1068 9.5671C13.7836
      9.41559 13.5296 9.50217 13.3911 9.50217C13.2526 9.50217 13.2064 9.3723
      13.1833 9.41559C13.1371 9.41559 13.2064 9.54546 13.3911 9.58875Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.4618 11.8398C16.4849 11.4935 16.3464 11.3637 16.3233 11.1039C16.254
      11.1256 16.1847 11.1256 16.1155 11.1472C16.1617 11.4069 16.3233 11.6017
      16.3002 11.8831C16.2771 12.2295 16.4618 12.4243 16.508 12.5541C16.5542
      12.684 16.4618 12.7706 16.508 12.7922C16.5542 12.8139 16.6465 12.7056
      16.6003 12.5108C16.5541 12.316 16.4156 12.2511 16.4618 11.8398Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M17.1313 11.5152C17.0159 11.1689 16.8312 11.1256 16.7157
      10.8875C16.6695 10.9308 16.6003 10.9741 16.531 10.9957C16.6926 11.2338
      16.9004 11.342 16.9928 11.6018C17.1082 11.9265 17.3622 12.0347 17.4545
      12.1429C17.5469 12.2511 17.5007 12.3594 17.5469 12.3594C17.5931 12.3594
      17.6392 12.2295 17.5238 12.078C17.4314 11.9265 17.2698 11.9265 17.1313
      11.5152Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.531 11.0174C16.4618 11.039 16.4156 11.0607 16.3463 11.0823L17.062
      12.5758C17.062 12.5758 17.0851 12.6191 17.1082 12.6191C17.1313 12.6191
      17.1313 12.5542 17.1313 12.5542L16.531 11.0174Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M18.2395 11.3637C18.0779 11.2555 17.9393 11.3204 17.6623
      10.9957C17.4314 10.736 17.2236 10.736 17.0389 10.5628C17.0158 10.6278
      16.9696 10.6711 16.9235 10.736C17.1543 10.8875 17.4083 10.9092 17.593
      11.1256C17.8239 11.3853 18.101 11.3853 18.2395 11.4503C18.378 11.5152
      18.3549 11.6451 18.4011 11.6234C18.4011 11.6018 18.4011 11.4719 18.2395
      11.3637Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.9004 10.736C16.8542 10.7793 16.808 10.8226 16.7618 10.8659L18.0086
      11.9914C18.0086 11.9914 18.0548 12.0347 18.0779 12.013C18.1009 11.9914
      18.0548 11.9481 18.0548 11.9481L16.9004 10.736Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M18.8629 9.97843L17.1775 9.9135C17.1775 9.93514 17.1775 9.97843 17.1775
      10.0001C17.1775 10.0434 17.1775 10.065 17.1775 10.0867L18.8629
      10.0217C18.8629 10.0217 18.9091 10.0217 18.9091 9.97843H18.8629Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M18.609 10.4113C18.4243 10.368 18.3088 10.4762 17.9163 10.2814C17.5931
      10.1299 17.4084 10.1949 17.1775 10.1083C17.1775 10.1732 17.1544 10.2381
      17.1313 10.3031C17.4084 10.368 17.6393 10.3031 17.8933 10.433C18.2165
      10.5845 18.4705 10.4979 18.609 10.4979C18.7475 10.4979 18.7937 10.6278
      18.8168 10.5845C18.863 10.5845 18.7937 10.4546 18.609 10.4113Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M18.6551 11.039L17.1082 10.3463C17.0851 10.4112 17.062 10.4762 17.0389
      10.5195L18.632 11.0822C18.632 11.0822 18.6782 11.1039 18.7013
      11.0606L18.6551 11.039Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16 11.1255C15.9538 11.1255 15.9307 11.1255 15.8845 11.1255L15.9538
      12.7706C15.9538 12.7706 15.9538 12.8139 16 12.8139C16.0461 12.8139
      16.0461 12.7706 16.0461 12.7706L16.1154 11.1255C16.0692 11.1255 16.023
      11.1255 16 11.1255Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M13.4141 10.5844C13.5988 10.5628 13.6681 10.4113 14.1068
      10.4546C14.4762 10.4762 14.6147 10.3464 14.8918 10.3247C14.8687 10.2598
      14.8687 10.1948 14.8456 10.1299C14.5685 10.1732 14.3607 10.3247 14.0606
      10.3031C13.7143 10.2814 13.5065 10.4546 13.368 10.4979C13.2294 10.5412
      13.1371 10.4546 13.1371 10.4979C13.114 10.5195 13.2294 10.6061 13.4141
      10.5844Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M15.0996 10.736L13.9452 11.9481C13.9452 11.9481 13.899 11.9914 13.9221
      12.013C13.9452 12.0347 13.9914 11.9914 13.9914 11.9914L15.2381
      10.8659C15.192 10.8226 15.1458 10.7793 15.0996 10.736Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M14.8918 10.3463L13.3449 11.039C13.3449 11.039 13.2987 11.0606 13.2987
      11.0822C13.3218 11.1039 13.368 11.1039 13.368 11.1039L14.961
      10.5195C14.938 10.4545 14.9149 10.4112 14.8918 10.3463Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M14.43 11.104C14.7763 10.9958 14.8455 10.801 15.0995 10.6927C15.0533
      10.6494 15.0072 10.5845 14.9841 10.5196C14.7532 10.6711 14.6147 10.8875
      14.3376 10.9741C13.9913 11.0823 13.8989 11.3204 13.7835 11.407C13.6681
      11.4936 13.5526 11.4503 13.5526 11.4936C13.5526 11.5369 13.6911 11.5802
      13.8528 11.472C14.0144 11.3854 14.0144 11.2339 14.43 11.104Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M15.8615 11.1256C15.7922 11.1256 15.7229 11.1039 15.6537
      11.0823C15.5844 11.342 15.6537 11.5801 15.5382 11.8399C15.3766 12.1429
      15.469 12.4026 15.469 12.5325C15.469 12.6624 15.3304 12.7057 15.3766
      12.7489C15.3997 12.7922 15.5382 12.7273 15.5844 12.5325C15.6306 12.3377
      15.5151 12.2295 15.7229 11.8615C15.8615 11.5368 15.7691 11.3637 15.8615
      11.1256Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M14.9841 11.6018C15.2612 11.3637 15.2612 11.1689 15.4459
      10.9741C15.3766 10.9308 15.3304 10.9092 15.2612 10.8659C15.0996 11.0823
      15.0765 11.3421 14.8456 11.5152C14.5685 11.7317 14.5685 12.0131 14.4993
      12.1213C14.43 12.2512 14.3146 12.2295 14.3146 12.2728C14.3377 12.3161
      14.4762 12.2944 14.5916 12.1429C14.7071 12.0131 14.6609 11.8832 14.9841
      11.6018Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M15.4459 10.9957L14.8456 12.5541C14.8456 12.5541 14.8225 12.5974
      14.8687 12.619C14.8918 12.6407 14.9149 12.5757 14.9149 12.5757L15.6306
      11.0822C15.5844 11.0606 15.5152 11.0389 15.4459 10.9957Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.1969"
    />
    <path
      d="M16.7388 11.7749L16.4617 11.039C16.4386 11.039 16.4155 11.0607 16.4155
      11.0607L16.7388 11.7749Z"
      fill="#795605"
    />
    <path
      d="M16.3694 11.8615C16.4617 11.6234 16.277 11.4286 16.254 11.1039C16.2309
      11.1039 16.2078 11.1039 16.1847 11.1255C16.2309 11.4069 16.3925 11.5585
      16.3694 11.8615Z"
      fill="#795605"
    />
    <path
      d="M15.6074 11.8615C15.7921 11.6667 15.6998 11.4286 15.7921 11.1255C15.769
      11.1255 15.746 11.1255 15.7229 11.1039C15.6767 11.3853 15.746 11.6017
      15.6074 11.8615Z"
      fill="#795605"
    />
    <path
      d="M15.9537 11.1255L15.9768 11.9048L15.9999 11.1255H15.9768C15.9768
      11.1255 15.9768 11.1255 15.9537 11.1255Z"
      fill="#795605"
    />
    <path
      d="M14.8917 11.5801C15.1226 11.4719 15.1457 11.2122 15.3766
      10.9524C15.3535 10.9308 15.3304 10.9308 15.3304 10.9091C15.1457 11.1689
      15.1457 11.3853 14.8917 11.5801Z"
      fill="#795605"
    />
    <path
      d="M15.238 11.7749L15.5844 11.0607C15.5613 11.0607 15.5382 11.039 15.5382
      11.039L15.238 11.7749Z"
      fill="#795605"
    />
    <path
      d="M15.1458 10.7792L14.5917 11.3637L15.192 10.8225C15.1689 10.8009 15.1689
      10.7792 15.1458 10.7792Z"
      fill="#795605"
    />
    <path
      d="M14.3607 11.039C14.6147 11.0173 14.7301 10.7792 15.0534 10.6494C15.0303
      10.6277 15.0303 10.6061 15.0072 10.6061C14.7532 10.7576 14.6609 10.9741
      14.3607 11.039Z"
      fill="#795605"
    />
    <path
      d="M17.8239 9.26415L17.0851 9.52389C17.0851 9.54554 17.1082 9.56718
      17.1082 9.56718L17.8239 9.26415Z"
      fill="#795605"
    />
    <path
      d="M17.9163 9.63201C17.6624 9.54543 17.4777 9.71859 17.1313
      9.74023C17.1313 9.76188 17.1313 9.78352 17.1544 9.80517C17.4546 9.76188
      17.6162 9.61036 17.9163 9.63201Z"
      fill="#795605"
    />
    <path
      d="M17.1775 10.0217L17.9625 10L17.1775 9.97838V10C17.1775 10.0217 17.1775
      10.0217 17.1775 10.0217Z"
      fill="#795605"
    />
    <path
      d="M17.9163 10.3897C17.7316 10.2165 17.4777 10.3031 17.1544
      10.2165C17.1544 10.2382 17.1544 10.2598 17.1313 10.2815C17.4315 10.3248
      17.6393 10.2382 17.9163 10.3897Z"
      fill="#795605"
    />
    <path
      d="M17.8008 10.736L17.0851 10.4113C17.0851 10.433 17.062 10.4546 17.062
      10.4546L17.8008 10.736Z"
      fill="#795605"
    />
    <path
      d="M17.6161 11.0606C17.5006 10.8442 17.2467 10.8225 16.9927
      10.6061C16.9927 10.6277 16.9696 10.6494 16.9465 10.6494C17.2005 10.8225
      17.4314 10.8225 17.6161 11.0606Z"
      fill="#795605"
    />
    <path
      d="M16.8081 10.8225L17.3853 11.342L16.8312 10.7792C16.8312 10.8009 16.8081
      10.8225 16.8081 10.8225Z"
      fill="#795605"
    />
    <path
      d="M17.062 11.5801C17.0389 11.3204 16.808 11.2121 16.6464 10.9308C16.6233
      10.9524 16.6002 10.9524 16.6002 10.974C16.7849 11.2121 16.9927 11.2987
      17.062 11.5801Z"
      fill="#795605"
    />
    <path
      d="M15.6305 8.13851C15.5382 8.37661 15.7229 8.57141 15.746 8.89609C15.7691
      8.89609 15.7922 8.89609 15.8152 8.87444C15.746 8.59306 15.5844 8.44154
      15.6305 8.13851Z"
      fill="#795605"
    />
    <path
      d="M15.238 8.24677L15.5151 8.98271C15.5382 8.98271 15.5613 8.96106 15.5613
      8.96106L15.238 8.24677Z"
      fill="#795605"
    />
    <path
      d="M16.3925 8.13851C16.2309 8.33332 16.3001 8.57141 16.2078
      8.87444C16.2309 8.87444 16.2539 8.87444 16.277 8.89609C16.3232 8.6147
      16.2309 8.39825 16.3925 8.13851Z"
      fill="#795605"
    />
    <path
      d="M16.0231 8.87449L16 8.09527L15.9769 8.87449C15.9769 8.87449 16 8.87449
      16.0231 8.87449C16 8.87449 16.0231 8.87449 16.0231 8.87449Z"
      fill="#795605"
    />
    <path
      d="M17.0851 8.41992C16.8542 8.52815 16.8311 8.78789 16.6233
      9.02598C16.6464 9.04763 16.6695 9.04763 16.6695 9.06927C16.8311 8.83118
      16.8542 8.61473 17.0851 8.41992Z"
      fill="#795605"
    />
    <path
      d="M16.7388 8.24677L16.4155 8.96106C16.4386 8.96106 16.4617 8.98271
      16.4617 8.98271L16.7388 8.24677Z"
      fill="#795605"
    />
    <path
      d="M16.8543 9.22084L17.3853 8.65807L16.8081 9.17755C16.8081 9.1992 16.8312
      9.1992 16.8543 9.22084Z"
      fill="#795605"
    />
    <path
      d="M17.6161 8.96111C17.3621 8.98276 17.2467 9.19921 16.9465
      9.35072C16.9696 9.37237 16.9696 9.39401 16.9927 9.39401C17.2467 9.2425
      17.339 9.02605 17.6161 8.96111Z"
      fill="#795605"
    />
    <path
      d="M14.0605 10.3679C14.3145 10.4545 14.5223 10.2814 14.8455
      10.2597C14.8455 10.2381 14.8455 10.2164 14.8225 10.1948C14.5454 10.2381
      14.3838 10.4112 14.0605 10.3679Z"
      fill="#795605"
    />
    <path
      d="M14.176 10.736L14.9379 10.4546C14.9379 10.433 14.9148 10.4113 14.9148
      10.4113L14.176 10.736Z"
      fill="#795605"
    />
    <path
      d="M14.0836 9.63208C14.2914 9.80524 14.5454 9.71866 14.8686
      9.82688C14.8686 9.80524 14.8686 9.78359 14.8917 9.76195C14.5685 9.67537
      14.3376 9.76195 14.0836 9.63208Z"
      fill="#795605"
    />
    <path
      d="M14.8456 9.97838L14.0375 10L14.8456 10.0217V10V9.97838Z"
      fill="#795605"
    />
    <path
      d="M14.3607 8.93937C14.4762 9.17746 14.7532 9.17746 15.0072
      9.39391C15.0072 9.37227 15.0303 9.35062 15.0534 9.35062C14.7994 9.17746
      14.5685 9.17746 14.3607 8.93937Z"
      fill="#795605"
    />
    <path
      d="M14.176 9.26415L14.9148 9.58883C14.9148 9.56718 14.9379 9.54554 14.9379
      9.54554L14.176 9.26415Z"
      fill="#795605"
    />
    <path
      d="M15.192 9.17757L14.5917 8.63644L15.1458 9.22086C15.1689 9.22086 15.1689
      9.19921 15.192 9.17757Z"
      fill="#795605"
    />
    <path
      d="M14.9148 8.41992C14.9379 8.67966 15.1688 8.78789 15.3304
      9.09092C15.3535 9.06927 15.3766 9.06927 15.3766 9.04763C15.2149 8.80953
      14.9841 8.70131 14.9148 8.41992Z"
      fill="#795605"
    />
    <path
      d="M15.9999 8.78797C16.6926 8.78797 17.2467 9.3291 17.2467 10.0001C17.2467
      10.6711 16.6926 11.2122 15.9999 11.2122C15.3073 11.2122 14.7532 10.6711
      14.7532 10.0001C14.7532 9.3291 15.3073 8.78797 15.9999 8.78797Z"
      fill="#FFB80C"
      stroke="#795605"
      strokeWidth="0.256"
    />
    <path
      d="M16.4848 9.84848C16.6003 9.84848 16.6003 9.67532 16.4848
      9.67532C16.3463 9.67532 16.3694 9.84848 16.4848 9.84848Z"
      fill="#795605"
    />
    <path
      d="M16.4386 9.78365C16.4617 9.762 16.4386 9.71871 16.4386 9.69707C16.4386
      9.69707 16.4155 9.69707 16.4155 9.71871C16.4155 9.74036 16.4155 9.82694
      16.4386 9.78365Z"
      fill="#FFB80C"
    />
    <path
      d="M16.254 9.78357C16.3464 9.87015 16.5773 9.91344 16.7389 9.78357C16.6696
      9.71864 16.5773 9.67535 16.4849 9.67535C16.3926 9.6537 16.3002 9.69699
      16.254 9.78357ZM16.6696 9.76192C16.5773 9.87015 16.3926 9.87015 16.3002
      9.78357C16.3926 9.61041 16.6234 9.67534 16.6696 9.76192Z"
      fill="#795605"
    />
    <path
      d="M16.4848 9.58881C16.3463 9.58881 16.3232 9.61045 16.2539
      9.67538C16.1846 9.71867 16.1616 9.71867 16.1616 9.71867C16.1385 9.80525
      16.3001 9.67538 16.3001 9.67538C16.3924 9.61045 16.531 9.61045 16.6233
      9.65374C16.7388 9.69703 16.7849 9.78361 16.7849 9.76196C16.8311 9.76196
      16.6926 9.58881 16.4848 9.58881Z"
      fill="#795605"
    />
    <path
      d="M16.0923 9.5455C16.0231 9.7403 16.0462 9.93511 16.1385 10.1083C16.1385
      10.1083 16.1616 10.0866 16.1847 10.0866C16.0923 9.95675 16.0462 9.82688
      16.0692 9.71866C16.1385 9.37234 16.5541 9.39398 16.8542 9.63208C16.6234
      9.35069 16.3232 9.37234 16.0923 9.5455Z"
      fill="#795605"
    />
    <path
      d="M16.1847 9.80527C16.1847 9.82691 16.3001 9.89185 16.277 9.89185C16.3694
      9.95678 16.5541 9.95678 16.7388 9.82691C16.3925 10.0001 16.2078 9.78362
      16.1847 9.80527Z"
      fill="#795605"
    />
    <path
      d="M15.5382 9.67532C15.4228 9.67532 15.4228 9.84848 15.5382
      9.84848C15.6537 9.84848 15.6537 9.67532 15.5382 9.67532Z"
      fill="#795605"
    />
    <path
      d="M15.4921 9.78365C15.5152 9.762 15.4921 9.71871 15.4921 9.69707C15.4921
      9.69707 15.469 9.69707 15.469 9.71871C15.469 9.74036 15.469 9.82694
      15.4921 9.78365Z"
      fill="#FFB80C"
    />
    <path
      d="M15.5383 9.65369C15.4459 9.65369 15.3536 9.69698 15.2843
      9.76192C15.4459 9.89179 15.6768 9.87014 15.7692 9.76192C15.6999 9.69698
      15.6306 9.65369 15.5383 9.65369ZM15.723 9.78357C15.6306 9.87015 15.4459
      9.87014 15.3536 9.76192C15.3997 9.67534 15.6075 9.61041 15.723 9.78357Z"
      fill="#795605"
    />
    <path
      d="M15.5151 9.58881C15.6536 9.58881 15.6767 9.61045 15.746 9.67538C15.8152
      9.71867 15.8383 9.71867 15.8383 9.71867C15.8614 9.80525 15.6998 9.67538
      15.6998 9.67538C15.6075 9.61045 15.4689 9.61045 15.3766 9.65374C15.2611
      9.69703 15.215 9.78361 15.215 9.76196C15.1688 9.76196 15.3073 9.58881
      15.5151 9.58881Z"
      fill="#795605"
    />
    <path
      d="M15.9077 9.54555C15.9769 9.74036 15.9538 9.93516 15.8615
      10.1083C15.8615 10.1083 15.8384 10.0867 15.8153 10.0867C15.9077 9.95681
      15.9538 9.82694 15.9307 9.71871C15.8615 9.37239 15.4459 9.39404 15.1458
      9.63213C15.3766 9.35075 15.6999 9.37239 15.9077 9.54555Z"
      fill="#795605"
    />
    <path
      d="M15.8153 9.80527C15.8153 9.82691 15.6999 9.89185 15.723 9.89185C15.6306
      9.95678 15.4459 9.95678 15.2612 9.82691C15.6076 10.0001 15.7923 9.78362
      15.8153 9.80527Z"
      fill="#795605"
    />
    <path
      d="M16.2308 10.1083C16.277 10.1299 16.277 10.1948 16.2539 10.2165C16.2308
      10.2381 16.1616 10.2381 16.1616 10.1732C16.1385 10.2165 16.0692 10.2598
      16.023 10.2598C15.9769 10.2598 15.9076 10.2165 15.8845 10.1732C15.8845
      10.2381 15.8153 10.2381 15.7922 10.2165C15.7691 10.1948 15.746 10.1515
      15.8153 10.1083C15.7229 10.1299 15.6998 10.1948 15.746 10.2598C15.7922
      10.3247 15.8845 10.3247 15.9076 10.2598C15.9307 10.2814 16 10.2814 16.023
      10.2814C16.0692 10.2814 16.1154 10.2814 16.1385 10.2598C16.1616 10.3247
      16.277 10.3247 16.3001 10.2598C16.3463 10.1948 16.3232 10.1299 16.2308
      10.1083Z"
      fill="#795605"
    />
    <path
      d="M16 10.4112C15.8384 10.3247 15.7691 10.4545 15.6537 10.5411C15.6999
      10.5195 15.746 10.4762 15.8153 10.4329C15.8846 10.3896 15.9538 10.4329 16
      10.4329C16.0462 10.4329 16.0924 10.3896 16.1847 10.4329C16.254 10.4762
      16.3002 10.5195 16.3463 10.5411C16.2309 10.4545 16.1616 10.3247 16
      10.4112Z"
      fill="#795605"
    />
    <path
      d="M16 10.5195C15.9307 10.5195 15.9076 10.4762 15.6998 10.5411C15.8845
      10.4979 15.9076 10.5628 16 10.5628C16.0923 10.5628 16.1154 10.4979
      16.3001 10.5411C16.1154 10.4762 16.0692 10.5195 16 10.5195Z"
      fill="#795605"
    />
    <path
      d="M16.3001 10.5196C16.3001 10.5196 16.3001 10.5412 16.3001
      10.5196C16.3001 10.5412 16.3001 10.5412 16.3001 10.5196C16.1154 10.5196
      16.1616 10.6278 16 10.6278C15.8153 10.6278 15.8846 10.5196 15.6999
      10.5196C15.6768 10.5196 15.6768 10.5196 15.6537 10.5196C15.8615 10.5412
      15.7691 10.6711 16 10.6711C16.2309 10.6711 16.1154 10.5412 16.3463
      10.5196C16.3232 10.5412 16.3232 10.5196 16.3001 10.5196Z"
      fill="#795605"
    />
    <path
      d="M16 10.7143C15.9077 10.7143 15.8153 10.7793 15.8153 10.8875C15.8384
      10.8009 15.9307 10.7576 16 10.7576C16.0924 10.7576 16.1616 10.8009
      16.1847 10.8875C16.1847 10.7576 16.0924 10.7143 16 10.7143Z"
      fill="#795605"
    />
  </>
);
