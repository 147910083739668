// @flow

import React, { Component, Fragment } from "react";
import { noop, get, isEqual } from "lodash";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import type {
  PastPerformanceRace,
  RaceCardRunner,
  Results,
  RaceProgram,
  RaceCardBettingInterest,
  FullResultRunner,
  MTPNotificationConfigType
} from "@tvg/types/Race";
import type { WroWagerGroup } from "@tvg/api/wro/types";
import type { HandicappingRunnerFlags } from "@tvg/ipp/src/components/RunnerFlags";
import RunnerInfoPastPerformance from "@tvg/ipp/src/components/RunnerInfoPastPerformance";
import type { RaceBet } from "../../_organism/RaceBets";
import FeedbackOverlay from "../../_atom/FeedbackOverlay";
import InlinePastPerformanceInfo from "../../_molecule/PastPerformanceRace";
import MTPNotification from "../../_molecule/MTPNotification";
import { mtpNotificationConfigDefault } from "../../__mocks__/ipp-pastRaces.mock";
import RaceOfficials, { type TabKey } from "../RaceOfficials";
import Video from "../../_organism/Video";
import { info } from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";

import buildColor from "../../_static/ColorPalette";
import {
  InfoContainer,
  LoadingState,
  ContentWrapper,
  ModalWrapper,
  VideoContainer,
  NoReplayContainer,
  NoReplayContainerMessage,
  IconContainer,
  MessageContainer
} from "./styled-components";

type Props = {
  race: PastPerformanceRace,
  runner: ?RaceCardRunner,
  hasNextRace: boolean,
  hasPreviousRace: boolean,
  onPreviousRaceNavigation: NullaryFn<void>,
  onNextRaceNavigation: NullaryFn<void>,
  results: ?Results,
  onTabClick: UnaryFn<TabKey, boolean>,
  onModalClose: NullaryFn<void>,
  withBorders: boolean,
  isLoading: boolean,
  bettingInterests: RaceCardBettingInterest[],
  scratchedRunners: string[],
  raceBets: RaceBet[],
  wagerGroup: ?WroWagerGroup,
  myBetsResultedBetTicket: boolean,
  replaySrc: ?string,
  sendVideoGtmEvent: NullaryFn<void>,
  fullResults: FullResultRunner[],
  hasTimeformData: boolean,
  videoFullscreenTilt: boolean,
  deviceLockRotationToggle: UnaryFn<boolean, void>,
  device: Device,
  enableRaceResultsReDesign: boolean,
  seeAllHorsesGTM: NullaryFn<void>,
  handicappingRunnerFlags: HandicappingRunnerFlags,
  racePayoffsShift: boolean,
  currentRace: RaceProgram,
  mtpNotificationConfig: MTPNotificationConfigType,
  handicappingMTPNotification: boolean,
  isVideoIframe?: boolean,
  disableFullscreen?: boolean,
  isAutoPlay?: boolean,
  isTvg5?: boolean
};

export default class HorsePastPerformances extends Component<Props> {
  static defaultProps = {
    runner: null,
    hasNextRace: false,
    hasPreviousRace: false,
    onPreviousRaceNavigation: noop,
    onNextRaceNavigation: noop,
    results: null,
    onTabClick: noop,
    onModalClose: noop,
    withBorders: false,
    videoFullscreenTilt: false,
    isLoading: true,
    bettingInterests: [],
    scratchedRunners: [],
    raceBets: [],
    wagerGroup: null,
    myBetsResultedBetTicket: false,
    replaySrc: null,
    sendVideoGtmEvent: noop,
    fullResults: [],
    hasTimeformData: false,
    deviceLockRotationToggle: noop,
    device: "mobile",
    enableRaceResultsReDesign: false,
    seeAllHorsesGTM: noop,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    racePayoffsShift: false,
    currentRace: {},
    mtpNotificationConfig: mtpNotificationConfigDefault,
    handicappingMTPNotification: false,
    isVideoIframe: false,
    disableFullscreen: false,
    isAutoPlay: false,
    isTvg5: false
  };

  shouldComponentUpdate(nextProps: Props) {
    const { race, runner, results, bettingInterests, raceBets } = this.props;

    return (
      !isEqual(results, nextProps.results) ||
      !isEqual(race, nextProps.race) ||
      !isEqual(runner, nextProps.runner) ||
      !isEqual(bettingInterests, nextProps.bettingInterests) ||
      !isEqual(raceBets, nextProps.raceBets) ||
      !isEqual(this.props, nextProps)
    );
  }

  renderResults = () => {
    return (
      <RaceOfficials
        isResultsPending={false}
        results={{
          payoffs: get(this.props.results, "payoffs") || [],
          runners: get(this.props.results, "runners") || []
        }}
        firstHandicapBold
        defaultTab="fullresult"
        raceTypeCode={get(this.props.race, "raceTypeCode", "")}
        raceNumber={get(this.props.race, "number", "")}
        onTabClick={this.props.onTabClick}
        handicapType="basic"
        bettingInterests={this.props.bettingInterests}
        hasNoHandicappingSelection
        bets={this.props.raceBets}
        wagerGroup={this.props.wagerGroup}
        myBetsResultedBetTicket={this.props.myBetsResultedBetTicket}
        fullResults={this.props.fullResults}
        scratchedRunners={this.props.scratchedRunners}
        runnerName={get(this.props.runner, "horseName")}
        resultsType="full"
        hasTimeformData={this.props.hasTimeformData}
        winningTime={get(this.props.results, "winningTime")}
        enableRaceResultsReDesign={this.props.enableRaceResultsReDesign}
        seeAllHorsesGTM={this.props.seeAllHorsesGTM}
        handicappingRunnerFlags={this.props.handicappingRunnerFlags}
        raceId={get(this.props.race, "id")}
        racePayoffsShift={this.props.racePayoffsShift}
      />
    );
  };

  renderVideo = () =>
    this.props.replaySrc && this.props.isVideoIframe ? (
      <div>
        <iframe
          title="rcn-player-replay-video"
          src={`${this.props.replaySrc}&autoplay=1`}
          allowFullScreen="true"
          width="100%"
          height="100%"
          allow={`fullscreen${this.props.isAutoPlay ? ";autoplay" : ""}`}
          scrolling="no"
          id="rcn-player-replay-video"
        />
      </div>
    ) : (
      <Video
        raceId={get(this.props.race, "id")}
        src={{ hd: null, sd: null, replay: this.props.replaySrc }}
        isReplay
        inlineVideo
        onVideoPlay={this.props.sendVideoGtmEvent}
        videoFullscreenTilt={this.props.videoFullscreenTilt}
        deviceLockRotationToggle={this.props.deviceLockRotationToggle}
        isAutoPlay={this.props.isAutoPlay}
        disableFullscreen={this.props.disableFullscreen}
      />
    );

  renderVideoFallback = () => (
    <Fragment>
      <NoReplayContainer />
      <NoReplayContainerMessage>
        <IconContainer>
          <Icon icon={info} color={buildColor("white", "100")} size={16} />
        </IconContainer>
        <MessageContainer>No replay available</MessageContainer>
      </NoReplayContainerMessage>
    </Fragment>
  );

  renderMTPNotification = () =>
    this.props.handicappingMTPNotification && (
      <MTPNotification
        onModalClose={this.props.onModalClose}
        currentRace={this.props.currentRace}
        mtpNotificationConfig={this.props.mtpNotificationConfig}
      />
    );

  render() {
    if (this.props.isLoading) {
      <ModalWrapper>
        <ContentWrapper withBorders={this.props.withBorders}>
          <LoadingState>
            <FeedbackOverlay
              loadingIconColor={buildColor("grey", "900")}
              overlayBgColor={buildColor("white", "70")}
            />
          </LoadingState>
        </ContentWrapper>
      </ModalWrapper>;
    }

    return (
      <ModalWrapper>
        <ContentWrapper withBorders={this.props.withBorders}>
          <InfoContainer borderless={this.props.withBorders}>
            {this.props.enableRaceResultsReDesign ? (
              <RunnerInfoPastPerformance
                race={this.props.race}
                hasNextRace={this.props.hasNextRace}
                hasPreviousRace={this.props.hasPreviousRace}
                onPreviousRaceNavigation={this.props.onPreviousRaceNavigation}
                onNextRaceNavigation={this.props.onNextRaceNavigation}
                hasNumberOfRunners={this.props.device !== "tablet"}
              />
            ) : (
              <InlinePastPerformanceInfo
                race={this.props.race}
                hasNextRace={this.props.hasNextRace}
                hasPreviousRace={this.props.hasPreviousRace}
                useSimpleLayout
                onPreviousRaceNavigation={this.props.onPreviousRaceNavigation}
                onNextRaceNavigation={this.props.onNextRaceNavigation}
                hasNumberOfRunners={this.props.device !== "tablet"}
              />
            )}
          </InfoContainer>
          <VideoContainer
            isVideoAvailable={
              get(this.props, "race.hasReplays") && this.props.replaySrc
            }
            isPlaying={!!this.props.replaySrc}
            isTvg5={this.props.isTvg5}
            isMobile={this.props.device === "mobile"}
          >
            {get(this.props, "race.hasReplays")
              ? this.renderVideo()
              : this.renderVideoFallback()}
          </VideoContainer>
          {this.renderResults()}
          {this.renderMTPNotification()}
        </ContentWrapper>
      </ModalWrapper>
    );
  }
}
