import styled from "styled-components";

export const List = styled.ul`
  margin-top: ${({ theme }) => theme.space["space-4"]};
  border-radius: ${({ theme }) => theme.radii.s};
  box-shadow: ${({ theme }) => theme.elevations.high};
  max-height: 300px;
  overflow: scroll;
  scroll-behavior: smooth;
`;

export const ListItem = styled.li`
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]} ${theme.space["space-4"]} ${theme.space["space-5"]}`};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorTokens.component.button.tertiary.background.hover};
  }
`;
