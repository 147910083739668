import { GestureResponderEvent } from "react-native";
import { SpaceProps, LayoutProps } from "styled-system";

import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { Breed, SaddleSelectionsProps } from "../../../src";
import {
  HeaderBetStatusType,
  WagerCardHeaderProps
} from "../WagerCardHeader/types";
import { WagerCardNotificationProps } from "./components/Notification/types";
import { PickBetRebetProps } from "./components/PickBetRebet/types";
import { PromoOnboardingProps } from "./components/PromoOnboarding/types";
import { WagerCardFooterProps } from "./components/Footer/types";

type BetStatusType = "NONE" | "PLACED" | "ERROR" | "PROCESSING";

export type BetRaceStatusType =
  | "open"
  | "raceOff"
  | "win"
  | "lost"
  | "attention"
  | "unknown";

type BetTypeCellHeaderPickTypes =
  | "onApproxPayout"
  | "isBetWinner"
  | "isCanceled"
  | "winningsAmount"
  | "shouldShowPotentialReturn"
  | "showMinMaxWillPays"
  | "minWillPays"
  | "maxWillPays"
  | "onHideShowLegsGTM"
  | "setShowContent"
  | "shouldShowHideLegButton"
  | "showContent"
  | "wagerId"
  | "wonIllustration";

export enum BetStatusCodesEnum {
  ACTIVE = "A",
  REFUNDED = "R",
  LOSER = "L",
  WINNER = "W",
  CANCELED = "C"
}

export interface BetTypeCellProps extends SpaceProps, LayoutProps {
  /** Bet Amount */
  betAmount: number;
  /** Bet type name */
  betTypeName: string;
  /** Bet Ticket value */
  betTicket: number;
  /** Number Wagerable Runners */
  numWagerableRunners?: string[] | undefined;
  /** Approximated Payout */
  approximatedPayout: string;
  /** Bet type */
  type: WagerTypeCodesEnum;
  /** Type of race */
  breed?: Breed;
  /** Saddle selections */
  selections: SaddleSelectionsProps[];
  /** Complete Selection Array */
  completeSelection?: SaddleSelectionsProps[][];
  /** Status of the bet */
  betStatus: BetStatusType | HeaderBetStatusType;
  /** Repeat bet handler */
  onRepeatBetHandler?: (event: GestureResponderEvent) => void;
  /** Cancel bet handler */
  onCancelBetHandler?: (event: GestureResponderEvent) => void;
  /** Custom data-qa-label attribute applied to element */
  qaLabel?: string;
  /** Is leg bet * */
  isLeg: boolean;
  /** If include scratch in selections */
  scratches?: string[][];
  /** Is Key bet */
  isKey?: boolean;
  /** Is Box bet */
  isBox?: boolean;
  /** Is Wheel bet */
  isWheel?: boolean;
  /** Current race number */
  raceNumber?: string;
  /** No header and no border */
  showOnlySelections?: boolean;
  betStatusCode?: BetStatusCodesEnum;
  racesStatus?: BetRaceStatusType[];
  winAmountBet?: number;
  /** Is the bet refund amount */
  betRefund?: number;
  /** Is the component being rendered in the myBets screen */
  isMyBets?: boolean;
  /** Is the bet repeatable */
  isRepeatable?: boolean;
  /** If is on handicaping */
  onHandicaping?: boolean;
  /** if bet is opted in */
  isOptedIn?: boolean;
  /** if footer should be shown */
  isFooterShown?: boolean;
  /** Props for my bets header */
  headerMyBetsProps?: Pick<WagerCardHeaderProps, BetTypeCellHeaderPickTypes>;
  /** Props for notifications */
  notificationProps?: WagerCardNotificationProps;
  /** Props for pick bet rebet */
  pickBetRebetProps?: PickBetRebetProps;
  /** Props for promo onboards */
  promoOnboardingProps?: PromoOnboardingProps;
  /** Props for footer */
  wagerCardFooterProps?: WagerCardFooterProps;
  /** Details controler */
  wagerDetails?: boolean[];
  onDetailsView?: (raceSelected: number) => void;
  shouldShowRefundTag?: boolean;
}
