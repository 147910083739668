import styled from "styled-components";
import { animated } from "@react-spring/web";
import { ThemeProps } from "../../theme/types";
import { ToastType } from "./types";

const toastBgColor = (type: ToastType, theme: ThemeProps) => {
  switch (type) {
    case "success":
      return {
        backgroundColor:
          theme.colorTokens.component.notification.geocomply.backgroundSuccess
      };
    case "pending":
      return {
        backgroundColor:
          theme.colorTokens.component.notification.geocomply
            .backgroundVerification
      };
    default:
      return {
        backgroundColor:
          theme.colorTokens.component.notification.geocomply
            .backgroundVerification
      };
  }
};

export const Wrapper = styled(animated.div)<{
  visibility: string;
  offset: number;
}>`
  display: flex;
  position: fixed;
  visibility: ${({ visibility }) => visibility};
  top: ${({ offset }) => `calc(98% - ${offset}px)`};
  justify-content: center;
  color: ${({ theme }) => theme.colorTokens.content.onDark};
  width: 100%;
  z-index: 1100;
  pointer-events: none;
`;

export const ContentWrapper = styled.div<{ type?: ToastType }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]}`};
  border-radius: 100px;
  box-shadow: ${({ theme }) => `0 1 ${theme.space["space-5"]}`};
  max-width: 312px;
  ${({ type, theme }) => type && toastBgColor(type, theme)};
  width: fit-content;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${({ theme }) => `0 ${theme.space["space-1"]}`};
`;

export const Title = styled.span`
  font-family: Proxima nova, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
`;

export const SubTitle = styled.span`
  font-family: Proxima nova, sans-serif;
  font-size: ${({ theme }) => theme.space["space-4"]};
  line-height: 125%;
  font-weight: 400;
`;
