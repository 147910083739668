import styled from "styled-components";

import {
  space,
  system,
  compose,
  fontFamily,
  fontSize,
  flex,
  layout,
  typography,
  color
} from "../../../../src/theming/styledSystem";
import { ParagraphProps } from "./types";
import { getAccessibilityRole } from "../../../../src";

const lineHeightNormal = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeights"
  }
});

const lineHeightTaller = system({
  fontSize: {
    property: "lineHeight",
    scale: "lineHeightsTaller"
  }
});

const transformText = system({
  textTransform: {
    property: "textTransform"
  },
  whiteSpace: {
    property: "whiteSpace"
  },
  textOverflow: {
    property: "textOverflow"
  }
});

const styles = compose(
  space,
  fontFamily,
  fontSize,
  transformText,
  typography,
  layout,
  color
);

export const Paragraph = styled.p.attrs<ParagraphProps>((props) => ({
  ref: props.ref,
  accessibilityRole: getAccessibilityRole("paragraph"),
  overflow: props.textOverflow === "ellipsis" ? "hidden" : "visible",
  width: props.textOverflow === "ellipsis" ? "100%" : "auto",
  "data-qa-label": props.qaLabel
}))<ParagraphProps>`
  ${(props) => (props.isLineHigher ? lineHeightTaller : lineHeightNormal)}
  ${styles};
  ${flex}
`;

Paragraph.defaultProps = {
  fontFamily: "regular",
  fontSize: "s",
  isLineHigher: false,
  color: "grey.900"
};
